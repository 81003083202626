import React from 'react';
import { Theme, withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Paper from '@material-ui/core/Paper';
import ecolabel from '../../images/ecolabel.png';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import HighlightOffTwoToneIcon from '@material-ui/icons/HighlightOffTwoTone';
import Divider from '@material-ui/core/Divider';

interface Props {
  theme: Theme;
  EcoLabel?: any;
  selectedUserFormulas: any;
}

const CRITERION_2 = 'criterion 2';
const CRITERION_3 = 'criterion 3';

const labelDescription = 'This section indicates whether your simulated detergent formulation lives up to the label criteria that are considered in WashLab.';

const criterion3tooltip = 'All surfactants displayed in the tool are anaerobically biodegradable with the exception of LAS. Therefore when LAS is included in formulation, Criterion 3 is not met.';

const noteLabelLink = 'https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv:OJ.L_.2017.180.01.0063.01.ENG&toc=OJ:L:2017:180:TOC#ntr6-L_2017180EN.01006601-E0010';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    boxSizing: 'border-box',
    padding: '32px 48px'
  },
  ecolabelImage: {
    width: '60px'
  },
  divider: {
    margin: '20px 0px'
  },
  novoFontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px',
    color: '#626262'
  },
  novoFontBold: {
    fontFamily: 'Novozymes Bold, Arial',
    fontSize: '14px'
  },
  novoTitle: {
    fontFamily: 'Novozymes Bold, Arial',
    fontSize: '16px',
  },
  note: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '10px'
  }
}));

const renderApprovedIcon = () => {
  return <CheckCircleTwoToneIcon style={{ color: '#56A849' }} fontSize='large' />;
};

const renderFailedIcon = () => {
  return <HighlightOffTwoToneIcon style={{ color: '#963131' }} fontSize='large' />;
};

const checkEcolabel = (EcoLabelData: any, criterionId: string) => {
  try {
    if (EcoLabelData[criterionId] === 'approved') {
      return renderApprovedIcon();
    } else {
      return renderFailedIcon();
    }
  } catch (error) {
  }
};

export const CompareEcoLabels: React.SFC<Props> = ({ theme, EcoLabel, selectedUserFormulas }) => {
  const classes = useStyles(theme);

  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      fontFamily: 'Novozymes Regular, Arial',
      backgroundColor: '#c5da00',
      color: '#2d0028',
      maxWidth: 420,
      fontSize: theme.typography.pxToRem(1),
      border: '1px solid #dadde9',
      borderRadius: '15px',
      padding: '10px'
    },
  }))(Tooltip);

  const formulationNames = Object.entries(selectedUserFormulas).map((userFormula: any) => userFormula[1].data.name);

  return (
    <div id='sustainabilitylabel' style={{ paddingTop: '20px' }}>
      <Grid container justify='flex-start' alignItems='baseline' spacing={2} >
        <Grid item>
          <Typography className={classes.novoTitle} variant='h5' gutterBottom={true} >Label</Typography>
        </Grid>
        <Grid item>
          <HtmlTooltip
            arrow
            placement='right'
            title={
              <React.Fragment>
                <Typography style={{ fontFamily: 'Novozymes Regular, Arial', padding: '15px', fontSize: '14px' }}>{labelDescription}</Typography>
              </React.Fragment>
            }
          >
            <InfoOutlinedIcon />
          </HtmlTooltip>
        </Grid>
      </Grid>

      <Grid item xl={12} >
        <Paper className={classes.root} elevation={24} >
          <Grid container alignItems='flex-start' justify='flex-start' style={{ padding: 0 }}>
            <Grid item xs={4} >
              <img src={ecolabel} alt='ecolabel logo' style={{ width: '64px', margin: 'auto' }} />
            </Grid>
            <Grid container item xs={8} justify='flex-start' alignItems='flex-start'>
              {formulationNames.map((formulationName: string, index: number) =>
                (<Grid key={index} item container xs={4} justify='center' alignItems='flex-start'>
                  <Grid item xs='auto'>
                    <Typography className={classes.novoFontRegular}>{`Formulation ${index + 1}`}</Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography className={classes.novoFontBold} align='center' style={{ padding: '0px 10px' }}>{formulationName}</Typography>
                  </Grid>
                </Grid>)
              )}
            </Grid>
          </Grid>

          <Divider className={classes.divider} variant='fullWidth' />

          <Grid container alignItems='center' justify='flex-start'>
            <Grid item xs={4} >
              <Typography className={classes.novoFontBold}>Dosage requirements</Typography>
              <Typography className={classes.novoFontRegular}>WashLab uses a fixed dosage of 60 g/wash and 4 kg of ballast.</Typography>
            </Grid>
            <Grid container item xs={8} justify='flex-start' alignItems='center'>
              {Object.entries(selectedUserFormulas).map((userFormula: any, index: number) => (
                <Grid key={index} item container xs={4} justify='center' alignItems='flex-start'>
                  {renderApprovedIcon()}
                </Grid>
              ))}
            </Grid>
          </Grid>

          <Divider className={classes.divider} variant='fullWidth' />

          <Grid container alignItems='center' justify='flex-start'>
            <Grid item xs={4} >
              <Typography className={classes.novoFontBold}>Toxicity to aquatic organisms</Typography>
            </Grid>
            <Grid container item xs={8} justify='flex-start' alignItems='center'>
              {Object.keys(selectedUserFormulas).map((userFormulaId: string, index: number) => {
                return <Grid key={index} item container xs={4} justify='center' alignItems='flex-start'>
                  {checkEcolabel(EcoLabel[userFormulaId], CRITERION_2)}
                </Grid>;
              }
              )}
            </Grid>
          </Grid>

          <Divider className={classes.divider} variant='fullWidth' />

          <Grid container alignItems='flex-start' justify='flex-start'>
            <Grid item container xs={4} >
              <Typography className={classes.novoFontBold}>Biodegradability</Typography>
              <HtmlTooltip
                arrow
                placement='right'
                title={
                  <React.Fragment>
                    <Typography style={{ fontFamily: 'Novozymes Regular, Arial', padding: '15px', fontSize: '14px' }}>{criterion3tooltip}</Typography>
                  </React.Fragment>
                }
              >
                <InfoOutlinedIcon />
              </HtmlTooltip>
            </Grid>
            <Grid container item xs={8} justify='flex-start' alignItems='center'>
              {Object.keys(selectedUserFormulas).map((userFormulaId: string, index: number) => {
                return <Grid key={index} item container xs={4} justify='center' alignItems='flex-start'>
                  {checkEcolabel(EcoLabel[userFormulaId], CRITERION_3)}
                </Grid>;
              }
              )}
            </Grid>
          </Grid>

          <Divider className={classes.divider} variant='fullWidth' />

          <Typography className={classes.novoFontRegular} variant='body2' >Note: The other criteria that you need to fulfill to qualify for the EU Eco label are not considered in WashLab. You can find them <a href={noteLabelLink} target='_blank' rel='noopener noreferrer'>here.</a></Typography>
        </Paper>
      </Grid>
    </div >
  );
};
