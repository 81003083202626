import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { RootState } from '../reducers/root';
import { InputMainContainer } from '../components/InputSelection/InputMainContainer';
import { UserInputActionType, optimizeRecipe, fetchStainsInfo, saveUserIngredientCost, fetchUserSavedData } from '../actions/UserInput';
import { nextWindow } from '../actions/AppState';

const mapStateToProps = (state: RootState) => ({
  benchmark: state.UserInputState.benchmark
});

const dispatchProps = (dispatch: Dispatch<UserInputActionType>) => {
  return {
    //@ts-ignore
    optimizeRecipe: () => dispatch(optimizeRecipe()),

    //@ts-ignore
    fetchStainsInfo: () => dispatch(fetchStainsInfo()),
    //@ts-ignore
    nextWindow: () => dispatch(nextWindow()),
    //@ts-ignore
    saveUserIngredientCost: () => dispatch(saveUserIngredientCost()),
    //@ts-ignore
    fetchUserSavedData: () => dispatch(fetchUserSavedData()),
  };
};

export default connect(mapStateToProps, dispatchProps)(InputMainContainer);
