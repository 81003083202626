import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { RootState } from '../reducers/root';
import { changeAppTab, AppActionTypes } from '../actions/AppState';
import { MainPredictionPage } from '../components/PerformancePrediction/MainPredictionPage';

const mapStateToProps = (state: RootState) => ({
  AuthState: state.AuthState,
  appTab: state.AppState.appTab,
  isFetching: state.UserInputState.isFetching,
  StainGroupNames: state.UserInputState.stainGroupNames,
  region: state.UserInputState.region,
  StainGroups: state.FormulationSpace.StainGroups,
  ModelPredictionData: state.FormulationSpace.ModelPredictionData,
  BenchmarkData: state.FormulationSpace.BenchmarkData
});

const dispatchProps = (dispatch: Dispatch<AppActionTypes>) => {
  return {
    changeAppTab: (appTab: number) => dispatch(changeAppTab(appTab)),
  };
};


export default connect(mapStateToProps, dispatchProps)(MainPredictionPage);
