import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Container from '@material-ui/core/Container';
import novozymesLogo from '../images/NZ_Primary_White_RGB.png';
import backgroundImg from '../images/MyWashLabLogoImg.jpg';
import bioscriptImg from '../images/BioscriptRethinkTommorow.svg';

interface Props {
  theme: Theme;
}

const useStyles = makeStyles(theme => ({
  aboutpage: {
    color: 'white',
    height: '100vh',
    width: '100%',
    backgroundColor: '#ffffff',
    margin: 0,
    padding: 0,
    border: 0
  },
  aboutPageBackgroundImg: {
    backgroundColor: 'white-smoke',
    backgroundImage: `url(${backgroundImg})`,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover'
  },
  mywashlablogo: {
    color: '#FFFFFF',
    height: '72px30px',
    width: '102px'
  },
  aboutcontainer: {
    padding: 0,
    paddingTop: '68.83px',
    width: '195px',
  },
  washlabtitle: {
    lineHeight: 1,
    fontSize: '2.5rem'
  },
  mywashlabDescription: {
    paddingTop: '10vh',
    height: '100%',
    width: '412px',
    color: '#FFFFFF',
    fontFamily: 'Novozymes Italic',
    fontSize: '20px',
    lineHeight: '34px',
    textAlign: 'center',
  },
  mywashlabBtn: {
    color: '#ffffff',
    fontFamily: 'Novozymes Bold',
    height: '45px',
    width: '240px',
    justifyContent: 'center',
    borderRadius: '22.5px',
    textAlign: 'center',
    margin: 'auto',
    textTransform: 'none',
    fontSize: '16px'
  },
  aboutfooter: {
    marginTop: '10vh',
    paddingBottom: '20px',
    fontFamily: 'Novozymes Regular, Arial',
    color: '#657D8F',
    fontSize: '20px',
    lineHeight: '22px',
    width: '100%',
    textAlign: 'center'
  },
  bioscriptlogo: {
    backgroundImage: `url(${bioscriptImg})`,
    position: 'absolute',
    height: '188.04px',
    width: '384.42px',
    opacity: '0.8',
    bottom: '40px',
    left: '0px',
    backgroundRepeat: 'no-repeat'
  }
}));

export const AboutMyWashLab: React.SFC<Props> = ({ theme }) => {
  const classes = useStyles(theme);
  return (
    <div>
      <Grid className={`${classes.aboutpage} ${classes.aboutPageBackgroundImg}`} container alignContent='flex-start' justify='space-between' direction='row'>
        <Grid item xs={12}>
          <Container className={classes.aboutcontainer}>
            <img className={classes.mywashlablogo} src={novozymesLogo} alt='novozymes logo' />
            <Typography className={classes.washlabtitle}>WASHLAB</Typography>
          </Container>
        </Grid>
        <Grid container item xs={12} justify='center'>
          <Typography component='div' style={{ textAlign: 'center' }}>
            <Box className={classes.mywashlabDescription}>
              Washlab is a performance prediction tool
              for consumer laundry formulations.
            <br />
              <br />
            It gives as output the predicted stain removal performance, associated formulation cost and sustainability profile.
            <br />
            Washlab can be used for reformulation exercises
            (e.g. surfactant system optimization) as a screening tool allowing for significant savings in washing resources.
            </Box>
          </Typography>
        </Grid>
        <Grid container item xs={12} alignItems='flex-end' justify='center'>
          <Typography component='div'>
            <Box className={classes.aboutfooter}>Rethink Tomorrow</Box>
          </Typography>
        </Grid>
      </Grid>
      <Box className={classes.bioscriptlogo} />
    </div>
  );
};
