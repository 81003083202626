import { connect } from 'react-redux';
import { RootState } from '../reducers/root';
import { CompareEcoLabels } from '../components/CompareFormulas/CompareEcoLabels';
import { getSelectedFormulations } from '../utility/CustomFunctions';

const mapStateToProps = (state: RootState) => ({
  EcoLabel: state.FormulationSpace.ComparedSustainabilityData.EcoLabel,
  selectedUserFormulas: getSelectedFormulations(state.UserInputState.UserFormulations),
});

export default connect(mapStateToProps)(CompareEcoLabels);
