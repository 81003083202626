export const UPDATE_ISAUTHENTICATED = 'AUTH:TOGGLE_ISAUTHENTICATED';
export const AUTH_UPDATE_USER_INFO = 'AUTH:UPDATE_USER_INFO';

interface updateIsAuthenticated {
  type: typeof UPDATE_ISAUTHENTICATED;
  isAuthenticated: boolean;
}

interface updateUserInfo {
  type: typeof AUTH_UPDATE_USER_INFO;
  user: any;
}

export type AuthActionTypes = updateIsAuthenticated | updateUserInfo;

export const updateIsAuthenticated = (isAuthenticated: boolean): updateIsAuthenticated => ({
  type: UPDATE_ISAUTHENTICATED,
  isAuthenticated: isAuthenticated
});

export const updateUserInfo = (userInfo: any): updateUserInfo => ({
  type: AUTH_UPDATE_USER_INFO,
  user: userInfo
});
