import React, { useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import CompareSingleStains from '../../containers/CompareSingleStains';
import CompareGroupStains from '../../containers/CompareGroupStains';
import CompareFormulationCost from '../../containers/CompareFormulationCost';
import CompareSustainabilityImpact from '../../containers/CompareSustainabilityImpact';
import CompareEcoLabels from '../../containers/CompareEcoLabels';

interface Props {
  theme: Theme;
  region: string;
  UserFormulations: any;
  compareFormulasTab: number;
  changeCompareFormulasTab: (value: number) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '700px',
    height: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));


export const CompareFormulasPage: React.SFC<Props> = ({ theme, region, compareFormulasTab, UserFormulations, changeCompareFormulasTab }) => {
  const classes = useStyles(theme);

  const [open, setOpen] = useState(false);

  const formulaIsSelected = Object.values(UserFormulations).filter((userFormulation: any) => {
    return userFormulation.isSelected && userFormulation.data.region === region;
  }).length > 0;

  const handleChange = (event: React.ChangeEvent<{ value: unknown; }>) => {
    changeCompareFormulasTab(event.target.value as number);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };


  const renderChooseFormula = () => {
    return (
      <div className={classes.root}>
        <Grid container justify='center'>
          <Grid item xs={12}>
            <Typography variant='h5'>Please select formulations to compare</Typography>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <Grid container alignContent='center' justify='flex-start' style={{ paddingBottom: '50vh' }}>
      <Grid className={classes.formControl} container justify='flex-end'>
        <Grid item xs={3}>
          <FormControl className={classes.formControl} >
            <Select
              labelId='demo-controlled-open-select-label'
              id='demo-controlled-open-select'
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={compareFormulasTab}
              onChange={handleChange}
            >
              <MenuItem value={0}>Stain groups</MenuItem>
              <MenuItem value={1}>Single stains</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <section id='remcharts'>
          {!formulaIsSelected && renderChooseFormula()}
          {formulaIsSelected && compareFormulasTab === 0 && <CompareGroupStains theme={theme} />}
          {formulaIsSelected && compareFormulasTab === 1 && <CompareSingleStains theme={theme} />}
        </section>
      </Grid>
      <Grid item xs={11}>
        <section id='formulationcost'>
          {formulaIsSelected && <CompareFormulationCost theme={theme} />}
        </section>
      </Grid>
      <Grid item xs={11}>
        <section id='sustainabilityimpact'>
          {formulaIsSelected && <CompareSustainabilityImpact theme={theme} />}
        </section>
      </Grid>
      <Grid item xs={11}>
        <section id='sustainabilitylabel'>
          {formulaIsSelected && <CompareEcoLabels theme={theme} />}
        </section>
      </Grid>
    </Grid>
  );
};