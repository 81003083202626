import React, { useState } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { AuthProps } from '../../types/App';
import { validateForm } from '../../utility/FormValidation';
import { Auth } from '@aws-amplify/auth';
import { FormErrors } from './FormErrors';
import { FormControl, InputAdornment, IconButton, Button, Grid, Box, FilledInput } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';

interface Props {
  theme: Theme;
  authProps: AuthProps;
}

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5),
    width: '500px',
    height: '500px'
  },
  fullwidth: {
    width: '100%',
    padding: '18px 0'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '240px',
    height: '45px',
    borderRadius: '22.5px',
    fontFamily: 'Novozymes Bold, arial',
    fontSize: '16px',
    lineHeight: '20px',
    textTransform: 'none'
  },
  inputfont: {
    fontSize: '16px',
    lineHeight: '19px',
    paddingBottom: '10px'
  },
  novoitalicfont: {
    fontFamily: 'Novozymes Italic, arial'
  },
  novoboldfont: {
    fontFamily: 'Novozymes Bold, arial'
  },
  custombgcollor: {
    backgroundColor: '#F6F6F5'
  }
}));

export const SignIn: React.SFC<Props> = ({ theme, authProps, ...props }) => {
  const classes = useStyles(theme);
  const initialState = {
    username: '',
    email: '',
    password: '',
    showPassword: false,
    newPasswordRequired: false,
    errors: {
      cognito: null,
      blankfield: false
    },
    user: {}
  };

  const [formState, setFormState] = useState(initialState);

  const handleInputChange = (event: any) => {
    event.persist();
    setFormState(formState => ({ ...formState, [event.target.name]: event.target.value }));
  };

  const clearErrorState = () => {
    setFormState({
      ...formState,
      errors: {
        ...formState.errors,
        cognito: null,
        blankfield: false
      }
    });
  };

  const handleClickShowPassword = () => {
    setFormState({
      ...formState,
      showPassword: !formState.showPassword
    });
  };

  const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  };

  const validateCodeForm = () => {
    return formState.password.length > 3;
  };

  const handleSubmitNewPassword = async (event: any) => {
    if (event) {
      event.preventDefault();
      debugger;
      try {
        const user = await Auth.currentAuthenticatedUser();
        const loggedUser = await Auth.completeNewPassword(user, formState.password, {
          email: formState.email
        }).then(user => {
          console.log('loggedUser', loggedUser);
          setFormState({
            ...formState,
            newPasswordRequired: false
          });
        });
      } catch (error) {
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        setFormState({
          ...formState,
          errors: {
            ...formState.errors,
            cognito: err,
            blankfield: false
          }
        });
        authProps.updateIsAuthenticated(false);
      }
    }
  };

  const handleSubmit = async (event: any) => {
    if (event) {
      event.preventDefault();
      clearErrorState();
      const error = validateForm(event, formState);
      if (error) {
        setFormState({ ...formState, errors: { ...formState.errors, ...error } });
        return;
      }
      try {
        const user = await Auth.signIn(formState.username, formState.password);
        if (user.challengeName === 'NEW_PASSWORD_REQUIRED') {
          setFormState({
            ...formState,
            newPasswordRequired: true,
            user: { ...user },
            password: ''
          });
        } else {
          authProps.updateUserInfo(user);
          authProps.updateIsAuthenticated(true);
        }
      } catch (error) {
        let err = null;
        !error.message ? (err = { message: error }) : (err = error);
        setFormState({
          ...formState,
          errors: {
            ...formState.errors,
            cognito: err,
            blankfield: false
          }
        });
        authProps.updateIsAuthenticated(false);
      }
    }
  };

  const renderRequiredNewPassword = () => {
    return (
      <Grid container justify='center'>
        <Grid item className={classes.fullwidth}>
          <FormControl fullWidth>
            <label className={`${classes.inputfont} ${classes.novoboldfont}`} htmlFor='email'>
              New password required. Please enter your email
            </label>
            <FilledInput
              className={`${classes.inputfont} ${classes.novoitalicfont} ${classes.custombgcollor}`}
              required
              id='email'
              name='email'
              value={formState.email}
              onChange={e => handleInputChange(e)}
              placeholder='Enter your email'
              fullWidth
              style={{ height: '50px', padding: 0 }}
            />
          </FormControl>
        </Grid>
        <Grid item className={classes.fullwidth}>
          <FormControl fullWidth>
            <label className={`${classes.inputfont} ${classes.novoboldfont}`} htmlFor='password'>
              New password required
            </label>
            <FilledInput
              className={`${classes.inputfont} ${classes.novoitalicfont} ${classes.custombgcollor}`}
              required
              id='password'
              name='password'
              type={formState.showPassword ? 'text' : 'password'}
              value={formState.password}
              onChange={e => handleInputChange(e)}
              placeholder='Enter your new password'
              endAdornment={
                <InputAdornment position='end'>
                  <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                    {formState.showPassword ? <Visibility /> : <VisibilityOff />}
                  </IconButton>
                </InputAdornment>
              }
              fullWidth
              style={{ height: '50px', padding: 0 }}
            />
          </FormControl>
        </Grid>
        <Grid item>
          <Grid container justify='center' alignItems='center' direction='column'>
            <Grid item xs={12}>
              <Button className={classes.submit} variant='contained' color='primary' onClick={handleSubmitNewPassword} disabled={!validateCodeForm()}>
                Change password
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderSignInForm = () => {
    return (
      <Box component='div'>
        <FormErrors theme={theme} formerrors={formState.errors} />

        <Grid container justify='center'>
          <Grid item className={classes.fullwidth}>
            <FormControl fullWidth>
              <label className={`${classes.inputfont} ${classes.novoboldfont}`} htmlFor='username'>
                Username
              </label>
              <FilledInput
                className={`${classes.inputfont} ${classes.novoitalicfont} ${classes.custombgcollor}`}
                required
                id='username'
                name='username'
                autoComplete='email'
                placeholder='Enter your username'
                value={formState.username}
                onChange={e => handleInputChange(e)}
                fullWidth
                style={{ height: '50px', padding: 0 }}
              />
            </FormControl>
          </Grid>
          <Grid item className={classes.fullwidth}>
            <FormControl fullWidth>
              <label className={`${classes.inputfont} ${classes.novoboldfont}`} htmlFor='password'>
                Password
              </label>
              <FilledInput
                className={`${classes.inputfont} ${classes.novoitalicfont} ${classes.custombgcollor}`}
                required
                id='password'
                name='password'
                type={formState.showPassword ? 'text' : 'password'}
                value={formState.password}
                onChange={e => handleInputChange(e)}
                placeholder='Enter your password'
                endAdornment={
                  <InputAdornment position='end'>
                    <IconButton aria-label='toggle password visibility' onClick={handleClickShowPassword} onMouseDown={handleMouseDownPassword}>
                      {formState.showPassword ? <Visibility /> : <VisibilityOff />}
                    </IconButton>
                  </InputAdornment>
                }
                fullWidth
                style={{ height: '50px', padding: 0 }}
              />
            </FormControl>
          </Grid>
          <Grid item>
            <Grid container justify='center' alignItems='center' direction='column'>
              <Grid item xs={12}>
                <Button className={classes.submit} variant='contained' color='primary' onClick={handleSubmit}>
                  Sign in to WashLab
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Grid>
      </Box>
    );
  };

  return <div>{!formState.newPasswordRequired ? renderSignInForm() : renderRequiredNewPassword()}</div>;
};
