import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Paper from '@material-ui/core/Paper';
import { Bar } from 'react-chartjs-2';
import colors from '../../static/colorCharts.json';
import 'chartjs-plugin-error-bars';
import 'chartjs-plugin-annotation';
import Chart from 'chart.js';
import { stainGroupNames } from '../../static/Constants';
import { UserLocale } from '../../types/AppState';

interface Props {
  theme: Theme;
  benchmark: string;
  benchmarkType: string;
  StainGroups: any;
  region: string;
  BenchmarkData: any;
  ModelPredictionData: any;
  StainGroupNames: any;
  userLocale: UserLocale;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100%',
  },
  paperContainer: {
    padding: '20px 20px',
  }
}));

Chart.pluginService.register({
  beforeDraw: function (chartInstance: any, easing) {
    const ctx = chartInstance.ctx;
    const chartArea = chartInstance.chartArea;

    if (chartInstance.config.options.plugins.chartArea && chartInstance.config.options.plugins.chartArea.backgroundColor) {
      ctx.save();
      ctx.fillStyle = chartInstance.config.options.plugins.chartArea.backgroundColor;
      //@ts-ignore
      ctx.fillRect(chartArea.left, chartArea.top, chartArea.right - chartArea.left, chartArea.bottom - chartArea.top);
      ctx.restore();
    }
  },
});

export const StainGroupsGraph: React.SFC<Props> = ({ theme, benchmark, benchmarkType, StainGroups, region, BenchmarkData, ModelPredictionData, StainGroupNames, userLocale }) => {
  const classes = useStyles(theme);
  const stainNames = Object.keys(StainGroupNames);

  const benchmarkIsSet = (Number(benchmark) > 1) || (benchmarkType === 'user');

  let sumPerStainGroup: any = {};

  let benchmarkStainGroupData: number[] = [];
  let errorBarGroupData: any = [];

  stainNames.forEach((groupName: string) => {
    sumPerStainGroup = {
      ...sumPerStainGroup,
      [groupName]: Math.round(StainGroups[groupName].reduce((a: number, b: any) => a + b.Predicted, 0)),
    };

    if (benchmarkIsSet && BenchmarkData.hasOwnProperty(groupName)) {
      benchmarkStainGroupData = [...benchmarkStainGroupData, Math.round(BenchmarkData[groupName].Predicted.reduce((a: number, b: any) => a + b, 0))];
    }

    const groupLabel = stainGroupNames[region][groupName];
    const deviation = StainGroups[groupName].reduce((a: number, b: any) => a + b.Deviation, 0).toFixed(0);
    errorBarGroupData = {
      ...errorBarGroupData,
      [groupLabel]: {
        plus: +deviation,
        minus: -deviation,
      },
    };
  });

  const stainGroupData = Object.values(sumPerStainGroup);

  let stainGroupColors: any = [];

  // Generate bar colors based on the stain group name
  Object.keys(sumPerStainGroup).forEach((stainGroupName: string) => {
    //@ts-ignore
    stainGroupColors.push(colors[stainGroupName].backgroundColor);
  });

  let graphData: any = {};

  graphData = {
    labels: Object.values(stainGroupNames[region]),
    datasets: [
      {
        label: 'Stain Groups',
        backgroundColor: stainGroupColors,
        data: stainGroupData,
        errorBars: errorBarGroupData,
        barPercentage: 1.0,
        categoryPercentage: 1.0,
      },
    ],
  };

  if (benchmarkIsSet) {
    graphData = {
      ...graphData,
      datasets: [
        {
          label: 'Benchmark',
          backgroundColor: Object.values(colors).map((color) => color.benchmarkColor),
          data: benchmarkStainGroupData,
          borderWidth: {
            top: 2,
            right: 0,
            bottom: 0,
            left: 0,
          },
          borderColor: 'grey',
          barPercentage: 1.0,
          categoryPercentage: 1.0,
        },
        ...graphData.datasets,
      ],
    };
  }

  const { locale } = userLocale;
  return (
    <div className={classes.root}>
      <Paper className={classes.paperContainer} elevation={24}>
        <Grid container alignContent='center' justify='center'>
          <Grid item xs={12}>
            <Box style={{ minHeight: '400px' }} my={5}>
              <Bar
                data={graphData}
                width={200}
                height={400}
                options={{
                  animation: {
                    duration: 0,
                  },
                  maintainAspectRatio: false,
                  scales: {
                    xAxes: [
                      {
                        id: 'bar-x-axis2',
                        stacked: true,
                        ticks: {
                          fontFamily: 'Novozymes Bold, Arial',
                          fontSize: 14,
                          callback: function (label) {
                            if (/\s/.test(label)) {
                              return label.split(/(&)/);
                            } else {
                              return label;
                            }
                          }
                        },
                        gridLines: {
                          display: false,
                        },
                      },
                    ],
                    yAxes: [
                      {
                        ticks: {
                          beginAtZero: true,
                          fontFamily: 'Novozymes Bold, Arial',
                          fontSize: 14,
                          callback: function (value, index, values) {
                            return value.toLocaleString(locale);
                          }
                        },
                      },
                    ],
                  },
                  responsive: true,
                  legend: {
                    display: false,
                    position: 'top',
                  },
                  plugins: {
                    chartJsPluginErrorBars: {
                      width: '15%',
                      color: 'grey',
                    },
                    annotation: {
                      annotations: [
                        {
                          drawTime: 'afterDatasetsDraw',
                          id: 'hline',
                          type: 'line',
                          mode: 'vertical',
                          scaleID: 'y-axis-0',
                          borderColor: 'black',
                          borderWidth: 5,
                        },
                      ],
                    },
                  },
                  tooltips: {
                    mode: 'index',
                    intersect: false,
                    titleFontSize: 16,
                    bodyFontSize: 16,
                    callbacks: {
                      label: function (tooltipItem, data) {
                        if (tooltipItem.datasetIndex !== undefined && data.datasets !== undefined && data.datasets[0] !== undefined) {
                          let label: string = '';
                          //@ts-ignore
                          if (data.datasets.length === 1) {
                            //@ts-ignore
                            const value = Number(data.datasets[0].data[tooltipItem.index]);
                            label = `${data.datasets[0].label}: ${value.toLocaleString(locale)}`;
                          } else {
                            const { datasetIndex, index } = tooltipItem;
                            if (datasetIndex === 0) {
                              //@ts-ignore
                              const value = Number(data.datasets[0].data[index]);
                              label = `Benchmark: ${value.toLocaleString(locale)}`;
                            } else {
                              //@ts-ignore
                              const value = Number(data.datasets[datasetIndex].data[index]);
                              //@ts-ignore
                              //label = `Current formula: ${value.toLocaleString(locale)}`;
                              label = `${data.datasets[datasetIndex].label}: ${value.toLocaleString(locale)}`;
                            }
                          }
                          return label !== undefined ? label : '';
                        }
                        return 'test';
                      },
                    },
                  },
                }}
              />
            </Box>
          </Grid>
        </Grid>
      </Paper>
    </div>
  );
};
