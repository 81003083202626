import React, { useState, useEffect } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import { BenchmarkTable } from './BenchmarkTable';
import { UserLocale } from '../../types/AppState';

import benchmarks from '../../static/industryBenchmarks.json';

interface Props {
  theme: Theme;
  userLocale: UserLocale;
  region: string;
  benchmark: string;
  benchmarkData: any;
  setBenchmark: (benchmark: string) => void;
  setUserBenchmark: (value: string) => void;
  UserFormulations: any;
  generateBenchmarkFormulasList: () => void;
  BenchmarkFormulasList: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    minHeight: '276px',
    padding: '30px',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
  },
  inputTitle: {
    fontSize: '32px',
  },
}));

export const SelectBenchmark: React.SFC<Props> = ({ theme, userLocale, region, benchmark, benchmarkData, setBenchmark, setUserBenchmark, UserFormulations, generateBenchmarkFormulasList, BenchmarkFormulasList }) => {
  const classes = useStyles(theme);

  const [userSelectedFormula, setUserSelectedFormula] = useState('0');
  const [defaultBenchmark, setDefaultBenchmark] = useState('0');

  useEffect(() => {
    generateBenchmarkFormulasList();
    // eslint-disable-next-line
  }, []);

  const userFormulations = Object.values(UserFormulations).filter((userFormula: any) => userFormula.data.region === region);
  const userFormulaIsEmpty = userFormulations.length === 0;

  //@ts-ignore
  const regionalIndustryBenchmarks: { [index: string]: any; } = Object.assign({}, benchmarks[region]);


  const benchmarkCount = Object.values(regionalIndustryBenchmarks).length;

  const industryBenchmarksList = Object.values(BenchmarkFormulasList).slice(0, benchmarkCount - 1);
  const userBenchmarksList = Object.values(BenchmarkFormulasList).slice(benchmarkCount - 1);

  const handleChangeUserBenchmark = (value: string) => {
    if (value !== '0') {
      setUserBenchmark(value);
      setUserSelectedFormula(value);
    } else {
      setUserSelectedFormula(value);
    }
    setDefaultBenchmark('0');
  };

  const handleChangeDefaultBenchmark = (value: string) => {
    setUserSelectedFormula('0');
    setDefaultBenchmark(value);
    setBenchmark(value);
  };

  return (
    <Grid container className={classes.root} alignItems='flex-start' justify='center'>
      <Grid item sm={12}>
        <Typography className={classes.inputTitle} component='h2' align='center'>
          Select benchmark detergent
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <Grid container justify='space-around'>
          <Grid item sm={4}>
            <FormControl className={classes.formControl} fullWidth>
              <Select
                labelId='industry-benchmark-label'
                id='industry-benchmark-label'
                value={defaultBenchmark}
                onChange={(e) => handleChangeDefaultBenchmark(e.target.value as string)}
              >
                <MenuItem key={0} value='0'>
                  Choose benchmark
                </MenuItem>
                {industryBenchmarksList.map((benchmark: any) => (
                  <MenuItem key={benchmark[0]} value={benchmark[0]}>
                    {benchmark[1].name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={4}>
            <FormControl className={classes.formControl} fullWidth>
              <Select
                labelId='userBenchmark-label'
                id='userBenchmark-label'
                value={userSelectedFormula}
                disabled={userFormulaIsEmpty}
                onChange={(e) => handleChangeUserBenchmark(e.target.value as string)}
              >
                <MenuItem value='0'>
                  Your saved formulation
                </MenuItem>
                {userBenchmarksList.map((benchmark: any) => {
                  return (
                    <MenuItem key={benchmark[0]} value={benchmark[0]}>
                      {benchmark[1].name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>
          </Grid>
          <Grid item sm={8}>
            {benchmark !== '0' && <BenchmarkTable theme={theme} locale={userLocale.locale} benchmarkData={benchmarkData} />}
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
