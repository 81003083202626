import { connect } from 'react-redux';
import App from '../components/App';
import { RootState } from '../reducers/root';
import { AuthActionTypes, updateIsAuthenticated, updateUserInfo } from '../actions/AuthState';
import { setUserLocale, SetUserLocale, toggleTerms, AppActionTypes } from '../actions/AppState';
import { Dispatch } from 'react';

const mapStateToProps = (state: RootState) => ({
  authState: state.AuthState
});

const dispatchProps = (dispatch: Dispatch<AuthActionTypes | SetUserLocale | AppActionTypes>) => {
  return {
    updateIsAuthenticated: (isAuthenticated: boolean) => dispatch(updateIsAuthenticated(isAuthenticated)),
    updateUserInfo: (userInfo: any) => dispatch(updateUserInfo(userInfo)),
    //@ts-ignore
    setUserLocale: (): SetUserLocale => dispatch(setUserLocale()),
    toggleTerms: (value: boolean) => dispatch(toggleTerms(value))
  };
};

export default connect(mapStateToProps, dispatchProps)(App);
