import React from 'react';
import { createStyles, Theme, withStyles, WithStyles, makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import MuiDialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';

import { ExportSelectedFormulationsType } from '../../types/FormulationSpace';
import { UserFormulationsList } from './UserFormulationsList';
import { BenchmarkFormulationsList } from './BenchmarkFormulationsList';

interface Props {
  showExportDataDialog: boolean;
  UserFormulations: any;
  BenchmarkFormulas: any;
  toggleExportDataDialog: (value: boolean) => void;
  exportMultipleDataToXlsx: (selectedFormulations: ExportSelectedFormulationsType) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '688px',
    height: '100%',
  },
  paperContainer: {
    border: '1px solid #b1b1b1',
    width: '100%',
    padding: '10px',
  },
  exportButton: {
    width: '182px',
    height: '44px',
    textTransform: 'none',
    borderRadius: '22.5px',
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '18px',
  },
  novoFontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px',
  },
  linkButton: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '16px',
    margin: '0px 20px',
    textDecoration: 'underline'
  }
}));

const styles = (theme: Theme) =>
  createStyles({
    root: {
      margin: 0,
      padding: theme.spacing(2),
    },
    closeButton: {
      position: 'absolute',
      right: theme.spacing(1),
      top: theme.spacing(1),
      color: theme.palette.grey[500],
    },
  });

interface DialogTitleProps extends WithStyles<typeof styles> {
  id: string;
  children: React.ReactNode;
  onClose: () => void;
}

const DialogTitle = withStyles(styles)((props: DialogTitleProps) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant='h6'>{children}</Typography>
      {onClose ? (
        <IconButton aria-label='close' className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme: Theme) => ({
  root: {
    padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const DialogActions = withStyles((theme: Theme) => ({
  root: {
    margin: 0,
    padding: theme.spacing(1),
  },
}))(MuiDialogActions);

const generateAllBenchmarkState = (value: boolean, filteredBenchmarkFormulas: any) => {
  let generatedState: { [id: number]: boolean; } = {};
  filteredBenchmarkFormulas.forEach((benchmarkFormula: any) => {
    generatedState = {
      ...generatedState,
      [benchmarkFormula.benchmarkId]: value
    };
  });
  return generatedState;
};

const generateAllUserFormulationsState = (value: boolean, filteredUserFormulations: any) => {
  let generatedState: { [id: number]: boolean; } = {};
  filteredUserFormulations.forEach((userFormulation: any) => {
    generatedState = {
      ...generatedState,
      [userFormulation.formulationId]: value
    };
  });
  return generatedState;
};

export const ExportDataDialog: React.SFC<Props> = ({ showExportDataDialog, UserFormulations, BenchmarkFormulas, toggleExportDataDialog, exportMultipleDataToXlsx }) => {
  const classes = useStyles();

  // Removed the first 2 records that contains placeholder for the select component (no benchmark)
  let filteredBenchmarkFormulas = Object.entries(BenchmarkFormulas).slice(2).map((benchmarkFormula: any) => {
    return { benchmarkId: benchmarkFormula[0], name: benchmarkFormula[1].name };
  });

  let initialBenchmarkState: any = [];
  filteredBenchmarkFormulas.forEach((benchmarkFormula: any) => {
    initialBenchmarkState = {
      ...initialBenchmarkState,
      [benchmarkFormula.benchmarkId]: false
    };
  });

  let filteredUserFormulations: any = {};
  let initialUserFormulationState: any = {};

  filteredUserFormulations = Object.values(UserFormulations).map((userFormula: any) => {
    return { formulationId: userFormula.data.id, name: userFormula.data.name };
  });

  filteredUserFormulations.forEach((userFormula: any) => {
    initialUserFormulationState = {
      ...initialUserFormulationState,
      [userFormula.formulationId]: false
    };
  });

  const [state, setState] = React.useState({
    selectAllUserFormulations: false,
    selectAllBenchmarks: false,
  });

  const [userFormulationState, setUserFormulationState] = React.useState({
    ...initialUserFormulationState
  });

  const [benchmarkFormulationState, setBenchmarkFormulationState] = React.useState({
    ...initialBenchmarkState
  });

  const handleClose = () => {
    toggleExportDataDialog(false);
  };

  const handleUserFormulationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setUserFormulationState({ ...userFormulationState, [event.target.name]: event.target.checked });
  };

  const handleBenchmarkFormulationChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setBenchmarkFormulationState({ ...benchmarkFormulationState, [event.target.name]: event.target.checked });
  };

  const handleSelectAllUserFormulations = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, selectAllUserFormulations: event.target.checked });
    setUserFormulationState({ ...generateAllUserFormulationsState(event.target.checked, filteredUserFormulations) });
  };

  const handleSelectAllBenchmarkFormulas = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, selectAllBenchmarks: event.target.checked });
    setBenchmarkFormulationState({ ...generateAllBenchmarkState(event.target.checked, filteredBenchmarkFormulas) });
  };

  const handleSelectAll = () => {
    setState({ selectAllUserFormulations: true, selectAllBenchmarks: true, });
    setBenchmarkFormulationState({ ...generateAllBenchmarkState(true, filteredBenchmarkFormulas) });
    setUserFormulationState({ ...generateAllUserFormulationsState(true, filteredUserFormulations) });
  };

  const handleSelectNone = () => {
    setState({ selectAllUserFormulations: false, selectAllBenchmarks: false, });
    setBenchmarkFormulationState({ ...generateAllBenchmarkState(false, filteredBenchmarkFormulas) });
    setUserFormulationState({ ...generateAllUserFormulationsState(false, filteredUserFormulations) });
  };

  let selectedUserFormulas: string[] = [];
  let selectedBenchmarkFormulas: string[] = [];
  const handleExportSelected = () => {
    // get the formula id of all selected user formulations
    selectedUserFormulas = Object.entries(userFormulationState).filter(userFormula => userFormula[1]).map(userFormula => userFormula[0]);
    selectedBenchmarkFormulas = Object.entries(benchmarkFormulationState).filter(benchmarkFormula => benchmarkFormula[1]).map(benchmarkFormula => benchmarkFormula[0]);
    const selectedFormulations = {
      selectedUserFormulas,
      selectedBenchmarkFormulas
    };
    // todo: dispatch call to fetch and generate xlsx
    exportMultipleDataToXlsx(selectedFormulations);
  };

  return (
    <div>
      <Dialog onClose={handleClose} aria-labelledby='export-data-dialog' open={showExportDataDialog} maxWidth={'lg'} style={{ height: '100%' }}>
        <DialogTitle id='export-data-dialog' onClose={handleClose}>
          Export data
        </DialogTitle>
        <DialogContent className={classes.root} dividers>
          <Grid container>

            <UserFormulationsList
              selectAllUserFormulations={state.selectAllUserFormulations}
              filteredUserFormulations={filteredUserFormulations}
              userFormulationState={userFormulationState}
              handleUserFormulationChange={handleUserFormulationChange}
              handleSelectAllUserFormulations={handleSelectAllUserFormulations}
            />

            <BenchmarkFormulationsList
              selectAllBenchmarks={state.selectAllBenchmarks}
              filteredBenchmarkFormulas={filteredBenchmarkFormulas}
              benchmarkFormulationState={benchmarkFormulationState}
              handleBenchmarkFormulationChange={handleBenchmarkFormulationChange}
              handleSelectAllBenchmarkFormulas={handleSelectAllBenchmarkFormulas}
            />

          </Grid>
        </DialogContent>
        <DialogActions style={{ padding: '20px' }}>
          <Grid container alignItems='center' justify='space-between'>
            <Grid item>
              <Link
                className={classes.linkButton}
                component="button"
                color='inherit'
                variant="body2"
                onClick={handleSelectAll}
              >
                Select all
              </Link>
              <Link
                className={classes.linkButton}
                component="button"
                color='inherit'
                variant="body2"
                onClick={handleSelectNone}
              >
                Select none
              </Link>
            </Grid>
            <Grid item>
              <Link
                className={classes.linkButton}
                component="button"
                color='inherit'
                variant="body2"
                onClick={handleClose}
              >
                Cancel
              </Link>
              <Button className={classes.exportButton} autoFocus onClick={handleExportSelected} color='primary' variant='contained'>
                Export selected
            </Button>
            </Grid>
          </Grid>

        </DialogActions>
      </Dialog>
    </div>
  );
};
