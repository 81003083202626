import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';

import Grid from '@material-ui/core/Grid';

import { InputStepper } from './InputStepper';
import { InputNavButtons } from './InputNavButtons';

interface Props {
  theme: Theme;
  benchmark: string;
  step: number;
  nextStep: () => void;
  prevStep: () => void;
  optimizeRecipe: () => void;
  nextWindow: () => void;
  saveUserIngredientCost: () => void;
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    width: '100%',
    height: '144px'
  }
}));

export const InputFooter: React.SFC<Props> = ({ theme, benchmark, step, nextStep, prevStep, optimizeRecipe, nextWindow, saveUserIngredientCost }) => {
  const classes = useStyles(theme);
  return (
    <div className={classes.root} style={{ marginTop: 'auto' }}>
      <Grid container justify='center' alignContent='center'>
        <Grid item sm={6}>
          <InputStepper theme={theme} step={step} />
        </Grid>
        <Grid item sm={6}>
          <InputNavButtons
            theme={theme}
            benchmark={benchmark}
            step={step}
            nextStep={nextStep}
            prevStep={prevStep}
            optimizeRecipe={optimizeRecipe}
            nextWindow={nextWindow}
            saveUserIngredientCost={saveUserIngredientCost}
          />
        </Grid>
      </Grid>
    </div>
  );
};
