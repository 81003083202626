import { connect } from 'react-redux';
import { RootState } from '../reducers/root';
import { Dispatch } from 'react';
import { AppActionTypes, changeCompareFormulasTab } from '../actions/AppState';
import { CompareFormulasPage } from '../components/CompareFormulas/CompareFormulasPage';

const mapStateToProps = (state: RootState) => ({
  region: state.UserInputState.region,
  UserFormulations: state.UserInputState.UserFormulations,
  compareFormulasTab: state.AppState.compareFormulasTab,
});

const dispatchProps = (dispatch: Dispatch<AppActionTypes>) => {
  return {
    changeCompareFormulasTab: (value: number) => dispatch(changeCompareFormulasTab(value))
  };
};

export default connect(mapStateToProps, dispatchProps)(CompareFormulasPage);
