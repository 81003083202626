import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MainPredictionPage from '../../containers/MainPredictionPage';

interface Props {
  theme: Theme;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'auto',
  }
}));

export const MainPanel: React.SFC<Props> = ({ theme }) => {
  const classes = useStyles(theme);

  return (
    <Grid className={classes.root} container alignContent='center' justify='flex-start'>
      <Grid item sm={12}>
        <Box height='auto'><MainPredictionPage theme={theme} /></Box>
      </Grid>
    </Grid>
  );
};
