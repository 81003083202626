import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { RootState } from '../reducers/root';
import { saveFormulation, UserInputActionType } from '../actions/UserInput';
import { SaveFormulationForm } from '../components/FormulationSpace/SaveFormulationForm';

const mapStateToProps = (state: RootState) => ({
  UserFormulations: state.UserInputState.UserFormulations,
});

const dispatchProps = (dispatch: Dispatch<UserInputActionType>) => {
  return {
    //@ts-ignore
    saveFormulation: (formulationName?: string) => dispatch(saveFormulation(formulationName))
  };
};

export default connect(mapStateToProps, dispatchProps)(SaveFormulationForm);