import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { RootState } from '../reducers/root';
import { setIngredientCost, setIngredientCostMeta, updateBenchmarkValue, UserInputActionType } from '../actions/UserInput';
import { IngredientsInput } from '../components/FormulationSpace/IngredientsInput';

const mapStateToProps = (state: RootState) => ({
  ingredientCosts: state.UserInputState.ingredientCosts,
  ingredientsCurrency: state.UserInputState.ingredientsCurrency,
  benchmarkData: state.UserInputState.benchmarkData,
  weightUnits: state.UserInputState.weightUnits,
  currency: state.UserInputState.currency,
  region: state.UserInputState.region,
  userLocale: state.AppState.userLocale,
});

const dispatchProps = (dispatch: Dispatch<UserInputActionType>) => {
  return {
    //@ts-ignore
    setIngredientCostMeta: (weightUnits: string, currency: string) => dispatch(setIngredientCostMeta(weightUnits, currency)),
    //@ts-ignore
    setIngredientCost: (ingredient: string, value: number) => dispatch(setIngredientCost(ingredient, value)),
    //@ts-ignore
    updateBenchmarkValue: (ingredient: string, value: number) => dispatch(updateBenchmarkValue(ingredient, value)),
  };
};

export default connect(mapStateToProps, dispatchProps)(IngredientsInput);
