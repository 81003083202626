import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import Paper from '@material-ui/core/Paper';

interface Props {
  theme: Theme;
  benchmark: string;
  BenchmarkFormulasList: any;
  setNewBenchmarkFromList: (value: string) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'auto',
    fontFamily: 'Novozymes Regular, Arial'
  },
  overrides: {
    MuiTypography: {
      fontFamily: 'Novozymes Regular, Arial'
    }
  },
  novofontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px',
    padding: '5px'
  },
  novoFont16: {
    fontSize: '16px'
  },
  leftPaneLayout: {
    paddingLeft: '48px',
    paddingRight: '33px',
  },
}));

export const BenchmarkFormulas: React.SFC<Props> = ({ theme, benchmark, BenchmarkFormulasList, setNewBenchmarkFromList }) => {
  const classes = useStyles();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setNewBenchmarkFromList((event.target as HTMLInputElement).value);
  };

  return (
    <Paper square className={classes.root}>
      <Grid container alignContent='center' justify='center'>
        <Grid item sm={12} >
          <ExpansionPanel defaultExpanded >
            <ExpansionPanelSummary
              className={classes.leftPaneLayout}
              expandIcon={<ExpandMoreIcon fontSize='large' />}
              aria-controls="panel1a-content"
              id="panel1a-header"
            >
              <Typography className={classes.novoFont16}>
                Benchmark formulas
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.leftPaneLayout}>
              <FormControl component="fieldset">
                <RadioGroup name="benchmark" value={benchmark} onChange={handleChange}>
                  {Object.entries(BenchmarkFormulasList).map((benchmark: any, index: number) => {
                    return <FormControlLabel className={classes.novofontRegular} key={index} value={benchmark[0]} control={<Radio color='primary' />} label={<span className={classes.novofontRegular}>{benchmark[1][1].name}</span>} style={{ padding: '0px' }} />;
                  })}
                </RadioGroup>
              </FormControl>
            </ExpansionPanelDetails>
            <Typography className={classes.novofontRegular} variant='subtitle2' align='center' style={{
              paddingLeft: '48px',
              paddingRight: '33px',
            }} >
              Please note you can only select one benchmark
            </Typography>
          </ExpansionPanel>
        </Grid>
      </Grid>
    </Paper>
  );
};
