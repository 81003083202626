import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { RootState } from '../reducers/root';
import { changeAppTab, AppActionTypes, changePerformanceTab } from '../actions/AppState';
import { PerformancePredictionPage } from '../components/PerformancePrediction/PerformancePredictionPage';

const mapStateToProps = (state: RootState) => ({
  AuthState: state.AuthState,
  appTab: state.AppState.appTab,
  predictionTab: state.AppState.predictionTab,
  isFetching: state.UserInputState.isFetching,
  StainGroupNames: state.UserInputState.stainGroupNames,
  region: state.UserInputState.region,
  StainGroups: state.FormulationSpace.StainGroups,
  ModelPredictionData: state.FormulationSpace.ModelPredictionData,
  BenchmarkData: state.FormulationSpace.BenchmarkData
});

const dispatchProps = (dispatch: Dispatch<AppActionTypes>) => {
  return {
    changeAppTab: (appTab: number) => dispatch(changeAppTab(appTab)),
    changePerformanceTab: (value: number) => dispatch(changePerformanceTab(value)),
  };
};


export default connect(mapStateToProps, dispatchProps)(PerformancePredictionPage);
