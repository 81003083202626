import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import {
  LAS_INGREDIENT_LABEL,
  AES_INGREDIENT_LABEL,
  AEO_INGREDIENT_LABEL,
  PROTEASE_INGREDIENT_LABEL,
  AMYLASE_INGREDIENT_LABEL,
  LIPASE_INGREDIENT_LABEL,
  CITRATE_INGREDIENT_LABEL,
  SOAP_INGREDIENT_LABEL,
  MANNANASE_INGREDIENT_LABEL,
} from '../../static/Constants';

interface Props {
  theme: Theme;
  locale: string;
  benchmarkData: any;
}

const useStyles = makeStyles({
  table: {
    minWidth: '300px'
  }
});

export const BenchmarkTable: React.SFC<Props> = ({ theme, locale, benchmarkData }) => {
  const classes = useStyles(theme);
  const { las, aes, aeo, soap, amylase, protease, lipase, mannanase, citrate } = benchmarkData;

  const ingredientsList = {
    [LAS_INGREDIENT_LABEL]: las,
    [AES_INGREDIENT_LABEL]: aes,
    [AEO_INGREDIENT_LABEL]: aeo,
    [PROTEASE_INGREDIENT_LABEL]: protease,
    [AMYLASE_INGREDIENT_LABEL]: amylase,
    [LIPASE_INGREDIENT_LABEL]: lipase,
    [MANNANASE_INGREDIENT_LABEL]: mannanase,
    [CITRATE_INGREDIENT_LABEL]: citrate,
    [SOAP_INGREDIENT_LABEL]: soap,
  };
  const defaultValues = Object.entries(ingredientsList).map(row => {
    return { name: row[0], value: row[1] === undefined ? 0 : row[1] };
  });

  return (
    <TableContainer component={Paper}>
      <Table className={classes.table} aria-label='simple table'>
        <TableHead>
          <TableRow>
            <TableCell align='center' colSpan={2}>
              Details (Weight %)
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {defaultValues.map(row => (
            <TableRow key={row.name}>
              <TableCell component='th' scope='row'>
                {row.name}
              </TableCell>
              <TableCell align='right'>{`${row.value.toLocaleString(locale)} %`}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};
