import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';

interface Props {
  selectAllBenchmarks: boolean;
  filteredBenchmarkFormulas: any;
  benchmarkFormulationState: any;
  handleBenchmarkFormulationChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectAllBenchmarkFormulas: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '688px',
    height: 'auto',
  },
  paperContainer: {
    border: '1px solid #b1b1b1',
    width: '100%',
    padding: '10px',
  },
  exportButton: {
    width: '182px',
    height: '44px',
    textTransform: 'none',
    borderRadius: '22.5px',
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '18px',
  },
  novoFontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px',
  },
}));

export const BenchmarkFormulationsList: React.SFC<Props> = ({ selectAllBenchmarks, filteredBenchmarkFormulas, benchmarkFormulationState, handleBenchmarkFormulationChange, handleSelectAllBenchmarkFormulas }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      <Grid container item xs>
        <Paper className={classes.paperContainer} square elevation={0} style={{ height: '65px' }}>
          <FormControlLabel
            control={<Checkbox checked={selectAllBenchmarks} onChange={handleSelectAllBenchmarkFormulas} name='selectAllBenchmarks' color='primary' />}
            label='Benchmarks'
          />
        </Paper>
        <Paper className={classes.paperContainer} square elevation={0} style={{ height: '100%' }}>
          <FormGroup>
            {Object.values(filteredBenchmarkFormulas).map((benchmarkFormula: any, index: number) => {
              const { benchmarkId, name } = benchmarkFormula;
              return (
                <FormControlLabel
                  key={index}
                  classes={{
                    label: classes.novoFontRegular,
                  }}
                  //@ts-ignore
                  control={<Checkbox checked={benchmarkFormulationState[benchmarkId]} onChange={handleBenchmarkFormulationChange} name={benchmarkId} color='primary' />}
                  label={name}
                />
              );
            })}
          </FormGroup>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};
