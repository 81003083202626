import { connect } from 'react-redux';
import { RootState } from '../reducers/root';

import { CompareSingleStains } from '../components/CompareFormulas/CompareSingleStains';

const mapStateToProps = (state: RootState) => ({
  BenchmarkData: state.FormulationSpace.BenchmarkData,
  region: state.UserInputState.region,
  UserFormulations: state.UserInputState.UserFormulations,
  isFetching: state.UserInputState.isFetching
});

export default connect(mapStateToProps)(CompareSingleStains);
