import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { Bar } from 'react-chartjs-2';
import colors from '../../static/colorCharts.json';
import { IErrorBars } from '../../types/PerformancePrediction';
import 'chartjs-plugin-error-bars';
import 'chartjs-plugin-annotation';
import { Typography } from '@material-ui/core';
import Paper from '@material-ui/core/Paper';

interface Props {
  theme: Theme;
  benchmark: string;
  benchmarkType: string;
  StainGroups: any;
  StainGroupNames: string[];
  ModelPredictionData: any;
  BenchmarkData: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paperContainer: {
    padding: '30px 50px',
    marginBottom: '50px',
  }
}));

type gridSize = 12 | 11 | 10 | 6 | 5 | 4 | 3 | 2 | 0;

export const SingleStainsPage: React.SFC<Props> = ({ theme, benchmark, benchmarkType, StainGroups, StainGroupNames, ModelPredictionData, BenchmarkData }) => {
  const classes = useStyles(theme);
  const stainNames = Object.keys(StainGroupNames);
  const benchmarkIsSet = (Number(benchmark) > 1) || (benchmarkType === 'user');

  const StainGraph: React.SFC<any> = ({ data, stain, stainGroupName, benchmarkIsSet, BenchmarkData }) => {
    const dataPlot = Object.values(data).map((stain: any) => Math.round(stain.Predicted));
    const stainCodes = Object.values(data).map((stain: any) => stain.stain_code);
    const stainCount = stainCodes.length;

    let gridSize: gridSize;
    if (stainCount < 8 && stainCount >= 4) {
      gridSize = 5;
    } else if (stainCount < 4) {
      gridSize = 5;
    } else {
      gridSize = 12;
    }

    let predictedBenchmarkColor: string[] = [];
    let errorBars: IErrorBars = {};
    let errorBarsLabel: string[] = [];
    Object.values(data).forEach((stain: any, index: number) => {
      predictedBenchmarkColor = [...predictedBenchmarkColor, 'rgb(0,0,0,0)'];
      const { display_name_eng, Deviation } = stain;
      errorBarsLabel = [...errorBarsLabel, display_name_eng];
      errorBars = {
        ...errorBars,
        [display_name_eng]: {
          plus: Deviation,
          minus: -Deviation,
        },
      };
    });

    //@ts-ignore
    const graphColor = colors[stain].backgroundColor;
    //@ts-ignore
    const labelColor = colors[stain].labelColor;
    //@ts-ignore
    const chartAreaBackgroundColor = colors[stain].chartAreaBackgroundColor;

    let graphData: any = {
      labels: errorBarsLabel,
      datasets: [
        {
          label: stainGroupName,
          backgroundColor: graphColor,
          data: dataPlot,
          stainCode: stainCodes,
          maxBarThickness: 50,
          minBarThickness: 40,
          errorBars: errorBars,
        },
      ],
    };

    let predictedBenchmarkData: any = [];
    if (benchmarkIsSet) {
      predictedBenchmarkData = BenchmarkData.Predicted.map((data: any) => Math.round(data));

      graphData = {
        ...graphData,
        datasets: [
          {
            label: 'Benchmark',
            data: predictedBenchmarkData,
            backgroundColor: predictedBenchmarkData.map((data: number) => 'rgba(0,0,0,0)'),
            borderWidth: {
              top: 2,
              right: 0,
              bottom: 0,
              left: 0,
            },
            borderColor: 'grey',
            barPercentage: 1.0,
            categoryPercentage: 1.0,
            maxBarThickness: 50,
            minBarThickness: 40,
          },
          ...graphData.datasets,
        ],
      };
    }

    return (
      <Grid item xs={gridSize}>
        <Paper className={classes.paperContainer} elevation={24}>
          <Typography style={{ color: labelColor }}>{stainGroupName} stains</Typography>
          <Bar
            data={graphData}
            width={100}
            height={350}
            options={{
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    id: 'bar-x-axis2',
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                      maxRotation: 90,
                      minRotation: 90,
                      fontFamily: 'Novozymes Bold, Arial',
                      fontSize: 14,
                      fontColor: labelColor,
                    },
                    gridLines: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    ticks: {
                      beginAtZero: true,
                      min: 0,
                      max: 100,
                      fontFamily: 'Novozymes Bold, Arial',
                      fontColor: labelColor,
                    },
                  },
                ],
              },
              responsive: true,
              legend: {
                display: false,
                position: 'top',
                labels: {
                  fontFamily: 'Novozymes Bold, Arial',
                },
              },
              plugins: {
                chartArea: {
                  backgroundColor: chartAreaBackgroundColor,
                },
                chartJsPluginErrorBars: {
                  drawTime: 'beforeDatasetsDraw',
                  width: '15%',
                  color: 'grey',
                },
                annotation: {
                  annotations: [
                    {
                      drawTime: 'beforeDatasetsDraw',
                      id: 'hline',
                      type: 'bar',
                      mode: 'horizontal',
                      scaleID: 'y-axis-0',
                      borderColor: 'red',
                      borderWidth: 5,
                    },
                  ],
                },
              },
              animation: {
                duration: 0,
              },
              tooltips: {
                mode: 'index',
                intersect: false,
                titleFontSize: 16,
                bodyFontSize: 16,
                callbacks: {
                  title: function (tooltipItems, data) {
                    let title: string = '';
                    //@ts-ignore
                    if (tooltipItems.length === 1 && data.datasets.length !== 0) {
                      const { index, datasetIndex } = tooltipItems[0];
                      //@ts-ignore
                      title = data.datasets[datasetIndex].stainCode[index];
                    } else {
                      const { index } = tooltipItems[0];
                      //@ts-ignore
                      title = data.datasets[1].stainCode[index];
                    }
                    return title !== undefined ? title : '';
                  },
                  label: function (tooltipItems, data) {
                    if (tooltipItems.datasetIndex !== undefined && data.datasets !== undefined && data.datasets[0] !== undefined) {
                      let label: string = '';
                      //@ts-ignore
                      if (data.datasets.length === 1) {
                        //@ts-ignore
                        label = tooltipItems.label + ` ${data.datasets[0].data[tooltipItems.index]}`;
                      } else {
                        if (tooltipItems.datasetIndex === 0) {
                          const { index } = tooltipItems;
                          //@ts-ignore
                          const value = data.datasets[0].data[index];
                          label = `Benchmark: ${value}`;
                        } else {
                          //@ts-ignore
                          label = `${tooltipItems.label}: ${data.datasets[1].data[tooltipItems.index]}`;
                        }
                      }
                      return label !== undefined ? label : '';
                    }
                    return '';
                  },
                },
              },
            }}
          />
        </Paper>
      </Grid>
    );
  };

  return (
    <div className={classes.root}>
      <Grid container alignContent='center' justify='space-between'>
        {stainNames.map((stain: string) => {
          //@ts-ignore
          const stainGroupName = StainGroupNames[stain];
          return (
            <StainGraph key={stain} data={StainGroups[stain]} stain={stain} stainGroupName={stainGroupName} benchmarkIsSet={benchmarkIsSet} BenchmarkData={BenchmarkData[stain]} />
          );
        })}
      </Grid>
    </div>
  );
};
