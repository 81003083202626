import { connect } from 'react-redux';
import { RootState } from '../reducers/root';

import { CompareGroupStains } from '../components/CompareFormulas/CompareGroupStains';

const mapStateToProps = (state: RootState) => ({
  benchmark: state.UserInputState.benchmark,
  BenchmarkData: state.FormulationSpace.BenchmarkData,
  region: state.UserInputState.region,
  UserFormulations: state.UserInputState.UserFormulations,
  isFetching: state.UserInputState.isFetching,
  ComparedFormulationsData: state.FormulationSpace.ComparedFormulationsData,
  ComparedGroupFormulationsData: state.FormulationSpace.ComparedGroupFormulationsData,
  StainGroups: state.FormulationSpace.StainGroups,
  StainGroupNames: state.UserInputState.stainGroupNames,
  userLocale: state.AppState.userLocale,
});

export default connect(mapStateToProps)(CompareGroupStains);
