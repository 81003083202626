import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';

interface Props {
  theme: Theme;
  step: number;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%'
    },
    button: {
      marginRight: theme.spacing(1)
    },
    instructions: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1)
    }
  })
);

export const InputStepper: React.SFC<Props> = ({ theme, step }) => {
  const classes = useStyles(theme);

  const steps = ['WASH CONDITIONS', 'BENCHMARK', 'INGREDIENT COST'];

  return (
    <div className={classes.root}>
      <Stepper alternativeLabel activeStep={step}>
        {steps.map(label => (
          <Step key={label}>
            <StepLabel>{label}</StepLabel>
          </Step>
        ))}
      </Stepper>
    </div>
  );
};
