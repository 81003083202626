import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import regions from '../../static/regions.json';
import { RegionsType } from '../../types/UserInput';

interface Props {
  theme: Theme;
  region: string;
  setRegion: (region: string) => void;
  temperature: number;
  setTemperature: (value: number) => void;
  fetchStainsInfo: () => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    padding: '30px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300,
    padding: '10px'
  },
  inputTitle: {
    fontSize: '32px'
  }
}));

export const SelectWashCondition: React.SFC<Props> = ({ theme, region, setRegion, temperature, setTemperature, fetchStainsInfo }) => {
  const classes = useStyles(theme);

  const handleChange = (event: any) => {
    setRegion(event.target.value as string);
    fetchStainsInfo();
  };

  return (
    <Grid container className={classes.root} alignContent='center' justify='center'>
      <Grid item sm={12}>
        <Typography className={classes.inputTitle} component='h2' align='center'>
          Select wash conditions
        </Typography>
      </Grid>
      <Grid item sm={12}>
        <Grid container justify='center'>
          <Grid item sm={6}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel id='region-label'>Region</InputLabel>
              <Select labelId='region-label' id='region-label' value={region} onChange={e => handleChange(e)} name='region'>
                {Object.keys(regions).map((region: string) => (
                  <MenuItem key={region} value={region}>{(regions as RegionsType)[region].regionName}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justify='center'>
          <Grid item sm={6}>
            <FormControl className={classes.formControl} fullWidth>
              <InputLabel id='temperature-label'>Temperature</InputLabel>
              <Select
                labelId='temperature-label'
                id='temperature-label'
                value={temperature}
                onChange={e => setTemperature(e.target.value as number)}
                name='temperature'
              >
                {
                  (regions as RegionsType)[region].temperatures.map((temperature: number) => (
                    <MenuItem key={temperature} value={temperature}>{temperature} °C</MenuItem>
                  ))
                }
              </Select>
            </FormControl>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
