import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { AuthProps } from '../../types/App';
import authtablogo from '../../images/NZLogo.svg';

interface Props {
  theme: Theme;
  authProps: AuthProps;
}

const useStyles = makeStyles(theme => ({
  authpage: {
    height: '100%',
    width: '100%',
    margin: 0,
    padding: 0,
    border: 0
  },
  authtab: {
    height: '100vh'
  },
  authtablogo: {
    backgroundImage: `url(${authtablogo})`,
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',
    height: '100%'
  }
}));

// WIP
export const ChangePassword: React.SFC<Props> = ({ theme, authProps }) => {
  const classes = useStyles(theme);
  return (
    <Grid className={classes.authpage} container spacing={0} alignContent='center' justify='center'>
      <h2>Change Password Component</h2>
    </Grid>
  );
};
