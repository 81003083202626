import { UserFormulationsTypes } from '../types/UserInput';
import { predictedResultsType } from '../types/UserInput';
import * as XLSX from 'xlsx';
import { saveAs } from 'file-saver';
import {
  remPerStainHeaders,
  costSustainabilityHeaders,
  stainGroupLabels,
  STANDARD_DEVIATION,
} from '../static/Constants';

export const filterUserFormulations = (UserFormulations: UserFormulationsTypes, region: string, temperature: number): UserFormulationsTypes => {
  Object.fromEntries = (arr: any) => Object.assign({}, ...Array.from(arr, ([k, v]) => ({ [k]: v })));
  const filteredUserFormulations = Object.fromEntries(Object.entries(UserFormulations)
    .filter((userFormulation: UserFormulationsTypes) => userFormulation[1].data.region === region)
    .filter((userFormulation) => userFormulation[1].data.temperature === temperature));
  return filteredUserFormulations;
};

interface Ingredients { [key: string]: number; }

const getIngredientValues = (benchmarkData: any): Ingredients => {
  let { las, aes, aeo, soap, amylase, protease, lipase, mannanase, citrate } = benchmarkData;
  let ingredientValues = { las, aes, aeo, soap, amylase, protease, lipase, mannanase, citrate };
  return ingredientValues;
};

const getIngredientCost = (ingredientCosts: Ingredients): Ingredients => {
  let { las, aes, aeo, soap, amylase, protease, lipase, mannanase, citrate } = ingredientCosts;
  let ingredientCost = { las, aes, aeo, soap, amylase, protease, lipase, mannanase, citrate };
  return ingredientCost;
};

export const computeCost = (benchmarkData: any, ingredientCost: Ingredients): number => {
  const ingredientValues: Ingredients = getIngredientValues(benchmarkData);
  const cost: Ingredients = getIngredientCost(ingredientCost);

  let formulationCost: number = 0;
  Object.keys(ingredientValues).forEach((key: string) => {
    formulationCost += ((ingredientValues[key] / 100) * 1000) * cost[key];
  });
  return formulationCost;
};

export const s2ab = (s: any) => {
  const buf = new ArrayBuffer(s.length); //convert s to arrayBuffer
  const view = new Uint8Array(buf);  //create uint8array as viewer
  for (var i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xFF; //convert to octet
  return buf;
};

export const generateRemPerStainData = (formulationData: any, formulationName: string) => {
  let generatedData: any = [];
  let generatedRemPerStainData: any = [];
  Object.keys(formulationData).forEach(stainGroupName => {
    generatedData = formulationData[stainGroupName].map((predictedData: { [key: string]: string | number; }) => {
      const { stain_code, display_name_eng, Predicted, Deviation } = predictedData;
      const stainGroupLabel = stainGroupLabels[stainGroupName];
      return { formulationName, stainGroupLabel, stain_code, display_name_eng, Predicted: Math.round(Predicted as number), Deviation: parseFloat((Deviation as number).toFixed(2)) };
    });
    generatedRemPerStainData = [...generatedRemPerStainData, ...generatedData];
  });
  return generatedRemPerStainData;
};

export const generateXlsxFile = (generatedRemPerStainData: any, costSustainabilityData: any) => {
  const wb = XLSX.utils.book_new();
  wb.Props = {
    Title: "Washlab",
    Subject: "Exported data",
    Author: "Novozymes Washlab Team",
    CreatedDate: new Date()
  };
  wb.SheetNames.push("REM per Stain");
  wb.SheetNames.push("Cost-Sustainability Comparison");

  const ws = XLSX.utils.aoa_to_sheet([remPerStainHeaders]);
  XLSX.utils.sheet_add_json(ws, generatedRemPerStainData, {
    header: ['formulationName', 'stainGroupLabel', 'stain_code', 'display_name_eng', 'Predicted', 'Deviation'],
    skipHeader: true,
    origin: -1
  });
  wb.Sheets["REM per Stain"] = ws;

  const wsCostSustainabilityTab = XLSX.utils.aoa_to_sheet([costSustainabilityHeaders]);
  XLSX.utils.sheet_add_json(wsCostSustainabilityTab, costSustainabilityData, {
    header: ['formulationName',
      'predictedResponse',
      'sumDeviation',
      'totalFormulationCost',
      'co2',
      'chemical',
      'cdv',
      'isEcolabel'],
    skipHeader: true,
    origin: -1
  });
  wb.Sheets["Cost-Sustainability Comparison"] = wsCostSustainabilityTab;

  const wbout = XLSX.write(wb, { bookType: 'xlsx', type: 'binary' });
  saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), 'Washlab.xlsx');
};

export const parseResult = (value: number): number => {
  return parseFloat(value.toFixed(2));
};

export const getSelectedFormulations = (UserFormulations: any) => {
  Object.fromEntries = (arr: any) => Object.assign({}, ...Array.from(arr, ([k, v]) => ({ [k]: v })));
  const selectedFormulations = Object.entries(UserFormulations)
    .filter((userFormulation: any) => userFormulation[1].isSelected);
  return Object.fromEntries(selectedFormulations);
};

export const roundOffPredictedResults = (predictedResults: predictedResultsType) => {
  return {
    schema: { ...predictedResults.schema },
    data: Object.values(predictedResults.data).map((result) => {
      return { Predicted: Math.round(result.Predicted), [STANDARD_DEVIATION]: Math.round(result[STANDARD_DEVIATION]) };
    })
  };
};