import { applyMiddleware, createStore } from 'redux';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import { createRootReducer } from './reducers/root';
import thunk from 'redux-thunk';
// import logger from 'redux-logger';
import { composeWithDevTools } from 'redux-devtools-extension';

export const history = createBrowserHistory();

export default function configureStore(initialState = {}) {
  let middleware: any = [thunk, routerMiddleware(history)];
  if (process.env.NODE_ENV === 'development') {
    // middleware.push(logger);
    middleware = composeWithDevTools(applyMiddleware(...middleware));
  } else {
    middleware = applyMiddleware(...middleware);
  }
  return createStore(createRootReducer(history), initialState, middleware);
}
