import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { RootState } from '../reducers/root';
import { toggleBenchmarkIsSet, FormulationActionTypes } from '../actions/FormulationSpace';
import { saveFormulation } from '../actions/UserInput';
import { prevWindow, toggleAboutWashlabDetails } from '../actions/AppState';
import { LeftPanel } from '../components/FormulationSpace/LeftPanel';

const mapStateToProps = (state: RootState) => ({
  data: state.FormulationSpace,
  currency: state.UserInputState.currency,
  appTab: state.AppState.appTab,
  errorMessage: state.UserInputState.errorMessage,
  locale: state.AppState.userLocale.locale,
});

const dispatchProps = (dispatch: Dispatch<FormulationActionTypes>) => {
  return {
    toggleBenchmarkIsSet: (benchmarkIsSet: boolean) => dispatch(toggleBenchmarkIsSet(benchmarkIsSet)),
    //@ts-ignore
    saveFormulation: (formulationName?: string) => dispatch(saveFormulation(formulationName)),
    //@ts-ignore
    prevWindow: () => dispatch(prevWindow()),
    //@ts-ignore
    toggleAboutWashlabDetails: (value: boolean) => dispatch(toggleAboutWashlabDetails(value)),
  };
};

export default connect(mapStateToProps, dispatchProps)(LeftPanel);
