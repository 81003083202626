import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';


interface Props {
  theme: Theme;
  benchmarkIsSet: boolean;
  benchmarkName: string;
  datasets: any;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'auto',
  },
  Legend: {
    backgroundColor: 'rgba(197, 218, 0, 0.5)',
    width: '50px',
    height: '20px',
    margin: '0 5px'
  },
  benchmarkLegend: {
    backgroundColor: 'grey',
    marginTop: '10px',
    width: '50px',
    height: '4px',
    margin: '0 5px'
  },
  novoFontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px'
  },
}));

export const CustomLegend: React.SFC<Props> = ({ theme, benchmarkIsSet, benchmarkName, datasets }) => {
  const classes = useStyles(theme);

  let filteredDatasets = datasets;
  if (benchmarkIsSet) {
    filteredDatasets = datasets.filter((dataset: any, index: number) => index !== 0 && index !== 2 && index !== 4);
  }

  return (
    <React.Fragment>
      <Grid container item xs={12}>
        {benchmarkIsSet && <React.Fragment><Box className={classes.benchmarkLegend}></Box><Typography className={classes.novoFontRegular}>{benchmarkName}</Typography></React.Fragment>}
      </Grid >
      {filteredDatasets.map((dataset: any, index: number) => {
        const { label, backgroundColor } = dataset;
        return (
          <Grid key={index} container item xs={12}>
            <Box className={classes.Legend} style={{ backgroundColor: backgroundColor }}></Box>
            <Typography className={classes.novoFontRegular} >{label}</Typography>
          </Grid>
        );
      })}

    </React.Fragment>
  );
};
