import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Auth } from '@aws-amplify/auth';
import Link from '@material-ui/core/Link';

interface Props {
  theme: Theme;
  isAuthenticated: boolean;
  user: any;
  isFetching: boolean;
  updateIsAuthenticated: (isAuthenticated: boolean) => void;
  updateUserInfo: (userInfo: any) => void;
}

const useStyles = makeStyles((theme) => ({
  pallete: {
    primary: {
      main: '#ffffff',
    },
    secondary: {
      main: '#c5da00',
      light: 'rgb(10,150,63)',
    },
  },
  root: {
    flexGrow: 1,
  },
  title: {
    flexGrow: 1,
  },
  navGreeting: {
    marginRight: theme.spacing(2),
    flexGrow: 1,
  },
  buttons: {
    borderRadius: '22.5px',
    margin: theme.spacing(1),
    color: 'black',
  },
  navLogout: {
    width: '43px',
    height: '16px',
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px',
    fontWeight: 'normal',
    textAlign: 'right',
    color: '#2a2a2a',
    marginRight: '32px',
  },
  novoFontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px',
    marginLeft: 'auto',
    marginRight: '20px',
  }
}));

export const PerformanceNavbar: React.SFC<Props> = ({
  theme,
  isAuthenticated,
  user,
  isFetching,
  updateIsAuthenticated,
  updateUserInfo,
}) => {
  const classes = useStyles(theme);
  const { email } = user.attributes;

  const handleLogout = async () => {
    try {
      await Auth.signOut();
      updateIsAuthenticated(false);
      updateUserInfo({});
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <div className={classes.root}>
      <AppBar position='static' color='inherit' style={{ boxShadow: 'none', backgroundColor: '#fafafa' }}>
        <Toolbar style={{ height: '100px' }}>
          {!isAuthenticated && <Button color='secondary'>Login</Button>}
          {isAuthenticated && user !== null && user.hasOwnProperty('attributes') && (
            <React.Fragment>
              {isFetching && <Typography>Loading...</Typography>}
              <Typography className={classes.novoFontRegular}>{`Hello, ${email} `}</Typography>
              <Link className={classes.navLogout} component='button' onClick={handleLogout} underline='always'>
                Logout
              </Link>
            </React.Fragment>
          )}
        </Toolbar>
      </AppBar>
    </div>
  );
};
