import {
  UPDATE_MODEL_PREDICTION,
  UPDATE_STAIN_GROUPS,
  SET_ALL_STAIN_CODES,
  SET_BENCHMARK_DATA,
  TOGGLE_BENCHMARK_IS_SET,
  SET_BENCHMARK_RESPONSE,
  SET_PREDICTED_RESPONSE,
  UPDATE_USER_FORMULATIONS_DATA,
  UPDATE_BENCHMARK_FORMULATIONS_DATA,
  UPDATE_COMPARED_FORMULATIONS_DATA,
  UPDATE_COMPARED_GROUP_FORMULATIONS_DATA,
  SET_ISFETCHING_SUSTAINABILITY,
  UPDATE_SUSTAINABILITY_IMPACT_DATA,
  UPDATE_ECOLABEL_DATA,
  UPDATE_SUSTAINABILITY_BENCHMARK_DATA,
  UPDATE_FORMULATION_COST,
  UPDATE_COMPARED_ECOLABEL,
  FormulationActionTypes,
  UPDATE_COMPARED_IMPACTDATA,
  UPDATE_MODEL_PREDICTION_RESULTS,
  UPDATE_BENCHMARK_PREDICTION_RESULTS,
  UPDATE_SAVED_ECOLABEL_DATA,
  UPDATE_SAVED_IMPACT_DATA,
} from '../actions/FormulationSpace';

import { SustainabilityDataType, ComparedSustainabilityDataType, AllStainCodesType, SavedEcolabelDataType, SavedImpactDataType } from '../types/FormulationSpace';

export interface FormulationSpaceState {
  predictedResponse: number;
  benchmarkResponse: number;
  totalFormulationCost: number;
  AllStainCodes: AllStainCodesType;
  StainGroups: any;
  BenchmarkData: any;
  benchmarkIsSet: boolean;
  ModelPredictionData: any;
  UserFormulationsData: any;
  BenchmarkFormulationsData: any;
  ComparedFormulationsData: any;
  ComparedGroupFormulationsData: any;
  SavedEcolabelData: SavedEcolabelDataType;
  SavedImpactData: SavedImpactDataType;
  SustainabilityData: SustainabilityDataType;
  ComparedSustainabilityData: ComparedSustainabilityDataType;
  isFetchingSustainability: boolean;
  ModelPredictionResults: any;
  BenchmarkPredictionResults: any;
}

const defaultValues = {
  predictedResponse: 0,
  benchmarkResponse: 0,
  totalFormulationCost: 0,
  AllStainCodes: [],
  StainGroups: {},
  BenchmarkData: {},
  benchmarkIsSet: false,
  ModelPredictionData: {},
  UserFormulationsData: {},
  BenchmarkFormulationsData: {},
  ComparedFormulationsData: {},
  ComparedGroupFormulationsData: {},
  SustainabilityData: {
    EcoLabel: {},
    ImpactData: {},
    SustainabilityBenchmarkData: {},
  },
  ComparedSustainabilityData: {
    EcoLabel: {},
    ImpactData: {},
  },
  SavedEcolabelData: {
    Benchmark: {},
    SavedFormulation: {},
  },
  SavedImpactData: {
    Benchmark: {},
    SavedFormulation: {},
  },
  isFetchingSustainability: false,
  ModelPredictionResults: {},
  BenchmarkPredictionResults: {},
};

export default (state: FormulationSpaceState = defaultValues, action: FormulationActionTypes): FormulationSpaceState => {
  switch (action.type) {
    case UPDATE_MODEL_PREDICTION:
      return Object.assign({}, state, {
        ModelPredictionData: action.ModelPredictionData
      });
    case UPDATE_STAIN_GROUPS:
      return Object.assign({}, state, {
        StainGroups: action.StainGroups
      });
    case SET_ALL_STAIN_CODES:
      return Object.assign({}, state, {
        AllStainCodes: action.AllStainCodes
      });
    case SET_BENCHMARK_DATA:
      return Object.assign({}, state, {
        BenchmarkData: action.BenchmarkData,
        benchmarkIsSet: action.benchmarkIsSet
      });
    case TOGGLE_BENCHMARK_IS_SET:
      return Object.assign({}, state, {
        benchmarkIsSet: action.benchmarkIsSet
      });
    case SET_BENCHMARK_RESPONSE:
      return Object.assign({}, state, {
        benchmarkResponse: action.benchmarkResponse
      });
    case SET_PREDICTED_RESPONSE:
      return Object.assign({}, state, {
        predictedResponse: action.predictedResponse
      });
    case UPDATE_USER_FORMULATIONS_DATA:
      return Object.assign({}, state, {
        UserFormulationsData: {
          ...state.UserFormulationsData,
          [action.id]: action.UserFormulationsData
        }
      });
    case UPDATE_BENCHMARK_FORMULATIONS_DATA:
      return Object.assign({}, state, {
        BenchmarkFormulationsData: {
          ...state.BenchmarkFormulationsData,
          [action.id]: action.BenchmarkFormulationsData
        }
      });
    case UPDATE_COMPARED_FORMULATIONS_DATA:
      return Object.assign({}, state, {
        ComparedFormulationsData: action.data
      });
    case UPDATE_COMPARED_GROUP_FORMULATIONS_DATA:
      return Object.assign({}, state, {
        ComparedGroupFormulationsData: action.data
      });
    case SET_ISFETCHING_SUSTAINABILITY:
      return Object.assign({}, state, {
        isFetchingSustainability: action.value
      });
    case UPDATE_SUSTAINABILITY_IMPACT_DATA:
      return Object.assign({}, state, {
        SustainabilityData: {
          ...state.SustainabilityData,
          ImpactData: action.ImpactData
        }
      });
    case UPDATE_ECOLABEL_DATA:
      return Object.assign({}, state, {
        SustainabilityData: {
          ...state.SustainabilityData,
          EcoLabel: action.EcoLabel
        }
      });
    case UPDATE_SUSTAINABILITY_BENCHMARK_DATA:
      return Object.assign({}, state, {
        SustainabilityData: {
          ...state.SustainabilityData,
          SustainabilityBenchmarkData: action.SustainabilityBenchmarkData
        }
      });
    case UPDATE_FORMULATION_COST:
      return Object.assign({}, state, {
        totalFormulationCost: action.formulationCost
      });
    case UPDATE_COMPARED_ECOLABEL:
      return Object.assign({}, state, {
        ComparedSustainabilityData: {
          ...state.ComparedSustainabilityData,
          EcoLabel: {
            ...state.ComparedSustainabilityData.EcoLabel,
            ...action.data
          }
        }
      });
    case UPDATE_COMPARED_IMPACTDATA:
      return Object.assign({}, state, {
        ComparedSustainabilityData: {
          ...state.ComparedSustainabilityData,
          ImpactData: {
            ...state.ComparedSustainabilityData.ImpactData,
            ...action.data
          }
        }
      });
    case UPDATE_MODEL_PREDICTION_RESULTS:
      return Object.assign({}, state, {
        ModelPredictionResults: {
          ...state.ModelPredictionResults,
          [action.formulationId]: action.data
        }
      });
    case UPDATE_BENCHMARK_PREDICTION_RESULTS:
      return Object.assign({}, state, {
        BenchmarkPredictionResults: {
          ...state.BenchmarkPredictionResults,
          [action.benchmarkId]: action.data
        }
      });
    case UPDATE_SAVED_ECOLABEL_DATA:
      return Object.assign({}, state, {
        SavedEcolabelData: {
          ...state.SavedEcolabelData,
          [action.categoryGroup]: {
            //@ts-ignore
            ...state.SavedEcolabelData[action.categoryGroup],
            [action.id]: action.data
          }
        }
      });
    case UPDATE_SAVED_IMPACT_DATA:
      return Object.assign({}, state, {
        SavedImpactData: {
          ...state.SavedImpactData,
          [action.categoryGroup]: {
            //@ts-ignore
            ...state.SavedImpactData[action.categoryGroup],
            [action.id]: action.data
          }
        }
      });
    default:
      return state;
  }
};
