import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { RootState } from '../reducers/root';
import { setIngredientCost, setIngredientCostMeta, UserInputActionType } from '../actions/UserInput';
import { IngredientsPanel } from '../components/FormulationSpace/IngredientsPanel';

const mapStateToProps = (state: RootState) => ({
  ingredientCosts: state.UserInputState.ingredientCosts,
  benchmarkData: state.UserInputState.benchmarkData,
  weightUnits: state.UserInputState.weightUnits,
  currency: state.UserInputState.currency,
  region: state.UserInputState.region,
  surfactantSumExceeded: state.UserInputState.surfactantSumExceeded,
});

const dispatchProps = (dispatch: Dispatch<UserInputActionType>) => {
  return {
    //@ts-ignore
    setIngredientCostMeta: (weightUnits: string, currency: string) => dispatch(setIngredientCostMeta(weightUnits, currency)),
    //@ts-ignore
    setIngredientCost: (ingredient: string, value: number) => dispatch(setIngredientCost(ingredient, value))
  };
};

export default connect(mapStateToProps, dispatchProps)(IngredientsPanel);
