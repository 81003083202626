import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { RootState } from '../reducers/root';
import { prevWindow, AppActionTypes } from '../actions/AppState';
import { ConditionsSummary } from '../components/FormulationSpace/ConditionsSummary';

const mapStateToProps = (state: RootState) => ({
  region: state.UserInputState.region,
  temperature: state.UserInputState.temperature,
  benchmarkName: state.UserInputState.BenchmarkFormulasList[state.UserInputState.benchmark][1].name
});

const dispatchProps = (dispatch: Dispatch<AppActionTypes>) => {
  return {
    //@ts-ignore
    prevWindow: () => dispatch(prevWindow()),  // Edit button
  };
};

export default connect(mapStateToProps, dispatchProps)(ConditionsSummary);
