import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Grid, Button } from '@material-ui/core';

interface Props {
  theme: Theme;
  step: number;
  benchmark: string;
  nextStep: () => void;
  prevStep: () => void;
  optimizeRecipe: () => void;
  nextWindow: () => void;
  saveUserIngredientCost: () => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    height: '100%',
    width: '100%'
  },
  novoButtons: {
    width: '130px',
    height: '45px',
    borderRadius: '22.5px',
    fontFamily: 'Novozymes Bold',
    fontSize: '16px',
    lineHeight: '20px',
    textTransform: 'none'
  }
}));

export const InputNavButtons: React.SFC<Props> = ({ theme, benchmark, step, nextStep, prevStep, optimizeRecipe, nextWindow, saveUserIngredientCost }) => {
  const classes = useStyles(theme);
  const disabledButton = ((benchmark === "0") && (step === 1));
  return (
    <div className={classes.root}>
      <Grid className={classes.root} container alignContent='center' justify='center'>
        <Grid item sm={12}>
          <Grid id='gridcontainer1' container justify='space-around' alignContent='space-around'>
            <Grid item>
              {step > 0 && (
                <Button className={classes.novoButtons} variant='contained' color='primary' onClick={prevStep}>
                  Back
                </Button>
              )}
            </Grid>
            <Grid item>
              {step < 2 && (
                <Button className={classes.novoButtons} variant='contained' color='primary' onClick={nextStep} disabled={disabledButton}>
                  Next
                </Button>
              )}
              {step === 2 && (
                <Button
                  className={classes.novoButtons}
                  variant='contained'
                  color='primary'
                  onClick={() => {
                    optimizeRecipe();
                    saveUserIngredientCost();
                    nextWindow();
                  }}
                >
                  Go
                </Button>
              )}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
