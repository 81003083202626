import React, { useState } from 'react';
import { Auth } from '@aws-amplify/auth';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormControl, Button, Grid, FilledInput, Typography } from '@material-ui/core';
import { FormErrors } from './FormErrors';
interface Props {
  theme: Theme;
}

const useStyles = makeStyles(theme => ({
  paper: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(5),
    width: '500px',
    height: '500px'
  },
  fullwidth: {
    width: '100%',
    padding: '18px 0'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    width: '240px',
    height: '45px',
    borderRadius: '22.5px',
    fontFamily: 'Novozymes Bold, arial',
    fontSize: '16px',
    lineHeight: '20px',
    textTransform: 'none'
  },
  inputfont: {
    fontSize: '16px',
    lineHeight: '19px',
    paddingBottom: '10px'
  },
  novoitalicfont: {
    fontFamily: 'Novozymes Italic, arial'
  },
  novoboldfont: {
    fontFamily: 'Novozymes Bold, arial'
  },
  custombgcollor: {
    backgroundColor: '#F6F6F5'
  }
}));

export const ResetPassword: React.SFC<Props> = ({ theme }): any => {
  const classes = useStyles(theme);

  const [state, setState] = useState({
    code: '',
    email: '',
    password: '',
    codeSent: false,
    confirmed: false,
    confirmPassword: '',
    isConfirming: false,
    isSendingCode: false,
    errors: {
      cognito: null,
      blankfield: false
    }
  });

  const validateCodeForm = () => {
    return state.email.length > 3;
  };

  const validateResetForm = () => {
    return state.code.length > 0 && state.password.length > 0 && state.password === state.confirmPassword;
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setState({ ...state, [event.target.id]: event.target.value });
  };

  const handleSendCodeClick = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setState({ ...state, isSendingCode: true });

    try {
      await Auth.forgotPassword(state.email);
      setState({ ...state, codeSent: true });
    } catch (error) {
      console.log(error.message);
      setState({ ...state, isSendingCode: false });
    }
  };

  const handleConfirmClick = async (event: { preventDefault: () => void }) => {
    event.preventDefault();
    setState({ ...state, isConfirming: true });
    try {
      await Auth.forgotPasswordSubmit(state.email, state.code, state.password);
      setState({ ...state, confirmed: true });
    } catch (error) {
      console.log(error.message);
      let err = null;
      !error.message ? (err = { message: error }) : (err = error);
      setState({
        ...state,
        isConfirming: false,
        errors: {
          cognito: err,
          blankfield: false
        }
      });
    }
  };

  const renderRequestCodeForm = () => {
    return (
      <Grid container alignContent='space-around' justify='center'>
        <Grid item xs={12}>
          <FormControl fullWidth>
            <label className={`${classes.inputfont} ${classes.novoboldfont}`} htmlFor='email'>
              Email
            </label>
            <FilledInput
              className={`${classes.inputfont} ${classes.novoitalicfont} ${classes.custombgcollor}`}
              required
              id='email'
              name='email'
              autoComplete='email'
              placeholder='Enter your email address'
              fullWidth
              style={{ height: '50px', padding: 0 }}
              value={state.email}
              onChange={handleChange}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6}>
          <Grid container>
            <Button className={classes.submit} variant='contained' color='primary' onClick={handleSendCodeClick} disabled={!validateCodeForm()}>
              Send verification code
            </Button>
          </Grid>
        </Grid>
      </Grid>
    );
  };

  const renderConfirmationForm = () => {
    return (
      <React.Fragment>
        <FormErrors theme={theme} formerrors={state.errors} />
        <Grid container justify='space-around' alignContent='flex-start'>
          <Grid item sm={12}>
            <Typography>The verification code has been sent to your email.</Typography>
          </Grid>
          <Grid item sm={12}>
            <FormControl fullWidth>
              <label className={`${classes.inputfont} ${classes.novoboldfont}`} htmlFor='code'>
                Verification Code
              </label>
              <FilledInput
                className={`${classes.inputfont} ${classes.novoitalicfont} ${classes.custombgcollor}`}
                required
                id='code'
                name='code'
                placeholder='Enter your verification code'
                fullWidth
                style={{ height: '50px', padding: 0 }}
                value={state.code}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <FormControl fullWidth>
              <label className={`${classes.inputfont} ${classes.novoboldfont}`} htmlFor='email'>
                Enter new password
              </label>
              <FilledInput
                className={`${classes.inputfont} ${classes.novoitalicfont} ${classes.custombgcollor}`}
                required
                id='password'
                name='password'
                placeholder='Enter your new password'
                fullWidth
                type='password'
                style={{ height: '50px', padding: 0 }}
                value={state.password}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item sm={12}>
            <FormControl fullWidth>
              <label className={`${classes.inputfont} ${classes.novoboldfont}`} htmlFor='confirmpassword'>
                Confirm new password
              </label>
              <FilledInput
                className={`${classes.inputfont} ${classes.novoitalicfont} ${classes.custombgcollor}`}
                required
                id='confirmPassword'
                name='confirmPassword'
                placeholder='Enter your new password'
                fullWidth
                type='password'
                style={{ height: '50px', padding: 0 }}
                value={state.confirmPassword}
                onChange={handleChange}
              />
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <Grid container>
              <Button className={classes.submit} variant='contained' color='primary' onClick={handleConfirmClick} disabled={!validateResetForm()}>
                Confirm
              </Button>
            </Grid>
          </Grid>
        </Grid>
      </React.Fragment>
    );
  };

  const renderSuccessMessage = () => {
    return (
      <Grid container justify='space-evenly' alignContent='center'>
        <Grid item>
          <Typography component='h4'>Your password has been reset.</Typography>
        </Grid>
      </Grid>
    );
  };

  return <div>{!state.codeSent ? renderRequestCodeForm() : !state.confirmed ? renderConfirmationForm() : renderSuccessMessage()}</div>;
};
