import { AuthActionTypes } from '../actions/AuthState';
export const UPDATE_ISAUTHENTICATED = 'AUTH:TOGGLE_ISAUTHENTICATED';
export const AUTH_UPDATE_USER_INFO = 'AUTH:UPDATE_USER_INFO';

export interface AuthState {
  isAuthenticated: boolean;
  user: any;
}

const initialAuthState = {
  isAuthenticated: false,
  user: null
};

export default (state: AuthState = initialAuthState, action: AuthActionTypes): AuthState => {
  switch (action.type) {
    case UPDATE_ISAUTHENTICATED:
      return Object.assign({}, state, {
        isAuthenticated: action.isAuthenticated
      });
    case AUTH_UPDATE_USER_INFO:
      return Object.assign({}, state, {
        user: action.user
      });
    default:
      return state;
  }
};
