const PROTEASE_LABEL = 'Protein',
  DETERGENCY_LABEL = 'Other Foods & Non-Food',
  AMYLASE_LABEL = 'Starch',
  JB_LABEL = 'JB',
  JBS_LABEL = 'JBS',
  LIPASE_LABEL = 'Natural Fats & Oils',
  CHIPS_LABEL = 'CHIPS',
  AISE_LABEL = 'AISE',
  MANNANASE_LABEL = 'Food Thickeners & Stabilizers';


export const EU_REGION_ID = 'eu';
export const CN_REGION_ID = 'cn';

const AISE_NAME = 'AISE';
const PROTEASE_NAME = 'Protease';
export const AMYLASE_NAME = 'Amylase';
export const LIPASE_NAME = 'Lipase';
const DETERGENCY_NAME = 'Detergency';
export const JB_NAME = 'JB';
export const JBS_NAME = 'JBS';
const CHIPS_NAME = 'CHIPS';
export const MANNANASE_NAME = 'Mannanase';
const MANNANASE_ID = 'mannanase';

export const stainGroupLabels: { [groupName: string]: string; } = {
  [AISE_NAME]: AISE_LABEL,
  [PROTEASE_NAME]: PROTEASE_LABEL,
  [AMYLASE_NAME]: AMYLASE_LABEL,
  [LIPASE_NAME]: LIPASE_LABEL,
  [MANNANASE_NAME]: MANNANASE_LABEL,
  [DETERGENCY_NAME]: DETERGENCY_LABEL,
  [JB_NAME]: JB_LABEL,
  [JBS_NAME]: JBS_LABEL,
  [CHIPS_NAME]: CHIPS_LABEL,
};

export const stainGroupNames: any = {
  'eu': {
    [AISE_NAME]: AISE_LABEL,
    [PROTEASE_NAME]: PROTEASE_LABEL,
    [AMYLASE_NAME]: AMYLASE_LABEL,
    [LIPASE_NAME]: LIPASE_LABEL,
    [MANNANASE_NAME]: MANNANASE_LABEL,
    [DETERGENCY_NAME]: DETERGENCY_LABEL,
  },
  'cn': {
    [JB_NAME]: JB_LABEL,
    [JBS_NAME]: JBS_LABEL,
    [CHIPS_NAME]: CHIPS_LABEL,
    [PROTEASE_NAME]: PROTEASE_LABEL,
    [AMYLASE_NAME]: AMYLASE_LABEL,
    [DETERGENCY_NAME]: DETERGENCY_LABEL,
  }
};


export const stainGroupsLayout: any = {
  "eu": {
    1: {
      groupCategory: 'Standard stain groups',
      stainGroupNames: [AISE_NAME]
    },
    2: {
      groupCategory: 'Enzyme sensitive stains',
      stainGroupNames: [PROTEASE_NAME, AMYLASE_NAME, LIPASE_NAME, MANNANASE_NAME]
    },
    3: {
      groupCategory: 'Detergency stains',
      stainGroupNames: [DETERGENCY_NAME]
    }
  },
  "cn": {
    1: {
      groupCategory: 'Standard stain groups',
      stainGroupNames: [JB_NAME, JBS_NAME, CHIPS_NAME]
    },
    2: {
      groupCategory: 'Enzyme sensitive stains',
      stainGroupNames: [PROTEASE_NAME, AMYLASE_NAME]
    },
    3: {
      groupCategory: 'Detergency stains',
      stainGroupNames: [DETERGENCY_NAME]
    }
  },
};

const LAS_INGREDIENT_ID = 'las';
export const LAS_INGREDIENT_LABEL = 'LAS';
const AES_INGREDIENT_ID = 'aes';
export const AES_INGREDIENT_LABEL = 'AES/SLES';
const AEO_INGREDIENT_ID = 'aeo';
export const AEO_INGREDIENT_LABEL = 'AEO';

const PROTEASE_INGREDIENT_ID = 'protease';
export const PROTEASE_INGREDIENT_LABEL = 'Protease';
const AMYLASE_INGREDIENT_ID = 'amylase';
export const AMYLASE_INGREDIENT_LABEL = 'Amylase';
const LIPASE_INGREDIENT_NAME = 'lipase';
export const LIPASE_INGREDIENT_LABEL = 'Lipase';
export const MANNANASE_INGREDIENT_LABEL = 'Mannanase';

const CITRATE_INGREDIENT_ID = 'citrate';
export const CITRATE_INGREDIENT_LABEL = 'Citrate';
const SOAP_INGREDIENT_ID = 'soap';
export const SOAP_INGREDIENT_LABEL = 'Soap';

export const ingredientList: { [key: string]: string; } = {
  [LAS_INGREDIENT_ID]: LAS_INGREDIENT_LABEL,
  [AES_INGREDIENT_ID]: AES_INGREDIENT_LABEL,
  [AEO_INGREDIENT_ID]: AEO_INGREDIENT_LABEL,
  [PROTEASE_INGREDIENT_ID]: PROTEASE_INGREDIENT_LABEL,
  [AMYLASE_INGREDIENT_ID]: AMYLASE_INGREDIENT_LABEL,
  [LIPASE_INGREDIENT_NAME]: LIPASE_INGREDIENT_LABEL,
  [MANNANASE_ID]: MANNANASE_INGREDIENT_LABEL,
  [CITRATE_INGREDIENT_ID]: CITRATE_INGREDIENT_LABEL,
  [SOAP_INGREDIENT_ID]: SOAP_INGREDIENT_LABEL
};

const surfactantsLayout = {
  name: 'Surfactants',
  ingredients: [
    {
      [LAS_INGREDIENT_ID]: LAS_INGREDIENT_LABEL
    },
    {
      [AES_INGREDIENT_ID]: AES_INGREDIENT_LABEL
    },
    {
      [AEO_INGREDIENT_ID]: AEO_INGREDIENT_LABEL
    }
  ],
};

const euEnzymesLayout = {
  name: 'Enzymes',
  ingredients: [
    {
      [PROTEASE_INGREDIENT_ID]: PROTEASE_INGREDIENT_LABEL
    },
    {
      [AMYLASE_INGREDIENT_ID]: AMYLASE_INGREDIENT_LABEL
    },
    {
      [LIPASE_INGREDIENT_NAME]: LIPASE_INGREDIENT_LABEL
    },
    {
      [MANNANASE_ID]: MANNANASE_INGREDIENT_LABEL
    }
  ]
};

const cnEnzymesLayout = {
  name: 'Enzymes',
  ingredients: [
    {
      [PROTEASE_INGREDIENT_ID]: PROTEASE_INGREDIENT_LABEL
    },
    {
      [AMYLASE_INGREDIENT_ID]: AMYLASE_INGREDIENT_LABEL
    },
  ]
};

const othersLayout = {
  name: 'Others',
  ingredients: [
    {
      [CITRATE_INGREDIENT_ID]: CITRATE_INGREDIENT_LABEL
    },
    {
      [SOAP_INGREDIENT_ID]: SOAP_INGREDIENT_LABEL
    }
  ],
};

export const ingredientsInputLayouts: any = {
  'eu': [surfactantsLayout, euEnzymesLayout, othersLayout],
  'cn': [surfactantsLayout, cnEnzymesLayout, othersLayout]
};

const PURE_INGREDIENT_TEXT = 'Price of 100% pure ingredient';

export const toolTipDescription: { [key: string]: string; } = {
  [LAS_INGREDIENT_ID]: PURE_INGREDIENT_TEXT,
  [AES_INGREDIENT_ID]: PURE_INGREDIENT_TEXT,
  [AEO_INGREDIENT_ID]: PURE_INGREDIENT_TEXT,
  [PROTEASE_INGREDIENT_ID]: 'Progress Uno 100L',
  [AMYLASE_INGREDIENT_ID]: 'Amplify Prime 100L',
  [LIPASE_INGREDIENT_NAME]: 'Lipex Evity 200L',
  [MANNANASE_ID]: 'Mannaway 200L',
  [CITRATE_INGREDIENT_ID]: PURE_INGREDIENT_TEXT,
  [SOAP_INGREDIENT_ID]: PURE_INGREDIENT_TEXT
};

export const TARGET_PH = 7.0;
export const DOSE = 60;

// Header for xlsx file export
export const remPerStainHeaders = [
  'Benchmark/Saved formulation name',
  'Stain group',
  'Stain code',
  'Stain name',
  'REM',
  '+/- variation of REM'
];

export const costSustainabilityHeaders = [
  'Benchmark/Saved formulation name',
  'Predicted response(total REM)',
  '+/- variation of predicted response (total REM)',
  'Formulation cost (EUR/ton)',
  'CO2 in million tons',
  'Chemicals in million tons',
  'Critical dillution volume in 1000m3',
  'Check for Eco label'
];

export const STANDARD_DEVIATION = 'Standard Deviation';

export const defaultCosts = {
  las: 0,
  aes: 0,
  aeo: 0,
  protease: 0,
  amylase: 0,
  lipase: 0,
  mannanase: 0,
  citrate: 0,
  soap: 0,
};

export const initialBenchmark = {
  las: 0,
  aes: 0,
  aeo: 0,
  protease: 0,
  amylase: 0,
  lipase: 0,
  mannanase: 0,
  citrate: 0,
  soap: 0,
};