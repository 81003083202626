import { connect } from 'react-redux';
import { RootState } from '../reducers/root';
import { CompareSustainabilityImpact } from '../components/CompareFormulas/CompareSustainabilityImpact';

const mapStateToProps = (state: RootState) => ({
  benchmark: state.UserInputState.benchmark,
  BenchmarkFormulasList: state.UserInputState.BenchmarkFormulasList,
  EcoLabel: state.FormulationSpace.SustainabilityData.EcoLabel,
  ImpactData: state.FormulationSpace.SustainabilityData.ImpactData,
  SustainabilityBenchmarkData: state.FormulationSpace.SustainabilityData.SustainabilityBenchmarkData,
  locale: state.AppState.userLocale.locale,
  CompareSustainabilityData: state.FormulationSpace.ComparedSustainabilityData,
  selectedUserFormulas: state.FormulationSpace.ComparedFormulationsData,
});

export default connect(mapStateToProps)(CompareSustainabilityImpact);
