import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers/root';
import { convertValue } from '../utility/Formula';
import { api_fetch } from '../utility/Api';
import { generateXlsxFile, generateRemPerStainData, parseResult, roundOffPredictedResults, computeCost } from '../utility/CustomFunctions';
import industryBenchmarks from '../static/industryBenchmarks.json';

import {
  ModelPredictionType,
  ComparedEcoLabelDataType,
  ComparedImpactDataType,
  AllStainCodesType,
  ExportSelectedFormulationsType,
} from '../types/FormulationSpace';

import { TARGET_PH, DOSE, STANDARD_DEVIATION } from '../static/Constants';

export const UPDATE_MODEL_PREDICTION = 'FORMULATION:UPDATE_MODEL_PREDICTION';
export const UPDATE_STAIN_GROUPS = 'FORMULATION:UPDATE_STAIN_GROUPS';
export const SET_ALL_STAIN_CODES = 'FORMULATION:SET_ALL_STAIN_CODES';
export const SET_BENCHMARK_DATA = 'FORMULATION:SET_BENCHMARK_DATA';
export const TOGGLE_BENCHMARK_IS_SET = 'FORMULATION:TOGGLE_BENCHMARK_IS_SET';
export const SET_BENCHMARK_RESPONSE = 'FORMULATION:SET_BENCHMARK_RESPONSE';
export const SET_PREDICTED_RESPONSE = 'FORMULATION:SET_PREDICTED_RESPONSE';
export const UPDATE_USER_FORMULATIONS_DATA = 'FORMULATION:UPDATE_USER_FORMULATIONS_DATA';
export const UPDATE_BENCHMARK_FORMULATIONS_DATA = 'FORMULATION:UPDATE_BENCHMARK_FORMULATIONS_DATA';
export const UPDATE_COMPARED_FORMULATIONS_DATA = 'FORMULATION:UPDATE_COMPARED_FORMULATIONS_DATA';
export const UPDATE_COMPARED_GROUP_FORMULATIONS_DATA = 'FORMULATION:UPDATE_COMPARED_GROUP_FORMULATIONS_DATA';
export const UPDATE_SUSTAINABILITY_IMPACT_DATA = 'FORMULATION:UPDATE_SUSTAINABILITY_IMPACT_DATA';
export const UPDATE_ECOLABEL_DATA = 'FORMULATION:UPDATE_ECOLABEL_DATA';
export const UPDATE_SUSTAINABILITY_BENCHMARK_DATA = 'FORMULATION:UPDATE_SUSTAINABILITY_BENCHMARK_DATA';
export const SET_ISFETCHING_SUSTAINABILITY = 'FORMULATION:SET_ISFETCHING_SUSTAINABILITY';
export const UPDATE_FORMULATION_COST = 'FORMULATION:UPDATE_FORMULATION_COST';
export const UPDATE_COMPARED_ECOLABEL = 'FORMULATION:UPDATE_COMPARED_ECOLABEL';
export const UPDATE_COMPARED_IMPACTDATA = 'FORMULATION:UPDATE_COMPARED_IMPACTDATA';
export const UPDATE_MODEL_PREDICTION_RESULTS = 'FORMULATION:UPDATE_MODEL_PREDICTION_RESULTS';
export const UPDATE_BENCHMARK_PREDICTION_RESULTS = 'FORMULATION:UPDATE_BENCHMARK_PREDICTIONRESULTS';
export const UPDATE_SAVED_ECOLABEL_DATA = 'FORMULATION:UPDATE_SAVED_ECOLABEL_DATA';
export const UPDATE_SAVED_IMPACT_DATA = 'FORMULATION:UPDATE_SAVED_IMPACT_DATA';

export const EXPORT_COMPARED_FORMULAS = 'comparison';
export const EXPORT_SINGLE_FORMULA = 'single';

interface UpdateModelPrediction {
  type: typeof UPDATE_MODEL_PREDICTION;
  ModelPredictionData: ModelPredictionType;
}

interface UpdateStainGroups {
  type: typeof UPDATE_STAIN_GROUPS;
  StainGroups: any;
}

interface SetStainCodes {
  type: typeof SET_ALL_STAIN_CODES;
  AllStainCodes: AllStainCodesType;
}

interface SetBenchmarkData {
  type: typeof SET_BENCHMARK_DATA;
  BenchmarkData: any;
  benchmarkIsSet: boolean;
}

interface ToggleBenchmarkIsSet {
  type: typeof TOGGLE_BENCHMARK_IS_SET;
  benchmarkIsSet: boolean;
}

interface SetBenchmarkResponse {
  type: typeof SET_BENCHMARK_RESPONSE;
  benchmarkResponse: number;
}

interface SetPredictedResponse {
  type: typeof SET_PREDICTED_RESPONSE;
  predictedResponse: number;
}

interface UpdateUserFormulationsData {
  type: typeof UPDATE_USER_FORMULATIONS_DATA;
  id: number;
  UserFormulationsData: any;
}

interface UpdateBenchmarkFormulationsData {
  type: typeof UPDATE_BENCHMARK_FORMULATIONS_DATA;
  id: number;
  BenchmarkFormulationsData: any;
}

interface UpdateComparedFormulationsData {
  type: typeof UPDATE_COMPARED_FORMULATIONS_DATA;
  data: any;
}

interface UpdateComparedGroupFormulationsData {
  type: typeof UPDATE_COMPARED_GROUP_FORMULATIONS_DATA,
  data: any;
}

interface UpdateSustainabilityImpactData {
  type: typeof UPDATE_SUSTAINABILITY_IMPACT_DATA;
  ImpactData: any;
}

interface SetIsFetchingSustainability {
  type: typeof SET_ISFETCHING_SUSTAINABILITY;
  value: boolean;
}

interface UpdateEcolabelData {
  type: typeof UPDATE_ECOLABEL_DATA;
  EcoLabel: any;
}

interface UpdateSustainabilityBenchmarkData {
  type: typeof UPDATE_SUSTAINABILITY_BENCHMARK_DATA;
  SustainabilityBenchmarkData: any;
}

interface UpdateFormulationCost {
  type: typeof UPDATE_FORMULATION_COST;
  formulationCost: number;
}

interface UpdateComparedEcoLabelData {
  type: typeof UPDATE_COMPARED_ECOLABEL;
  data: ComparedEcoLabelDataType;
}

interface UpdateComparedImpactData {
  type: typeof UPDATE_COMPARED_IMPACTDATA;
  data: ComparedEcoLabelDataType;
}

interface UpdateModelPredictionResults {
  type: typeof UPDATE_MODEL_PREDICTION_RESULTS;
  formulationId: number;
  data: any;
}

interface UpdateBenchmarkPredictionResults {
  type: typeof UPDATE_BENCHMARK_PREDICTION_RESULTS;
  benchmarkId: number;
  data: any;
}

interface UpdateSavedEcolabelData {
  type: typeof UPDATE_SAVED_ECOLABEL_DATA;
  id: number;
  categoryGroup: string;
  data: any;
}

interface UpdateSavedImpactData {
  type: typeof UPDATE_SAVED_IMPACT_DATA;
  id: number;
  categoryGroup: string;
  data: any;
}

type ThunkResult<R extends Action> = ThunkAction<void, RootState, undefined, R>;

export type FormulationActionTypes =
  | UpdateModelPrediction
  | UpdateStainGroups
  | SetStainCodes
  | SetBenchmarkData
  | ToggleBenchmarkIsSet
  | SetBenchmarkResponse
  | SetPredictedResponse
  | UpdateUserFormulationsData
  | UpdateBenchmarkFormulationsData
  | UpdateComparedFormulationsData
  | UpdateComparedGroupFormulationsData
  | UpdateSustainabilityImpactData
  | SetIsFetchingSustainability
  | UpdateEcolabelData
  | UpdateSustainabilityBenchmarkData
  | UpdateFormulationCost
  | UpdateComparedEcoLabelData
  | UpdateComparedImpactData
  | UpdateModelPredictionResults
  | UpdateBenchmarkPredictionResults
  | UpdateSavedEcolabelData
  | UpdateSavedImpactData;

export const updateModelPrediction = (modelPrediction: ModelPredictionType): UpdateModelPrediction => ({
  type: UPDATE_MODEL_PREDICTION,
  ModelPredictionData: modelPrediction,
});

export const updateStainGroups = (StainGroups: any): UpdateStainGroups => ({
  type: UPDATE_STAIN_GROUPS,
  StainGroups: StainGroups,
});

export const setStainCodes = (AllStainCodes: AllStainCodesType): SetStainCodes => ({
  type: SET_ALL_STAIN_CODES,
  AllStainCodes: AllStainCodes,
});

export const setBenchmarkData = (): ThunkResult<SetPredictedResponse | SetBenchmarkData> => {
  return (dispatch, getState) => {
    const benchmarkData = getState().FormulationSpace.StainGroups;

    let tempBenchmarkData: any = {};

    Object.keys(benchmarkData).forEach((groupName: string) => {
      tempBenchmarkData = {
        ...tempBenchmarkData,
        [groupName]: {
          stain_code: benchmarkData[groupName].map((stain: any) => stain.stain_code),
          display_name_eng: benchmarkData[groupName].map((stain: any) => stain.display_name_eng),
          Predicted: benchmarkData[groupName].map((stain: any) => stain.Predicted),
        },
      };
    });

    dispatch(setPredictedResponse());

    dispatch({
      type: SET_BENCHMARK_DATA,
      BenchmarkData: tempBenchmarkData,
      benchmarkIsSet: true,
    });

    dispatch(updateSustainabilityBenchmarkData());

  };
};

export const toggleBenchmarkIsSet = (benchmarkIsSet: boolean): ToggleBenchmarkIsSet => ({
  type: TOGGLE_BENCHMARK_IS_SET,
  benchmarkIsSet: benchmarkIsSet,
});

const setBenchmarkResponse = (setBenchmarkResponse: number): SetBenchmarkResponse => ({
  type: SET_BENCHMARK_RESPONSE,
  benchmarkResponse: setBenchmarkResponse,
});

export const setPredictedResponse = (): ThunkResult<SetBenchmarkResponse | SetPredictedResponse> => {
  return (dispatch, getState) => {
    const modelPredictionResults = getState().FormulationSpace.ModelPredictionData.data;
    let sumBenchmarkResponse: number = 0;
    const benchmarkIsSet = getState().FormulationSpace.benchmarkIsSet;

    sumBenchmarkResponse = modelPredictionResults.reduce((a: number, b: { [id: string]: number; }) => Math.round(a) + Math.round(b.Predicted), 0);

    const newPredictedResponse = Math.round(sumBenchmarkResponse);

    if (!benchmarkIsSet) {
      if ((Number(getState().UserInputState.benchmark) > 1) || (getState().UserInputState.benchmarkType === 'user')) {
        dispatch(setBenchmarkResponse(newPredictedResponse));
      } else {
        // no benchmark selected, set the benchmark response to zero
        dispatch(setBenchmarkResponse(0));
      }
    }

    dispatch({
      type: SET_PREDICTED_RESPONSE,
      predictedResponse: newPredictedResponse,
    });
    if (getState().FormulationSpace.isFetchingSustainability === false) {
      dispatch(evaluateFormulaSustainability());
    }
    dispatch(computeFormulationCost());
    dispatch(generateComparedGroupFormulationsData());
  };
};

export const predictUserFormulation = (id: number): ThunkResult<UpdateModelPredictionResults | UpdateUserFormulationsData> => {
  return (dispatch, getState) => {
    // check and see if the id already exist in the UserFormulationsData
    const currentUserFormulationsData = Object.keys(getState().FormulationSpace.UserFormulationsData);

    // do nothing if formulation id already exists
    if (currentUserFormulationsData.indexOf(id.toString()) !== -1) {
      // the formulaId was already fetched, just generate data for comparison
      dispatch(generateComparedFormulationsData());
      return;
    }

    const userFormulations = Object.values(getState().UserInputState.UserFormulations);

    let userFormula: any = Object.assign({}, Object.values(userFormulations.filter((formula: any) => formula.data.id === id))[0]);

    const { region, temperature, las, aes, aeo, soap, citrate, lipexevity200l, amplifyprime100l, progressuno100l, mannaway200l } = userFormula.data;

    let body = {
      region: region,
      Temperature: temperature,
      las: convertValue(las, region),
      aes: convertValue(aes, region),
      aeo: convertValue(aeo, region),
      soap: convertValue(soap, region),
      citrate: convertValue(citrate, region),
      target_ph: TARGET_PH, // fixed value
      lipexevity200l: convertValue(lipexevity200l, region),
      mannaway200l: convertValue(mannaway200l, region),
      amplifyprime100l: convertValue(amplifyprime100l, region),
      progressuno100l: convertValue(progressuno100l, region),
      Swatch_Types: getState().FormulationSpace.AllStainCodes.map(stainCode => stainCode.stain_code),
    };

    try {
      api_fetch('model_prediction', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
        .then((results: any) => {
          const predictedResults = roundOffPredictedResults(JSON.parse(results));
          dispatch(updateModelPredictionResults(id, predictedResults));  // save the raw results to ModelPredictionResults

          const { StainGroups } = getState().FormulationSpace;
          let newStainGroups: any = Object.assign({}, StainGroups);

          //  Generate new StainGroups with predicted results and deviation
          Object.keys(StainGroups).forEach((groupStains: any) => {
            newStainGroups = {
              ...newStainGroups,
              [groupStains]: [],
            };
            StainGroups[groupStains].forEach((stain: any, index: number) => {
              newStainGroups = {
                ...newStainGroups,

                [groupStains]: [
                  ...newStainGroups[groupStains],
                  {
                    ...stain,
                    Predicted: Math.round(predictedResults.data[stain.index].Predicted),
                    Deviation: Math.round(predictedResults.data[stain.index]['Standard Deviation']),
                  },
                ],
              };
            });
          });
          // Saves the results to FormulationSpace.UserFormulationsData state
          dispatch(updateUserFormulationData(newStainGroups, id));
        })
        .then(() => {
          dispatch(generateComparedFormulationsData());
        })
        .catch((error: any) => {
          console.log('Fetch error', error);
        });
    } catch (error) {
      console.log('Something went wrong', error);
    }
  };
};

const updateUserFormulationData = (UserFormulationsData: any, id: number): UpdateUserFormulationsData => ({
  type: UPDATE_USER_FORMULATIONS_DATA,
  id: id,
  UserFormulationsData: UserFormulationsData,
});

export const generateComparedFormulationsData = (): ThunkResult<UpdateComparedFormulationsData> => {
  return (dispatch, getState) => {
    // get formulaId of selected formulas to compare
    const selectedFormulations = Object.entries(getState().UserInputState.UserFormulations)
      .filter((userFormulation: any) => userFormulation[1].isSelected)
      .map((formulaId: any) => formulaId[0]);

    let newCompareFormulationsData: any = {};

    const UsersFormulationsData = getState().FormulationSpace.UserFormulationsData;
    try {
      selectedFormulations.forEach((formulationId: string) => {
        Object.keys(UsersFormulationsData[formulationId]).forEach((stainGroup: string) => {
          newCompareFormulationsData = {
            ...newCompareFormulationsData,
            [formulationId]: {
              ...newCompareFormulationsData[formulationId],
              name: getState().UserInputState.UserFormulations[formulationId].data.name,
              [stainGroup]: {
                data: UsersFormulationsData[formulationId][stainGroup].map((stain: any) => stain.Predicted),
              },
            },
          };
        });
      });
    } catch (error) {
      console.log('Something went wrong', error);
    }

    dispatch({
      type: UPDATE_COMPARED_FORMULATIONS_DATA,
      data: newCompareFormulationsData,
    });

    // Create data for group comparison
    dispatch(generateComparedGroupFormulationsData());
  };
};

// This will generate the data for grouped stains
export const generateComparedGroupFormulationsData = (): ThunkResult<UpdateComparedGroupFormulationsData> => {
  return (dispatch, getState) => {
    const compareFormulationsData = getState().FormulationSpace.ComparedFormulationsData;
    const labels = Object.keys(getState().FormulationSpace.StainGroups);

    let newComparedGroupFormulationsData: any = {};

    // Generate the benchmark dataset if benchmark is set
    if (Number(getState().UserInputState.benchmark) > 1) {
      const benchmarkData = getState().FormulationSpace.BenchmarkData;
      Object.keys(benchmarkData).forEach(stainGroupName => {
        newComparedGroupFormulationsData = {
          Benchmark: {
            ...newComparedGroupFormulationsData.Benchmark,
            [stainGroupName]: Math.round(benchmarkData[stainGroupName].Predicted.reduce((a: number, b: any) => a + b, 0))
          }
        };
      });
    }

    Object.entries(compareFormulationsData).forEach((userFormula: any) => {
      const { name } = userFormula[1];  //  Stain Group name
      labels.forEach((groupName: string) => {
        newComparedGroupFormulationsData = {
          ...newComparedGroupFormulationsData,
          [name]: {
            ...newComparedGroupFormulationsData[name],
            [groupName]: Math.round(userFormula[1][groupName].data.reduce((a: number, b: any) => a + b, 0))  // Predicted sum
          }
        };
      });
    });

    dispatch(updateComparedGroupFormulationsData(JSON.parse(JSON.stringify(newComparedGroupFormulationsData))));
    //  console.log('newComparedGroupFormulationsData', newComparedGroupFormulationsData);
  };
};

const updateComparedGroupFormulationsData = (data: any): UpdateComparedGroupFormulationsData => ({
  type: UPDATE_COMPARED_GROUP_FORMULATIONS_DATA,
  data: data
});


const updateSustainabilityImpactData = (ImpactData: any): UpdateSustainabilityImpactData => ({
  type: UPDATE_SUSTAINABILITY_IMPACT_DATA,
  ImpactData: ImpactData,
});

const updateEcolabelData = (EcoLabel: any): UpdateEcolabelData => ({
  type: UPDATE_ECOLABEL_DATA,
  EcoLabel: EcoLabel,
});

export const evaluateFormulaSustainability = (): ThunkResult<SetIsFetchingSustainability | UpdateEcolabelData | UpdateSustainabilityImpactData> => {
  return (dispatch, getState) => {
    // generate api body
    const { las, aes, aeo, soap, citrate, amylase, protease, lipase, mannanase } = getState().UserInputState.benchmarkData;

    let body = {
      dose: DOSE,
      progressuno100l: protease,
      amplifyprime100l: amylase,
      lipexevity200l: lipase,
      mannaway200l: mannanase,
      las: las,
      aes: aes,
      aeo: aeo,
      soap: soap,
      citrate: citrate,
    };

    try {
      dispatch(setIsFetchingSustainability(true));
      api_fetch('ecolabel', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
        .then((results) => {
          dispatch(updateEcolabelData(JSON.parse(results)));
        })
        .then(() => dispatch(setIsFetchingSustainability(false)))
        .catch((error) => {
          console.log('Problem encountered while fetching ecolabel data', error);
        });
    } catch (error) {
      console.log('Error encountered while fetching Sustainability data', error);
    }

    // generate body for sustainability_impact endpoint - requires region field
    body = Object.assign({}, body, {
      region: getState().UserInputState.region,
    });

    try {
      dispatch(setIsFetchingSustainability(true));
      api_fetch('sustainability_impact', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
        .then((results) => {
          dispatch(updateSustainabilityImpactData(JSON.parse(results)));
        })
        .then(() => dispatch(setIsFetchingSustainability(false)))
        .catch((error) => {
          console.log('Error encountered while fetching data', error);
        });
    } catch (error) {
      console.log('Something went wrong', error);
    }
  };
};

export const updateSustainabilityBenchmarkData = (): ThunkResult<UpdateSustainabilityBenchmarkData> => {
  return (dispatch, getState) => {
    const { las, aes, aeo, soap, citrate, amylase, protease, lipase, mannanase } = getState().UserInputState.benchmarkData;

    let body = {
      region: getState().UserInputState.region,
      dose: DOSE,
      progressuno100l: protease,
      amplifyprime100l: amylase,
      lipexevity200l: lipase,
      mannaway200l: mannanase,
      las: las,
      aes: aes,
      aeo: aeo,
      soap: soap,
      citrate: citrate,
    };

    try {
      api_fetch('sustainability_impact', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
        .then((results) => {
          dispatch({
            type: UPDATE_SUSTAINABILITY_BENCHMARK_DATA,
            SustainabilityBenchmarkData: JSON.parse(results),
          });
        })
        .catch((error) => {
          console.log('Problem encountered while fetching data', error);
        });
    } catch (error) {
      console.log('Problem encountered', error);
    }
  };
};

export const setIsFetchingSustainability = (value: boolean): SetIsFetchingSustainability => ({
  type: SET_ISFETCHING_SUSTAINABILITY,
  value: value,
});

const updateFormulationCost = (value: number): UpdateFormulationCost => ({
  type: UPDATE_FORMULATION_COST,
  formulationCost: value,
});

export const computeFormulationCost = (): ThunkResult<UpdateFormulationCost> => {
  return (dispatch, getState) => {
    interface Ingredients {
      [key: string]: number;
    }

    const getIngredientValues = (): Ingredients => {
      let { las, aes, aeo, soap, amylase, protease, lipase, mannanase, citrate } = getState().UserInputState.benchmarkData;
      let ingredientValues = { las, aes, aeo, soap, amylase, protease, lipase, mannanase, citrate };
      return ingredientValues;
    };

    const getIngredientCost = (): Ingredients => {
      let { las, aes, aeo, soap, amylase, protease, lipase, mannanase, citrate } = getState().UserInputState.ingredientCosts;
      let ingredientCost = { las, aes, aeo, soap, amylase, protease, lipase, mannanase, citrate };
      return ingredientCost;
    };

    const ingredientValues = getIngredientValues();
    const ingredientCost = getIngredientCost();

    let formulationCost: number = 0;
    Object.keys(ingredientValues).forEach((key: string) => {
      formulationCost += ((ingredientValues[key] / 100) * 1000) * ingredientCost[key];
    });

    dispatch(updateFormulationCost(Number(formulationCost.toFixed(2))));
  };
};

const fetchEcolabelResults = async (ecolabelBody: { [keys: string]: string | number; }) => {
  return api_fetch('ecolabel', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(ecolabelBody),
  })
    .then((results) => {
      return JSON.parse(results);
    })
    .catch((error) => {
      console.log('Problem encountered while fetching ecolabel data', error);
    });
};

const fetchImpactResults = async (impactBody: { [keys: string]: string | number; }) => {
  return api_fetch('sustainability_impact', {
    method: 'post',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(impactBody),
  })
    .then((results) => {
      return JSON.parse(results);
    })
    .catch((error) => {
      console.log('Problem encountered while fetching ecolabel data', error);
    });
};

const updateComparedEcolabel = (comparedEcolabel: ComparedEcoLabelDataType): UpdateComparedEcoLabelData => ({
  type: UPDATE_COMPARED_ECOLABEL,
  data: comparedEcolabel
});

const updateComparedImpactData = (comparedImpactData: ComparedImpactDataType): UpdateComparedImpactData => ({
  type: UPDATE_COMPARED_IMPACTDATA,
  data: comparedImpactData
});

export const evaluateSustainability = (formulaId: number): ThunkResult<UpdateComparedEcoLabelData | UpdateComparedImpactData> => {
  return (dispatch, getState) => {

    const recordExist = Object.keys(getState().FormulationSpace.ComparedSustainabilityData.EcoLabel).indexOf(formulaId.toString()) !== -1;
    if (recordExist) return;

    const dose = DOSE;  // hardcoded/fixed value
    const region = getState().UserInputState.region;

    // get the formula from UserInput.UserFormulations state
    const { las, aes, aeo, soap, citrate, lipexevity200l, amplifyprime100l, progressuno100l, mannaway200l } = getState().UserInputState.UserFormulations[formulaId].data;

    const ecolabelBody = { dose, las, aes, aeo, soap, citrate, lipexevity200l, amplifyprime100l, progressuno100l, mannaway200l };
    const impactBody = { region, ...ecolabelBody };

    // generate ecolabel results
    try {
      fetchEcolabelResults(ecolabelBody)
        // save to FormulationSpace.ComparedSustainabilityData.ImpactData
        .then((results) => dispatch(updateComparedEcolabel({ [formulaId]: Object.assign({}, results) })))
        .catch(error => console.log('something went wrong', error));
    } catch (error) {
      console.log('Something went wrong', error);
    }

    // generate sustainability_impact results
    try {
      fetchImpactResults(impactBody)
        // save to FormulationSpace.ComparedSustainabilityData.ImpactData
        .then((results) => dispatch(updateComparedImpactData({ [formulaId]: Object.assign({}, results) })))
        .catch(error => console.log('something went wrong', error));
    } catch (error) {
      console.log('Something went wrong', error);
    }

    const resultValues = getState().UserInputState.UserFormulations[formulaId].data;
    return resultValues;
  };
};

export const generateCostSustainabilityData = (formulationId: number): ThunkResult<any> => {
  return (dispatch, getState) => {

    try {
      const formulationName = getState().UserInputState.UserFormulations[formulationId].data.name;
      const { predictedResponse, totalFormulationCost } = getState().FormulationSpace;
      const sumDeviation = getState().FormulationSpace.ModelPredictionResults[formulationId].data
        .reduce((a: number, b: any) => a + b[STANDARD_DEVIATION], 0);
      //@ts-ignore
      const comparedImpact = getState().FormulationSpace.ComparedSustainabilityData.ImpactData[formulationId];
      const { co2, chemical, cdv } = comparedImpact;

      const EcoLabel = getState().FormulationSpace.SustainabilityData.EcoLabel;
      //@ts-ignore
      const isEcolabel = EcoLabel['criterion 2'] === 'approved' && EcoLabel['criterion 3'] === 'approved';

      // data for Cost-Sustainability tab
      const costSustainabilityData = [{
        formulationName: formulationName,
        predictedResponse: predictedResponse,
        sumDeviation: Math.round(sumDeviation),
        totalFormulationCost: parseResult(totalFormulationCost),
        co2: parseResult(co2 as number),
        chemical: parseResult(chemical as number),
        cdv: parseResult(cdv as number),
        isEcolabel: isEcolabel ? 'Yes' : 'No'
      }];
      return costSustainabilityData;

    } catch (error) {

    }
  };
};

export const generateXlsxData = (formulationId: number, exportType: string): ThunkResult<any> => {
  return (dispatch, getState) => {
    dispatch(evaluateSustainability(formulationId));
    let generatedRemPerStainData: any = [];
    let costSustainabilityData: any = [];
    let formulationName = getState().UserInputState.UserFormulations[formulationId].data.name;

    // Generate prediction if it doesn't exist yet on UserFormulationsData
    if (Object.keys(getState().FormulationSpace.UserFormulationsData).indexOf(formulationId.toString()) === -1) {

      let generatedRemPerStainData: any;  // data that will be used for xlsx file generation
      const userFormula = getState().UserInputState.UserFormulations[formulationId].data;

      const { region, temperature, las, aes, aeo, soap, citrate, lipexevity200l, amplifyprime100l, progressuno100l, mannaway200l } = userFormula;

      let body = {
        region: region,
        Temperature: temperature,
        las: convertValue(las, region),
        aes: convertValue(aes, region),
        aeo: convertValue(aeo, region),
        soap: convertValue(soap, region),
        citrate: convertValue(citrate, region),
        target_ph: TARGET_PH, // fixed value
        lipexevity200l: convertValue(lipexevity200l, region),
        mannaway200l: convertValue(mannaway200l, region),
        amplifyprime100l: convertValue(amplifyprime100l, region),
        progressuno100l: convertValue(progressuno100l, region),
        Swatch_Types: getState().FormulationSpace.AllStainCodes.map(stainCode => stainCode.stain_code),
      };

      try {
        api_fetch('model_prediction', {
          method: 'post',
          headers: { 'Content-Type': 'application/json' },
          body: JSON.stringify(body),
        })
          .then((results: any) => {
            const predictedResults = roundOffPredictedResults(JSON.parse(results));
            dispatch(updateModelPredictionResults(formulationId, predictedResults));
            const { StainGroups } = getState().FormulationSpace;
            let newStainGroups: any = Object.assign({}, StainGroups);

            //  Generate new StainGroups with predicted results and deviation
            Object.keys(StainGroups).forEach((groupStains: any) => {
              newStainGroups = {
                ...newStainGroups,
                [groupStains]: [],
              };
              StainGroups[groupStains].forEach((stain: any, index: number) => {
                newStainGroups = {
                  ...newStainGroups,

                  [groupStains]: [
                    ...newStainGroups[groupStains],
                    {
                      ...stain,
                      Predicted: predictedResults.data[stain.index].Predicted,
                      Deviation: predictedResults.data[stain.index]['Standard Deviation'],
                    },
                  ],
                };
              });
            });
            // Add this new results to UserFormulationData
            dispatch(updateUserFormulationData(newStainGroups, formulationId));
          })
          .then(() => {
            const formulationData = getState().FormulationSpace.UserFormulationsData[formulationId];
            generatedRemPerStainData = generateRemPerStainData(formulationData, formulationName);
          })
          .then(() => {
            costSustainabilityData = dispatch(generateCostSustainabilityData(formulationId));
          })
          .then(() => {
            generateXlsxFile(generatedRemPerStainData, costSustainabilityData);
          })
          .catch((error: any) => {
            console.log('Fetch error', error);
          });
      } catch (error) {
        console.log('Something went wrong', error);
      }
    } else {
      const formulationData = getState().FormulationSpace.UserFormulationsData[formulationId];
      generatedRemPerStainData = generateRemPerStainData(formulationData, formulationName);
      costSustainabilityData = dispatch(generateCostSustainabilityData(formulationId));
      generateXlsxFile(generatedRemPerStainData, costSustainabilityData);
    }
  };
};

const updateModelPredictionResults = (formulationId: number, modelPredictionResults: any): UpdateModelPredictionResults => ({
  type: UPDATE_MODEL_PREDICTION_RESULTS,
  formulationId: formulationId,
  data: modelPredictionResults
});

const updateBenchmarkPredictionResults = (benchmarkId: number, benchmarkPredictionResults: any): UpdateBenchmarkPredictionResults => ({
  type: UPDATE_BENCHMARK_PREDICTION_RESULTS,
  benchmarkId: benchmarkId,
  data: benchmarkPredictionResults
});

const updateBenchmarkFormulationsData = (BenchmarkFormulationsData: any, id: number): UpdateBenchmarkFormulationsData => ({
  type: UPDATE_BENCHMARK_FORMULATIONS_DATA,
  id: id,
  BenchmarkFormulationsData: BenchmarkFormulationsData
});

export const predictBenchmarkFormulation = (id: number): ThunkResult<UpdateBenchmarkFormulationsData | UpdateBenchmarkPredictionResults> => {
  return (dispatch, getState) => {

    //@ts-ignore
    const benchmarkFormulation = industryBenchmarks[getState().UserInputState.region][id].defaultBenchmark;

    const currentBenchmarkFormulationsData = Object.keys(getState().FormulationSpace.BenchmarkFormulationsData);

    // do nothing if formulation id already exists
    if (currentBenchmarkFormulationsData.indexOf(id.toString()) !== -1) {
      // the formulaId was already fetched, do nothing
      return;
    }

    const { region, temperature } = getState().UserInputState;
    const { las, aes, aeo, soap, citrate, lipase, amylase, protease, mannanase } = benchmarkFormulation;

    let body = {
      region: region,
      Temperature: temperature,
      las: convertValue(las, region),
      aes: convertValue(aes, region),
      aeo: convertValue(aeo, region),
      soap: convertValue(soap, region),
      citrate: convertValue(citrate, region),
      target_ph: TARGET_PH, // fixed value
      lipexevity200l: convertValue(lipase, region),
      mannaway200l: convertValue(mannanase, region),
      amplifyprime100l: convertValue(amylase, region),
      progressuno100l: convertValue(protease, region),
      Swatch_Types: getState().FormulationSpace.AllStainCodes.map(stainCode => stainCode.stain_code),
    };

    try {
      api_fetch('model_prediction', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
        .then((results: any) => {
          const predictedResults = JSON.parse(results);
          dispatch(updateBenchmarkPredictionResults(id, predictedResults));  // save the raw results to ModelPredictionResults

          const { StainGroups } = getState().FormulationSpace;
          let newStainGroups: any = Object.assign({}, StainGroups);

          //  Generate new StainGroups with predicted results and deviation
          Object.keys(StainGroups).forEach((groupStains: any) => {
            newStainGroups = {
              ...newStainGroups,
              [groupStains]: [],
            };
            StainGroups[groupStains].forEach((stain: any, index: number) => {
              newStainGroups = {
                ...newStainGroups,

                [groupStains]: [
                  ...newStainGroups[groupStains],
                  {
                    ...stain,
                    Predicted: predictedResults.data[stain.index].Predicted,
                    Deviation: predictedResults.data[stain.index]['Standard Deviation'],
                  },
                ],
              };
            });
          });
          // Saves the results to FormulationSpace.UserFormulationsData state
          dispatch(updateBenchmarkFormulationsData(newStainGroups, id));
        })
        .catch((error: any) => {
          console.log('Fetch error', error);
        });
    } catch (error) {
      console.log('Something went wrong', error);
    }
  };
};


export const exportMultipleDataToXlsx = (selectedFormulations: ExportSelectedFormulationsType): ThunkResult<any> => {
  return (dispatch, getState) => {
    const PREDICTED = 'Predicted';
    const STANDARD_DEVIATION = 'Standard Deviation';
    // fetch model_prediction results from all selected formulations to be extracted
    try {
      selectedFormulations.selectedUserFormulas.forEach(formulationId => {
        dispatch(predictUserFormulation(Number(formulationId)));
      });

      selectedFormulations.selectedBenchmarkFormulas.forEach(benchmarkId => {
        dispatch(predictBenchmarkFormulation(Number(benchmarkId)));
      });
    } catch (error) {
      console.log('Something went wrong', error);
    }

    let combinedRemPerStainData: any = [];
    let combinedCostSustainabilityData: any = [];
    //@ts-ignore
    const BenchmarkFormulas = industryBenchmarks[getState().UserInputState.region];

    window.setTimeout(() => {
      selectedFormulations.selectedUserFormulas.forEach(formulationId => {
        let formulationName = getState().UserInputState.UserFormulations[formulationId].data.name;
        const formulationData = getState().FormulationSpace.UserFormulationsData[formulationId];
        let generatedRemPerStainData = generateRemPerStainData(formulationData, formulationName);
        combinedRemPerStainData = [...combinedRemPerStainData, ...generatedRemPerStainData];

        const EcoLabel = getState().FormulationSpace.SavedEcolabelData.SavedFormulation[Number(formulationId)];
        //@ts-ignore
        const isEcolabel = EcoLabel['criterion 2'] === 'approved' && EcoLabel['criterion 3'] === 'approved';

        const { las, aes, aeo, soap, citrate, lipexevity200l, amplifyprime100l, progressuno100l, mannaway200l } = getState().UserInputState.UserFormulations[formulationId].data;
        const ingredients = { las, aes, aeo, soap, citrate, lipase: lipexevity200l, amylase: amplifyprime100l, protease: progressuno100l, mannanase: mannaway200l };
        const { data } = getState().FormulationSpace.ModelPredictionResults[formulationId];
        //@ts-ignore
        const { co2, chemical, cdv } = getState().FormulationSpace.SavedImpactData.SavedFormulation[formulationId];
        const { ingredientCosts } = getState().UserInputState;
        combinedCostSustainabilityData = [
          ...combinedCostSustainabilityData,
          {
            formulationName: formulationName,
            predictedResponse: data.reduce((a: number, b: any) => Math.round(a) + Math.round(b[PREDICTED]), 0),
            sumDeviation: data.reduce((a: number, b: any) => Math.round(a) + Math.round(b[STANDARD_DEVIATION]), 0),
            totalFormulationCost: parseResult(computeCost(ingredients, ingredientCosts)),
            co2: parseResult(co2 as number),
            chemical: parseResult(chemical as number),
            cdv: parseResult(cdv as number),
            isEcolabel: isEcolabel ? 'Yes' : 'No'
          }
        ];
      });

      selectedFormulations.selectedBenchmarkFormulas.forEach(benchmarkId => {
        let formulationName = BenchmarkFormulas[benchmarkId].name;
        const formulationData = getState().FormulationSpace.BenchmarkFormulationsData[benchmarkId];
        let generatedRemPerStainData = generateRemPerStainData(formulationData, formulationName);
        combinedRemPerStainData = [...combinedRemPerStainData, ...generatedRemPerStainData];

        const { data } = getState().FormulationSpace.BenchmarkPredictionResults[benchmarkId];
        const { las, aes, aeo, soap, citrate, lipase, amylase, protease, mannanase } = BenchmarkFormulas[benchmarkId].defaultBenchmark;
        const ingredients = { las, aes, aeo, soap, citrate, lipase, amylase, protease, mannanase };
        const { ingredientCosts } = getState().UserInputState;
        //@ts-ignore
        const { co2, chemical, cdv } = getState().FormulationSpace.SavedImpactData.Benchmark[benchmarkId];
        const EcoLabel = getState().FormulationSpace.SavedEcolabelData.Benchmark[Number(benchmarkId)];
        //@ts-ignore
        const isEcolabel = EcoLabel['criterion 2'] === 'approved' && EcoLabel['criterion 3'] === 'approved';

        combinedCostSustainabilityData = [
          ...combinedCostSustainabilityData,
          {
            formulationName: formulationName,
            predictedResponse: data.reduce((a: number, b: any) => Math.round(a) + Math.round(b[PREDICTED]), 0),
            sumDeviation: data.reduce((a: number, b: any) => Math.round(a) + Math.round(b[STANDARD_DEVIATION]), 0),
            totalFormulationCost: parseResult(computeCost(ingredients, ingredientCosts)),
            co2: parseResult(co2 as number),
            chemical: parseResult(chemical as number),
            cdv: parseResult(cdv as number),
            isEcolabel: isEcolabel ? 'Yes' : 'No'
          }
        ];

      });
    }, 5000);

    selectedFormulations.selectedUserFormulas.forEach(formulationId => {
      let formulationData = getState().UserInputState.UserFormulations[formulationId].data;
      dispatch(fetchSustainabilityData(Number(formulationId), 'SavedFormulation', formulationData));
    });

    selectedFormulations.selectedBenchmarkFormulas.forEach(benchmarkId => {
      const { las, aes, aeo, soap, citrate, lipase, amylase, protease, mannanase } = BenchmarkFormulas[benchmarkId].defaultBenchmark;
      const benchmarkData = {
        las: las,
        aes: aes,
        aeo: aeo,
        soap: soap,
        citrate: citrate,
        lipexevity200l: lipase,
        amplifyprime100l: amylase,
        progressuno100l: protease,
        mannaway200l: mannanase,
      };
      dispatch(fetchSustainabilityData(Number(benchmarkId), 'Benchmark', benchmarkData));
    });

    window.setTimeout(() => {
      try {
        generateXlsxFile(combinedRemPerStainData, combinedCostSustainabilityData);
      } catch (error) { }
    }, 5000);
  };
};

const updateSavedEcolabelData = (id: number, categoryGroup: string, data: any): UpdateSavedEcolabelData => ({
  type: UPDATE_SAVED_ECOLABEL_DATA,
  id: id,
  categoryGroup: categoryGroup,
  data: data,
});

const updateSavedImpactData = (id: number, categoryGroup: string, data: any): UpdateSavedImpactData => ({
  type: UPDATE_SAVED_IMPACT_DATA,
  id: id,
  categoryGroup: categoryGroup,
  data: data,
});

export const fetchSustainabilityData = (id: number, categoryGroup: string, ingredients: any): ThunkResult<UpdateSavedEcolabelData | UpdateSavedImpactData> => {
  return (dispatch, getState) => {

    //@ts-ignore
    const recordExist = Object.keys(getState().FormulationSpace.SavedEcolabelData[categoryGroup]).indexOf(id.toString()) !== -1;
    if (recordExist) return;

    const dose = DOSE;  // hardcoded/fixed value
    const region = getState().UserInputState.region;

    // get the formula from UserInput.UserFormulations state
    const { las, aes, aeo, soap, citrate, lipexevity200l, amplifyprime100l, progressuno100l, mannaway200l } = ingredients;

    const ecolabelBody = { dose, las, aes, aeo, soap, citrate, lipexevity200l, amplifyprime100l, progressuno100l, mannaway200l };
    const impactBody = { region, ...ecolabelBody };

    // generate ecolabel results
    try {
      fetchEcolabelResults(ecolabelBody)
        // save to FormulationSpace.ComparedSustainabilityData.ImpactData
        .then((results) => dispatch(updateSavedEcolabelData(id, categoryGroup, results)))
        .catch(error => console.log('something went wrong', error));
    } catch (error) {
      console.log('Something went wrong', error);
    }

    // generate sustainability_impact results
    try {
      fetchImpactResults(impactBody)
        // save to FormulationSpace.ComparedSustainabilityData.ImpactData
        .then((results) => dispatch(updateSavedImpactData(id, categoryGroup, results)))
        .catch(error => console.log('something went wrong', error));
    } catch (error) {
      console.log('Something went wrong', error);
    }

  };
};
