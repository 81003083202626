import { connect } from 'react-redux';
import { RootState } from '../reducers/root';
import { Dispatch } from 'react';
import { toggleAboutWashlabDetails, AppActionTypes } from '../actions/AppState';
import { AboutWashlabDetails } from '../components/AboutWashlabDetails';

const mapStateToProps = (state: RootState) => ({
  showAboutWashlabDetails: state.AppState.showAboutWashlabDetails,
  region: state.UserInputState.region,
});

const dispatchProps = (dispatch: Dispatch<AppActionTypes>) => {
  return {
    //@ts-ignore
    toggleAboutWashlabDetails: (value: boolean) => dispatch(toggleAboutWashlabDetails(value)),
  };
};

export default connect(mapStateToProps, dispatchProps)(AboutWashlabDetails);
