import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { ErrorMessage } from '../../types/UserInput';

interface Props {
  theme: Theme;
  errorMessage: ErrorMessage;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      margin: '20px',
    },
    novoFontRegular: {
      fontFamily: 'Novozymes Regular, Arial',
      color: 'red',
      textAlign: 'center'
    }
  })
);

export const MessageBox: React.SFC<Props> = ({ theme, errorMessage }) => {
  const classes = useStyles(theme);
  const { message } = errorMessage;
  return (
    <Grid className={classes.root} container justify='center'>
      <Grid item xs={12}>
        <Typography className={classes.novoFontRegular} variant='subtitle2' color='error'>
          {message}
        </Typography>
      </Grid>
    </Grid>

  );
};
