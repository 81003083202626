import React, { useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import novozymesLogo from '../../images/NZ_Primary_Black_RGB.png';
import Button from '@material-ui/core/Button';
import Paper from '@material-ui/core/Paper';
import { FormulationSpaceState } from '../../reducers/FormulationSpace';
import { CompositionCost } from './CompositionCost';
import IngredientsPanel from '../../containers/IngredientsPanel';
import SaveFormulationForm from '../../containers/SaveFormulationForm';
import ConditionsSummary from '../../containers/ConditionsSummary';
import { CompareSelectionPage } from '../CompareFormulas/CompareSelectionPage';
import { ErrorMessage } from '../../types/UserInput';
import MessageBox from '../../containers/MessageBox';
import Link from '@material-ui/core/Link';

interface Props {
  theme: Theme;
  data: FormulationSpaceState;
  currency: string;
  appTab: number;
  errorMessage: ErrorMessage;
  locale: string;
  prevWindow: () => void;
  toggleBenchmarkIsSet: (benchmarkIsSet: boolean) => void;
  saveFormulation: (formulationName?: string) => void;
  toggleAboutWashlabDetails: (value: boolean) => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '360px',
    height: '100%',
    paddingBottom: '50px',
    position: 'fixed',
    top: '0px',
    overflowY: 'scroll',
  },
  mywashlablogo: {
    height: '19px',
    width: '62px'
  },
  submit: {
    borderRadius: '22.5px',
    fontFamily: 'Novozymes Regular, arial',
    textTransform: 'none',
    height: '25px',
    width: '149px',
    fontSize: '14px',
    padding: '0px',
    marginTop: '20px',
  },
  saveButton: {
    borderRadius: '22.5px',
    fontFamily: 'Novozymes Regular, arial',
    textTransform: 'none',
    height: '25px',
    fontSize: '14px',
    padding: '0px',
    marginTop: '10px',
  },
  paper: {
    width: '100%',
    padding: '20px',
    paddingLeft: '48px',
    paddingRight: '33px',
  },
  novofontRegular: {
    fontFamily: 'Novozymes Regular, Arial'
  },
  navAbout: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px',
    marginLeft: 'auto',
    color: '#2a2a2a',
  }
}));

export const LeftPanel: React.SFC<Props> = ({ theme, data, currency, appTab, errorMessage, locale, toggleAboutWashlabDetails, prevWindow, toggleBenchmarkIsSet }) => {
  const classes = useStyles(theme);

  const initialStatus = {
    displayMessage: false,
    message: ''
  };

  const errorMessageIsSet = errorMessage.isEnabled;

  const [toggleSave, setToggleSave] = useState(false);
  const [message, setMessage] = useState(initialStatus);

  const handleClick = (value: boolean) => {
    setToggleSave(value);
  };

  const handleConfirm = () => {
    setMessage({
      displayMessage: false,
      message: ''
    });
    setToggleSave(false);
  };

  const renderSaveButton = () => {
    if (appTab !== 1)
      return (
        <Grid container direction='row' justify='flex-start' spacing={0} style={{ paddingBottom: '50px' }}>
          <Grid item xs style={{ paddingLeft: '43px' }}>
            <Button className={classes.submit} size='small' variant='contained' color='primary' onClick={() => handleClick(true)}>
              Save formulation
          </Button>
          </Grid>
        </Grid>
      );
  };

  const renderMessage = () => {
    return (
      <Paper className={classes.paper}>
        <Grid container alignItems='center' justify='center'>
          <Grid item xs={12}>
            <Typography className={classes.novofontRegular} align='center'>
              {message.message}
            </Typography>
          </Grid>
          <Grid item xs='auto'>
            <Button className={classes.saveButton} size='small' variant='contained' color='primary' onClick={handleConfirm}>
              Okay
            </Button>
          </Grid>
        </Grid>
      </Paper>
    );
  };

  return (
    <div id='leftpanel' className={classes.root} style={{ boxSizing: 'border-box' }}>
      <Grid container alignContent='space-between' justify='center'>
        <Grid item sm={12}>
          <Paper square className={classes.paper}>
            <Grid container alignItems='flex-end' justify='space-between'>
              <Grid item sm>
                <img className={classes.mywashlablogo} src={novozymesLogo} alt='novozymes logo' />
                <Typography>WASHLAB</Typography>
              </Grid>
              <Grid item>
                <Link className={classes.navAbout} component='button' onClick={() => toggleAboutWashlabDetails(true)} underline='always'>
                  About
                </Link>
              </Grid>

            </Grid>
          </Paper>
          <ConditionsSummary theme={theme} />
          {appTab !== 1 && <CompositionCost theme={theme} data={data} currency={currency} locale={locale} />}
          {appTab !== 1 && <IngredientsPanel theme={theme} />}
          {appTab === 1 && <CompareSelectionPage theme={theme} />}

        </Grid>
        <Grid container justify='space-between'>
          <Grid container item xs={12} justify='center'>
            {message.displayMessage && renderMessage()}
            {errorMessageIsSet && <MessageBox theme={theme} />}
          </Grid>
          <Grid container item justify='flex-start'>
            {toggleSave && !message.displayMessage && <SaveFormulationForm theme={theme} onClick={handleClick} setMessage={setMessage} />}
            {!toggleSave && !message.displayMessage && renderSaveButton()}
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
