import React, { useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
// import CircularProgress from '@material-ui/core/CircularProgress';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

import StainGroupsGraph from '../../containers/StainGroupsGraph';
import SingleStainsPage from '../../containers/SingleStainsPage';

import FormulationCost from '../../containers/FormulationCost';
import SustainabilityImpact from '../../containers/SustainabilityImpactPage';
import SustainabilityLabel from '../../containers/SustainabilityLabel';

interface Props {
  theme: Theme;
  predictionTab: number;
  isFetching: boolean;
  StainGroupNames: any;
  region: string;
  StainGroups: any;
  ModelPredictionData: any;
  BenchmarkData: any;
  changeAppTab: (appTab: number) => void;
  changePerformanceTab: (value: number) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '700px',
    height: 'auto',
  },
  circular: {
    margin: 'auto',
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
  },
}));

export const PerformancePredictionPage: React.SFC<Props> = ({ theme, predictionTab, changePerformanceTab }) => {
  const classes = useStyles(theme);

  // const [tab, setTab] = useState(0);
  const [open, setOpen] = useState(false);

  const handleChange = (event: React.ChangeEvent<{ value: unknown; }>) => {
    changePerformanceTab(event.target.value as number);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const RenderGraph = () => {
    if (predictionTab === 0) {
      return <StainGroupsGraph theme={theme} />;
    } else if (predictionTab === 1) {
      return <SingleStainsPage theme={theme} />;
    }
  };

  return (
    <Grid container alignContent='center' justify='flex-start'>
      <Grid className={classes.formControl} container justify='flex-end'>
        <Grid item xs={3}>
          <FormControl className={classes.formControl}>
            <Select
              labelId='demo-controlled-open-select-label'
              id='demo-controlled-open-select'
              open={open}
              onClose={handleClose}
              onOpen={handleOpen}
              value={predictionTab}
              onChange={handleChange}
            >
              <MenuItem value={0}>Stain groups</MenuItem>
              <MenuItem value={1}>Single stains</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <Grid item xs={11}>
        <section id='remcharts'>
          {RenderGraph()}
        </section>
      </Grid>
      <Grid item xs={11}>
        <section id='formulationcost'>
          <FormulationCost theme={theme} />
        </section>
      </Grid>
      <Grid item xs={11}>
        <section id='sustainabilityimpact'>
          <SustainabilityImpact theme={theme} />
        </section>
      </Grid>
      <Grid item xs={11}>
        <section id='sustainabilitylabel'>
          <SustainabilityLabel theme={theme} />
        </section>
      </Grid>
    </Grid>
  );
};
