import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Bar } from 'react-chartjs-2';
import { CustomLegend } from './CustomLegend';
import { computeCost } from '../../utility/CustomFunctions';

interface Props {
  theme: Theme;
  locale: string;
  currency: string;
  benchmark: string;
  BenchmarkFormulasList: any;
  totalFormulationCost: number;
  ingredientCosts: any;
}

const useStyles = makeStyles(theme => ({
  chartContainer: {
    width: '200px',
    height: '300px',
    boxSizing: 'border-box',
    padding: '20px',
    fontFamily: 'Novozymes Bold, Arial'
  },
  graphContainers: {
    backgroundColor: theme.palette.background.paper,
    height: '300px',
    padding: '10px',
    margin: '0px 10px',
    width: '200px'
  },
  Legend: {
    backgroundColor: 'rgb(208, 226, 70)',
    width: '50px',
    height: '20px',
    margin: '0 5px'
  },
  benchmarkLegend: {
    backgroundColor: 'grey',
    //marginTop: '10px',
    width: '50px',
    height: '4px',
    margin: '0 5px 0 20px'
  },
  novoFontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px'
  },
  novoTitle: {
    fontFamily: 'Novozymes Bold, Arial',
    fontSize: '16px',
  }
}));

const primaryColor = '#2d0028';

const generateDataset = (totalFormulationCost: number, benchmark: string, BenchmarkFormulasList: any, currency: string, ingredientCosts: any) => {
  let formulationCostDataset: any = {
    labels: [`${currency}/ton`],
    datasets: [
      {
        label: 'Formulation',
        data: [totalFormulationCost],
        backgroundColor: 'rgba(197, 218, 0, 0.5 )',
        barPercentage: 1.0,
        categoryPercentage: 1.0,
        barThickness: 50,
      }
    ]
  };

  const benchmarkIsSet = Number(benchmark) > 1;
  let benchmarkName: string = 'No benchmark';

  if (benchmarkIsSet) {
    const { name, defaultBenchmark } = BenchmarkFormulasList[benchmark][1];
    benchmarkName = `${name} benchmark`;
    formulationCostDataset = {
      ...formulationCostDataset,
      datasets: [
        {
          label: benchmarkName,
          data: [computeCost(defaultBenchmark, ingredientCosts)],
          backgroundColor: 'rgba(0,0,0,0)',
          borderWidth: {
            top: 2,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderColor: "grey",
          barPercentage: 1.0,
          categoryPercentage: 1.0
        },
        ...formulationCostDataset.datasets
      ]
    };
  };
  return formulationCostDataset;
};

export const FormulationCost: React.SFC<Props> = ({ theme, locale, currency, benchmark, BenchmarkFormulasList, totalFormulationCost, ingredientCosts }) => {
  const classes = useStyles(theme);
  let newDataset: any;
  newDataset = generateDataset(totalFormulationCost, benchmark, BenchmarkFormulasList, currency, ingredientCosts);

  const benchmarkIsSet = Number(benchmark) > 1;
  let benchmarkName: string = 'No benchmark';

  if (benchmarkIsSet) {
    const { name } = BenchmarkFormulasList[benchmark][1];
    benchmarkName = `${name} benchmark`;
  }

  return (
    <div style={{ marginBottom: '80px', padding: '20px 0px', marginTop: '60px' }}>
      <Grid container alignItems='center' spacing={5}>
        <Grid item xs={12}>
          <Typography className={classes.novoTitle} variant='h5'>Formulation cost</Typography>
        </Grid>
        <Grid item xs={4}>
          <Paper className={classes.chartContainer} elevation={24} >
            <Bar
              data={newDataset}
              width={100}
              height={300}
              options={{
                animation: {
                  duration: 0,
                },
                maintainAspectRatio: false,
                legend: {
                  display: false,
                  position: 'bottom',
                  fullWidth: true,
                },
                scales: {
                  xAxes: [
                    {
                      id: "bar-x-axis2",
                      stacked: true,
                      ticks: {
                        beginAtZero: true,
                        fontFamily: 'Novozymes Bold, Arial',
                        fontColor: primaryColor
                      },
                      gridLines: {
                        display: false
                      }
                    }
                  ],
                  yAxes: [
                    {
                      ticks: {
                        beginAtZero: true,
                        fontFamily: 'Novozymes Bold, Arial',
                        fontColor: primaryColor,
                        callback: function (value, index, values) {
                          return value.toLocaleString(locale);
                        }
                      }
                    }
                  ]
                },
                tooltips: {
                  mode: 'index',
                  intersect: true,
                  titleFontSize: 12,
                  bodyFontSize: 12,
                  callbacks: {
                    label: function (tooltipItem, data) {
                      if (tooltipItem.datasetIndex !== undefined && data.datasets !== undefined && data.datasets[0] !== undefined) {
                        let label: string = '';
                        //@ts-ignore
                        if (data.datasets.length === 1) {
                          //@ts-ignore
                          const value = Number(data.datasets[0].data[tooltipItem.index]);
                          label = `Formulation: ${value.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                        } else {
                          if (tooltipItem.datasetIndex === 0) {
                            const { index } = tooltipItem;
                            //@ts-ignore
                            const value = Number(data.datasets[0].data[index]);
                            label = `Benchmark: ${value.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                          } else {
                            //@ts-ignore
                            const value = Number(data.datasets[1].data[tooltipItem.index]);
                            label = `Formulation: ${value.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                          }
                        }
                        return label !== undefined ? label : '';
                      }
                      return '';
                    },
                  },
                }
              }}
            />
          </Paper>
        </Grid>
        <Grid container item xs={7} justify='flex-start' spacing={2}>
          <CustomLegend theme={theme} benchmarkIsSet={benchmarkIsSet} benchmarkName={benchmarkName} datasets={newDataset.datasets} />
        </Grid>
      </Grid>
    </div >
  );
};
