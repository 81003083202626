import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import 'chartjs-plugin-annotation';
import { Bar } from 'react-chartjs-2';
import colors from '../../static/colorCharts.json';
import { JB_NAME, AMYLASE_NAME, LIPASE_NAME, JBS_NAME, MANNANASE_NAME } from '../../static/Constants';

const legendPosition: any = {
  eu: {
    [AMYLASE_NAME]: 'right',
    [LIPASE_NAME]: 'right',
    [MANNANASE_NAME]: 'right',
  },
  cn: {
    [JB_NAME]: 'right',
    [AMYLASE_NAME]: 'right',
    [JBS_NAME]: 'right',
  },
};

interface Props {
  theme: Theme;
  region: string;
  groupName: any;
  benchmark: string;
  BenchmarkData: any;
  BenchmarkFormulaList: any;
  ComparedFormulationsData: any;
  StainGroups: any;
  StainGroupNames: any;
}

const useStyles = makeStyles((theme) => ({
  root: {
    height: '600px',
  },
  novoTitle: {
    fontFamily: 'Novozymes Bold, Arial',
    fontSize: '16px',
  },
}));

const barPercentage = 1.0;
const categoryPercentage = 0.7;
const maxBarThickness = 100;
const minBarThickness = 40;
const benchmarkBarPercentage = 1.0;

const generateDataset = (groupName: string, ComparedFormulationsData: any, BenchmarkData: any, benchmark: string, StainGroups: any) => {
  const benchmarkIsSet = Number(benchmark) > 1;

  let newDataset: any = {};

  let dataset: any = [];
  Object.keys(ComparedFormulationsData).forEach((formulationId: string, index: number) => {
    const stainCodes = [...StainGroups[groupName].map((stain: any) => stain.stain_code)];
    if (benchmarkIsSet) {
      dataset = [...dataset, { ...generateBenchmarkData(BenchmarkData[groupName], formulationId) }];
    }
    dataset = [...dataset, { ...generateStainGroupData(ComparedFormulationsData[formulationId], formulationId, groupName, index, stainCodes) }];
  });

  newDataset = {
    labels: [...StainGroups[groupName].map((stain: any) => stain.display_name_eng)],
    datasets: [...dataset],
  };

  return newDataset;
};

const generateBenchmarkData = (benchmarkData: any, stack: string): any => {
  let generatedBenchmark = {
    label: 'Benchmark',
    data: benchmarkData.Predicted.map((predicted: number) => Math.round(predicted)),
    // just generate transparent background color
    backgroundColor: benchmarkData.Predicted.map((predicted: number) => 'rgba(0, 0, 0 , 0)'),
    barPercentage: benchmarkBarPercentage,
    categoryPercentage: categoryPercentage,
    //maxBarThickness: maxBarThickness,
    //minBarThickness: minBarThickness,
    borderWidth: {
      top: 2,
      right: 0,
      bottom: 0,
      left: 0,
    },
    borderColor: 'gray',
    stack: stack, // formulationId
  };
  return generatedBenchmark;
};

const generateStainGroupData = (ComparedFormulationsData: any, stack: string, groupName: string, colorNumber: number, stainCodes: string[]) => {
  if (ComparedFormulationsData[groupName] === undefined || ComparedFormulationsData[groupName].data === undefined) return;
  const roundedData = ComparedFormulationsData[groupName].data.map((value: number) => Math.round(value));
  //@ts-ignore
  const barChartColor = colors[groupName][`backgroundColor${colorNumber + 1}`];
  return {
    label: ComparedFormulationsData.name,
    data: roundedData,
    backgroundColor: ComparedFormulationsData[groupName].data.map((data: any) => barChartColor),
    barPercentage: barPercentage,
    categoryPercentage: categoryPercentage,
    maxBarThickness: maxBarThickness,
    minBarThickness: minBarThickness,
    stack: stack,
    stainCodes: stainCodes,
  };
};

const setGridSize = (stainGroupName: string): 3 | 6 | 8 | 9 | 12 => {
  switch (stainGroupName) {
    case 'JB':
      return 9;
    case 'Lipase':
    case 'Amylase':
      return 12;
    case 'JBS':
      return 12;
    default:
      return 12;
  }
};

const assignPosition = (groupName: string, region: string): 'right' | 'bottom' => {
  if (legendPosition[region][groupName]) {
    return legendPosition[region][groupName];
  } else {
    return 'bottom';
  }
};

export const CompareStainDetails: React.SFC<Props> = ({
  theme,
  region,
  benchmark,
  BenchmarkData,
  BenchmarkFormulaList,
  groupName,
  ComparedFormulationsData,
  StainGroups,
  StainGroupNames,
}) => {
  const generatedDataset = generateDataset(groupName, ComparedFormulationsData, BenchmarkData, benchmark, StainGroups);
  // const newdataSet = generateComparisonDataset(BenchmarkData, groupName);
  const classes = useStyles(theme);
  //@ts-ignore
  const labelColor = colors[groupName].labelColor;
  //@ts-ignore
  const chartAreaBackgroundColor = colors[groupName].chartAreaBackgroundColor;

  const benchmarkIsSet = Number(benchmark) > 1;

  const legendPosition: 'bottom' | 'right' = assignPosition(groupName, region);
  return (
    <Grid className={classes.root} container item xs={12} alignContent='flex-start'>
      <Grid item xs={12}>
        <Typography className={classes.novoTitle} variant='h5' style={{ color: labelColor }}>
          {StainGroupNames[groupName]}
        </Typography>
      </Grid>
      <Grid item xs={setGridSize(groupName)} style={{ height: '500px' }}>
        <Bar
          redraw={true}
          data={generatedDataset}
          width={50}
          height={350}
          options={{
            maintainAspectRatio: false,
            scales: {
              xAxes: [
                {
                  id: 'benchmark',
                  stacked: true,
                  ticks: {
                    beginAtZero: true,
                    maxRotation: 90,
                    minRotation: 90,
                    fontFamily: 'Novozymes Bold, Arial',
                    fontSize: 14,
                    fontColor: labelColor,
                  },
                  gridLines: {
                    display: false,
                  },
                },
              ],
              yAxes: [
                {
                  stacked: false,
                  ticks: {
                    display: true,
                    beginAtZero: true,
                    min: 0,
                    max: 100,
                    fontFamily: 'Novozymes Bold, Arial',
                    fontColor: labelColor,
                  },
                  gridLines: {
                    display: true,
                  },
                },
              ],
            },
            responsive: true,
            legend: {
              display: true,
              position: legendPosition,
              align: 'center',
              labels: {
                fontFamily: 'Novozymes Regular, Arial',
                fontSize: 14,
                generateLabels: function (chart) {
                  const { data } = chart;
                  if (data.labels?.length && data.datasets?.length) {
                    let filteredDataset: any = {};
                    if (benchmarkIsSet) {
                      const { name } = BenchmarkFormulaList[benchmark][1];
                      filteredDataset = data.datasets
                        .filter((dataset: any, index: number) => index !== 2 && index !== 4)
                        .map((dataset: any, index: number) => {
                          return {
                            label: index === 0 ? name + ' benchmark' : dataset.label,
                            backgroundColor: index === 0 ? 'grey' : dataset.backgroundColor[0],
                          };
                        });
                    } else {
                      filteredDataset = data.datasets.map((dataset: any) => {
                        return {
                          label: dataset.label,
                          backgroundColor: dataset.backgroundColor[0],
                        };
                      });
                    }
                    return filteredDataset.map((data: any, index: number) => {
                      return {
                        text: data.label,
                        fillStyle: data.backgroundColor,
                        index: index,
                      };
                    });
                  }
                  return [];
                },
              },
            },
            plugins: {
              chartArea: {
                backgroundColor: chartAreaBackgroundColor,
              },
              chartJsPluginErrorBars: {
                drawTime: 'beforeDatasetsDraw',
                width: '15%',
                color: 'grey',
              },
              annotation: {
                annotations: [
                  {
                    drawTime: 'beforeDatasetsDraw',
                    id: 'hline',
                    type: 'bar',
                    mode: 'horizontal',
                    scaleID: 'y-axis-0',
                    borderColor: 'red',
                    borderWidth: 5,
                  },
                ],
              },
            },
            animation: {
              duration: 0,
            },
            tooltips: {
              mode: 'index',
              intersect: false,
              titleFontSize: 16,
              bodyFontSize: 16,
              filter: function (tooltipItems, data) {
                if (benchmarkIsSet) {
                  return tooltipItems.datasetIndex !== 2 && tooltipItems.datasetIndex !== 4;
                } else {
                  return true;
                }
              },
              callbacks: {
                title: function (TooltipItem, data) {
                  const { index } = TooltipItem[0];
                  if (TooltipItem.length === 1) {
                    //@ts-ignore
                    return data.datasets[0].stainCodes[index] || '';
                  }
                  //@ts-ignore
                  return data.datasets[1].stainCodes[index] || '';
                },
              },
            },
          }}
        />
      </Grid>
    </Grid>
  );
};
