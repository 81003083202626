import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers/root';

//@ts-ignore
import getUserLocale from 'get-user-locale';

import { toggleBenchmarkIsSet, FormulationActionTypes } from '../actions/FormulationSpace';

export const CHANGE_APPTAB = 'APPSTATE:CHANGE_APPTAB';
export const CHANGE_PERFORMANCE_TAB = 'APPSTATE:CHANGE_PERFORMANCE_TAB';
export const CHANGE_COMPARE_FORMULAS_TAB = 'APPSTATE:CHANGE_COMPARE_FORMULAS_TAB';
export const INITIAL_DATA_LOADED = 'APPSTATE:INITIAL_DATA_LOADED';
export const NEXT_WINDOW = 'APPSTATE:NEXT_WINDOW';
export const PREV_WINDOW = 'APPSTATE:PREV_WINDOW';
export const TOGGLE_ABOUTWASHLAB = 'APPSTATE:TOGGLE_ABOUTWASHLAB';
export const SET_USER_LOCALE = 'APPSTATE:SET_USER_LOCALE';
export const TOGGLE_TERMS = 'APPSTATE:TOGGLE_TERMS';
export const TOGGLE_EXPORT_DATA_DIALOG = 'APPSTATE:TOGGLE_EXPORT_DATA_DIALOG';

interface ChangeAppTab {
  type: typeof CHANGE_APPTAB;
  appTab: number;
}

interface ChangePerformanceTab {
  type: typeof CHANGE_PERFORMANCE_TAB;
  value: number;
}

interface ChangeCompareFormulasTab {
  type: typeof CHANGE_COMPARE_FORMULAS_TAB;
  value: number;
}

interface InitialDataLoaded {
  type: typeof INITIAL_DATA_LOADED;
  initialDataLoaded: boolean;
}

interface NextWindow {
  type: typeof NEXT_WINDOW;
  window: number;
}

interface PrevWindow {
  type: typeof PREV_WINDOW;
  window: number;
}
interface ToggleAboutWashlab {
  type: typeof TOGGLE_ABOUTWASHLAB;
  value: boolean;
}

export interface SetUserLocale {
  type: typeof SET_USER_LOCALE;
  userLocale: string;
  groupSeparator: string;
  decimalSeparator: string;
}

interface ToggleTerms {
  type: typeof TOGGLE_TERMS;
  value: boolean;
}

interface ToggleExportDataDialog {
  type: typeof TOGGLE_EXPORT_DATA_DIALOG;
  value: boolean;
}

type ThunkResult<R extends Action> = ThunkAction<void, RootState, undefined, R>;

export const changeAppTab = (tab: number): ChangeAppTab => ({
  type: CHANGE_APPTAB,
  appTab: tab,
});

export const changePerformanceTab = (value: number): ChangePerformanceTab => ({
  type: CHANGE_PERFORMANCE_TAB,
  value: value,
});

export const changeCompareFormulasTab = (value: number): ChangeCompareFormulasTab => ({
  type: CHANGE_COMPARE_FORMULAS_TAB,
  value: value,
});

export const initialDataLoaded = (value: boolean): InitialDataLoaded => ({
  type: INITIAL_DATA_LOADED,
  initialDataLoaded: value,
});

export const nextWindow = (): NextWindow => ({
  type: NEXT_WINDOW,
  window: 2,
});

export const prevWindow = (): ThunkResult<FormulationActionTypes | PrevWindow> => {
  return (dispatch) => {
    dispatch(toggleBenchmarkIsSet(false));
    dispatch({
      type: PREV_WINDOW,
      window: 1,
    });
  };
};

export const toggleAboutWashlabDetails = (value: boolean): ToggleAboutWashlab => ({
  type: TOGGLE_ABOUTWASHLAB,
  value: value,
});

export const setUserLocale = (): SetUserLocale => {
  const userLocale = getUserLocale();
  const sampleNumWithDecimal = 1001.1;
  const groupSeparator = sampleNumWithDecimal.toLocaleString(userLocale).substring(1, 2);
  const decimalSeparator = sampleNumWithDecimal.toLocaleString(userLocale).substring(5, 6);
  return {
    type: SET_USER_LOCALE,
    userLocale: userLocale,
    groupSeparator: groupSeparator,
    decimalSeparator: decimalSeparator,
  };
};

export const toggleTerms = (value: boolean): ToggleTerms => ({
  type: TOGGLE_TERMS,
  value: value,
});

export const toggleExportDataDialog = (value: boolean): ToggleExportDataDialog => ({
  type: TOGGLE_EXPORT_DATA_DIALOG,
  value: value,
});

export type AppActionTypes =
  | ChangeAppTab
  | ChangePerformanceTab
  | ChangeCompareFormulasTab
  | InitialDataLoaded
  | NextWindow
  | PrevWindow
  | ToggleAboutWashlab
  | SetUserLocale
  | ToggleTerms
  | ToggleExportDataDialog;
