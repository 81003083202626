import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import AuthState from './AuthState';
import AppState from './AppState';
import UserInputState from './UserInput';
import FormulationSpace from './FormulationSpace';

export const createRootReducer = (history: any) =>
  combineReducers({
    router: connectRouter(history),
    AuthState,
    AppState,
    UserInputState,
    FormulationSpace
  });

export type RootState = ReturnType<ReturnType<typeof createRootReducer>>;
