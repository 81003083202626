import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Bar } from 'react-chartjs-2';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';

interface Props {
  theme: Theme;
  benchmark: string;
  BenchmarkFormulasList: any;
  ImpactData: any;
  SustainabilityBenchmarkData: any;
  locale: string;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    paddingTop: '20px',
    fontFamily: 'Novozymes Bold, Arial',
  },
  graphContainers: {
    height: '300px',
    width: '170px',
    padding: '10px 5px',
  },
  legendContainer: {
    paddingTop: '50px',
  },
  formulaLegend: {
    backgroundColor: 'rgba(197, 218, 0, 0.5 )',
    width: '50px',
    height: '20px',
    margin: '0 5px'
  },
  benchmarkLegend: {
    backgroundColor: 'grey',
    marginTop: '10px',
    width: '50px',
    height: '4px',
    margin: '0 5px 0 20px'
  },
  novoFontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px'
  },
  novoTitle: {
    fontFamily: 'Novozymes Bold, Arial',
    fontSize: '16px',
    paddingBottom: '30px',
  }
}));

const primaryColor = '#2d0028';

export const SustainabilityImpact: React.SFC<Props> = ({ theme, benchmark, BenchmarkFormulasList, ImpactData, SustainabilityBenchmarkData, locale }) => {
  const classes = useStyles(theme);

  const benchmarkIsSet = Number(benchmark) > 1;

  let benchmarkName: string = 'No benchmark';
  if (benchmarkIsSet) {
    const { name } = BenchmarkFormulasList[benchmark][1];
    benchmarkName = `${name} benchmark`;
  }

  const labelNames: any = {
    co2: 'CO2  in million tons',
    chemical: 'Chemicals  in million tons',
    cdv: 'CDV in  billion m3 water'
  };

  let impactDataset: any = {};

  Object.keys(ImpactData).forEach((key: any) => {

    let dataset: any = [
      {
        label: 'Impact',
        backgroundColor: 'rgba(197, 218, 0, 0.5 )',
        data: [Number.parseFloat(ImpactData[key]).toFixed(2)],
        barThickness: 50
      },
    ];

    if (benchmarkIsSet) {
      dataset = [
        ...dataset,
        {
          label: 'Benchmark',
          data: [Number.parseFloat(SustainabilityBenchmarkData[key]).toFixed(2)],
          backgroundColor: 'rgba(0,0,0,0)',
          borderWidth: {
            top: 2,
            right: 0,
            bottom: 0,
            left: 0
          },
          borderColor: "grey",
          barPercentage: 1.0,
          categoryPercentage: 1.0
        }
      ];
    }

    impactDataset = {
      ...impactDataset,
      [key]: {
        labels: [labelNames[key]],
        datasets: dataset
      }
    };
  });

  return (
    <div id='sustainabilityimpact'>
      <Grid container item xs={12} className={classes.root} >
        <Grid item>
          <Typography className={classes.novoTitle} variant='h5'>Sustainability, Yearly impact</Typography>
        </Grid>
        <Grid container item justify='space-between' xs={12} >
          {Object.keys(impactDataset).map((keyName: string) => {
            return (
              <Paper key={keyName} elevation={24} >
                <Grid className={classes.graphContainers} item style={{ width: '200px', height: '300px', padding: '15px' }}>
                  <Bar
                    data={impactDataset[keyName]}
                    width={100}
                    height={300}
                    options={{
                      animation: {
                        duration: 0,
                      },
                      maintainAspectRatio: false,
                      legend: {
                        display: false
                      },
                      scales: {
                        xAxes: [
                          {
                            id: "bar-x-axis2",
                            stacked: true,
                            ticks: {
                              beginAtZero: true,
                              fontFamily: 'Novozymes Bold, Arial',
                              fontColor: primaryColor,
                              callback: function (label) {
                                if (/\s/.test(label)) {
                                  // eslint-disable-next-line
                                  return label.split(/  /);
                                } else {
                                  return label;
                                }
                              }
                            },
                            gridLines: {
                              display: false
                            }
                          }
                        ],
                        yAxes: [
                          {
                            stacked: false,
                            ticks: {
                              beginAtZero: true,
                              fontFamily: 'Novozymes Bold, Arial',
                              fontSize: 12,
                              fontColor: primaryColor,
                              callback: function (value, index, values) {
                                return value.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                              }
                            }
                          }
                        ]
                      },
                      tooltips: {
                        mode: 'index',
                        intersect: true,
                        titleFontSize: 12,
                        bodyFontSize: 12,
                        callbacks: {
                          label: function (tooltipItem, data) {
                            if (tooltipItem.datasetIndex !== undefined && data.datasets !== undefined && data.datasets[0] !== undefined) {
                              let label: string = '';
                              //@ts-ignore
                              if (data.datasets.length === 1) {
                                //@ts-ignore
                                const value = Number(data.datasets[0].data[tooltipItem.index]);
                                label = `Formulation: ${value.toLocaleString(locale)}`;
                              } else {
                                if (tooltipItem.datasetIndex === 0) {
                                  const { index } = tooltipItem;
                                  //@ts-ignore
                                  const value = Number(data.datasets[0].data[index]);
                                  label = `Formulation: ${value.toLocaleString(locale)}`;
                                } else {
                                  //@ts-ignore
                                  const value = Number(data.datasets[1].data[tooltipItem.index]);
                                  label = `Benchmark: ${value.toLocaleString(locale)}`;
                                }
                              }
                              return label !== undefined ? label : '';
                            }
                            return '';
                          },
                        },
                      }
                    }}
                  />
                </Grid>
              </Paper>
            );
          })}
        </Grid>

        <Grid item container xs={12} justify='center' style={{ padding: '50px 10px' }}>
          {benchmarkIsSet && <React.Fragment><Box className={classes.benchmarkLegend}></Box><Typography className={classes.novoFontRegular}>{benchmarkName}</Typography></React.Fragment>}
          <Box className={classes.formulaLegend}></Box><Typography className={classes.novoFontRegular}>Formulation</Typography>
        </Grid>
      </Grid >
    </div>
  );
};
