import {
  UserInputActionType,
  SET_TEMPERATURE,
  SET_REGION,
  SET_GROUP_NAMES,
  SET_BENCHMARK,
  SET_BENCHMARK_TYPE,
  SET_INGREDIENT_COST,
  SET_INGREDIENT_COST_META,
  UPDATE_BENCHMARK_VALUE,
  TOGGLE_IS_FETCHING,
  SET_CURRENCY,
  SAVE_FORMULATION,
  UPDATE_USER_FORMULATIONS,
  LOAD_USER_INGREDIENTCOST,
  SET_SURFACTANT_SUM_EXCEEDED,
  SET_BENCHMARK_FORMULAS_LIST,
  SET_ERROR_MESSAGE,
} from '../actions/UserInput';

import { ErrorMessage, Ingredients, Costs } from '../types/UserInput';
import regions from '../static/regions.json';
import { stainGroupNames, defaultCosts, initialBenchmark } from '../static/Constants';

const initialState = {
  isFetching: false,
  temperature: 30,
  region: 'eu',
  stainGroupNames: stainGroupNames['eu'],
  benchmark: '0',
  benchmarkType: 'default',
  benchmarkData: initialBenchmark,
  BenchmarkFormulasList: {},
  currency: regions['eu'].currency,
  weightUnits: 'kg',
  ingredientCosts: regions['eu'].surfactantPrices as Costs,
  ingredientsCurrency: regions['eu'].currency,
  UserFormulations: [],
  surfactantSumExceeded: false,
  errorMessage: { message: '', isEnabled: false }
};

export interface UserInputsState {
  isFetching: boolean;
  temperature: number;
  region: string;
  stainGroupNames: any;
  benchmark: string;
  benchmarkType: string;
  benchmarkData: Ingredients;
  BenchmarkFormulasList: any;
  ingredientCosts: Costs;
  ingredientsCurrency: string;
  currency: string;
  weightUnits: string;
  UserFormulations: any;
  surfactantSumExceeded: boolean;
  errorMessage: ErrorMessage;
}

export default (state: UserInputsState = initialState, action: UserInputActionType): UserInputsState => {
  switch (action.type) {
    case SET_REGION:
      let costs = defaultCosts;
      if (action.value in regions) {
        //@ts-ignore
        costs = regions[action.value].surfactantPrices;
      }
      return Object.assign({}, state, {
        region: action.value,
        ingredientCosts: costs,
      });
    case SET_GROUP_NAMES:
      return Object.assign({}, state, {
        stainGroupNames: action.value,
      });
    case SET_TEMPERATURE:
      return Object.assign({}, state, {
        temperature: action.value,
      });
    case SET_BENCHMARK:
      return Object.assign({}, state, {
        benchmark: action.value,
        benchmarkData: action.benchmarkParameters,
      });
    case SET_BENCHMARK_TYPE:
      return Object.assign({}, state, {
        benchmarkType: action.value
      });
    case SET_INGREDIENT_COST:
      return Object.assign({}, state, {
        ingredientCosts: { ...state.ingredientCosts, ...action.data },
      });
    case SET_INGREDIENT_COST_META:
      return Object.assign({}, state, {
        ingredientsCurrency: action.currency,
        ingredientsWeightUnit: action.weightUnit,
      });
    case UPDATE_BENCHMARK_VALUE:
      return Object.assign({}, state, {
        benchmarkData: { ...state.benchmarkData, ...action.data },
      });
    case TOGGLE_IS_FETCHING:
      return Object.assign({}, state, {
        isFetching: action.isFetching,
      });
    case SET_CURRENCY:
      return Object.assign({}, state, {
        currency: action.currency,
      });
    case SAVE_FORMULATION:
      return Object.assign({}, state, {
        UserFormulations: action.UserFormulation,
      });
    case UPDATE_USER_FORMULATIONS:
      return Object.assign({}, state, {
        UserFormulations: action.UserFormulations,
      });
    case LOAD_USER_INGREDIENTCOST:
      return Object.assign({}, state, {
        ingredientCosts: action.ingredientCost,
      });
    case SET_SURFACTANT_SUM_EXCEEDED:
      return Object.assign({}, state, {
        surfactantSumExceeded: action.value,
      });
    case SET_BENCHMARK_FORMULAS_LIST:
      return Object.assign({}, state, {
        BenchmarkFormulasList: action.value
      });
    case SET_ERROR_MESSAGE:
      return Object.assign({}, state, {
        errorMessage: {
          message: action.message,
          isEnabled: action.isEnabled
        }
      });
    default:
      return state;
  }
};
