import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import { AboutMyWashLab } from '../AboutMyWashLab';
import { AuthTabs } from './AuthTabs';
import { AuthProps } from '../../types/App';
import authtablogo from '../../images/NZLogo.svg';
import TermsAndConditions from '../../containers/TermsAndConditions';

interface Props {
  theme: Theme;
  authProps: AuthProps;
  toggleTerms: (value: boolean) => void;
}

const useStyles = makeStyles(theme => ({
  authpage: {
    height: '100%',
    width: '100%',
    margin: 0,
    padding: 0,
    border: 0
  },
  authtab: {
    height: '100vh'
  },
  authtablogo: {
    backgroundImage: `url(${authtablogo})`,
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat',
    height: '100%'
  }
}));

export const AuthPage: React.SFC<Props> = ({ theme, authProps, toggleTerms }) => {
  const classes = useStyles(theme);
  return (
    <Grid className={classes.authpage} container spacing={0} alignContent='center' justify='center'>
      <TermsAndConditions theme={theme} />
      <Grid item md={6} xs={12} className={classes.authpage}>
        <AboutMyWashLab theme={theme} />
      </Grid>
      <Grid item md={6} className={`${classes.authpage} ${classes.authtab}`}>
        <Grid className={`${classes.authtablogo}`} container alignContent='center' justify='center'>
          <Grid item>
            <AuthTabs theme={theme} authProps={authProps} toggleTerms={toggleTerms} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
