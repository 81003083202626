import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Grid } from '@material-ui/core';
import InputMainContainer from '../containers/InputMainContainer';
import { FormulationSpace } from '../components/FormulationSpace/FormulationSpace';
import { AuthProps } from '../types/App';
import AboutWashlabDetails from '../containers/AboutWashlabDetails';
import ExportDataDialog from '../containers/ExportDataDialog';

interface Props {
  theme: Theme;
  authProps: AuthProps;
  window: number;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: '100vh',
  },
}));

export const Homepage: React.SFC<Props> = ({ theme, authProps, window }) => {
  const classes = useStyles(theme);

  return (
    <div id='maincontainer' className={classes.root}>
      <Grid className={classes.root} container alignContent='center' justify='center'>
        <Grid item sm={12}>
          <Grid container justify='center' alignContent='center'>
            <AboutWashlabDetails theme={theme} />
            <ExportDataDialog />
            <Grid className={classes.root}>{window === 1 ? <InputMainContainer theme={theme} /> : <FormulationSpace theme={theme} />}</Grid>
          </Grid>
        </Grid>
      </Grid>
    </div>
  );
};
