import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Box from '@material-ui/core/Box';

import CompareFormulasPage from '../../containers/CompareFormulasPage';
import PerformancePredictionPage from '../../containers/PerformancePredictionPage';
import { SustainabilityMainPage } from '../SustainabilityPage/SustainabilityMainPage';

interface Props {
  theme: Theme;
  appTab: number;
  changeAppTab: (appTab: number) => void;
}

const useStyles = makeStyles((theme) => ({
  circular: {
    margin: 'auto',
  },
  formControl: {
    margin: theme.spacing(0),
    minWidth: 120,
    boxSizing: 'border-box',
  },
  fontStyling: {
    textTransform: 'none',
    fontSize: '18px',
    lineSize: '20px',
    margin: '0px 20px',
  },
  tabsComponent: {
    backgroundColor: '#FAFAFA',
    width: '750px',
    padding: '0px',
    margin: '0px',
  },
}));

export const MainPredictionPage: React.SFC<Props> = ({ theme, appTab, changeAppTab }) => {
  const classes = useStyles(theme);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    changeAppTab(newValue);
  };

  interface TabPanelProps {
    children?: React.ReactNode;
    index: any;
    value: any;
  }

  function TabPanel(props: TabPanelProps) {
    const { children, value, index, ...other } = props;

    return (
      <Typography
        component='div'
        role='tabpanel'
        hidden={value !== index}
        id={`scrollable-auto-tabpanel-${index}`}
        aria-labelledby={`scrollable-auto-tab-${index}`}
        {...other}
      >
        {value === index && <Box p={3}>{children}</Box>}
      </Typography>
    );
  }

  function a11yProps(index: any) {
    return {
      id: `scrollable-auto-tab-${index}`,
      'aria-controls': `scrollable-auto-tabpanel-${index}`,
    };
  }

  return (
    <Grid className={classes.formControl} container>
      <Grid item xs={12}>
        <Paper square className={classes.tabsComponent} elevation={0}>
          <Tabs value={appTab} onChange={handleChange} indicatorColor='primary' textColor='secondary' aria-label='Formulation page'>
            <Tab className={classes.fontStyling} label='Performance' {...a11yProps(0)} />
            <Tab className={classes.fontStyling} label='Performance comparison' {...a11yProps(1)} />
          </Tabs>
        </Paper>
        <TabPanel value={appTab} index={0}>
          <PerformancePredictionPage theme={theme} />
        </TabPanel>
        <TabPanel value={appTab} index={1}>
          <CompareFormulasPage theme={theme} />
        </TabPanel>
        <TabPanel value={appTab} index={2}>
          <SustainabilityMainPage theme={theme} />
        </TabPanel>
      </Grid>
    </Grid>
  );
};
