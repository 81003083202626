import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import { Theme, makeStyles } from '@material-ui/core/styles';
import regions from '../../static/regions.json';
import { RegionsType } from '../../types/UserInput';

interface Props {
  theme: Theme;
  region: string;
  temperature: number;
  benchmarkName: string;
  prevWindow: () => void;
}

const useStyles = makeStyles(theme => ({
  submit: {
    width: '55px',
    height: '25px',
    borderRadius: '22.5px',
    backgroundColor: '#c5da00',
    fontFamily: 'Novozymes Regular, Arial',
    textTransform: 'none',
    padding: '0px'
  },
  paper: {
    width: '100%',
    paddingLeft: '48px',
    paddingRight: '33px',
    padding: '20px',
    boxSizing: 'border-box'
  },
  novofontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px',
    margin: '5px 0px 5px 0px',
  },
  navAbout: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px',
    marginLeft: 'auto',
    marginRight: '20px',
    color: '#2a2a2a',
  }
}));

export const ConditionsSummary: React.SFC<Props> = ({ theme, region, temperature, benchmarkName, prevWindow }) => {
  const classes = useStyles(theme);

  const { regionName } = (regions as RegionsType)[region];

  return (
    <Paper square className={classes.paper}>
      <Grid container alignContent='center' justify='center'>
        <Grid item sm={12}>
          <Typography>Conditions Summary</Typography>
        </Grid>
        <Grid item sm={12}>
          <Grid container alignContent='flex-end' justify='space-between'>
            <Typography className={classes.novofontRegular} variant='subtitle1'>
              Region:
            </Typography>
            <Typography className={classes.novofontRegular}>
              {regionName}
            </Typography>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid container alignContent='flex-end' justify='space-between'>
            <Typography className={classes.novofontRegular} variant='subtitle1'>
              Temperature:
            </Typography>
            <Typography className={classes.novofontRegular}>
              {temperature}
              <span className={classes.novofontRegular} style={{ marginLeft: '5px' }}>°C</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid container alignContent='flex-end' justify='space-between'>
            <Typography className={classes.novofontRegular} variant='subtitle1'>
              Benchmark:
            </Typography>
            <Typography className={classes.novofontRegular}>
              {benchmarkName}
            </Typography>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid container alignContent='flex-end' justify='space-between'>
            <Typography className={classes.novofontRegular} variant='subtitle1'>
              Price:
            </Typography>
            <Typography className={classes.novofontRegular}>
              Multi
            </Typography>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid container alignContent='flex-end' justify='flex-start'>
            <Button className={classes.submit} onClick={prevWindow} >
              Edit
          </Button>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
