import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Paper from '@material-ui/core/Paper';
import AppBar from '@material-ui/core/AppBar';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';

import IngredientsInput from '../../containers/IngredientsInput';

interface Props {
  theme: Theme;
  region: string;
  surfactantSumExceeded: boolean;
}

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: any;
  value: any;
}

const TabPanel = (props: TabPanelProps) => {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
};

const a11yProps = (index: any) => {
  return {
    id: `full-width-tab-${index}`,
    'aria-controls': `full-width-tabpanel-${index}`
  };
};

const useStyles = makeStyles(theme => ({
  paper: {
    width: '100%'
  },
  novofontRegular: {
    fontFamily: 'Novozymes Regular, Arial'
  },
  message: {
    padding: '25px 0'
  },
  tabStyle: {
    textTransform: 'none',
    minWidth: 85,
    paddingLeft: 0,
    paddingRight: 0,
    letterSpacing: "-.04em",
    color: '#b5b5b5'
  },
}));

export const IngredientsPanel: React.SFC<Props> = ({ theme, region, surfactantSumExceeded }) => {
  const classes = useStyles(theme);
  const [value, setValue] = React.useState(0);

  const surfactants = ['las', 'aes', 'aeo'];
  const enzymes = region === 'eu' ? ['protease', 'amylase', 'lipase', 'mannanase'] : ['protease', 'amylase'];
  const others = ['citrate', 'soap'];

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  const renderSurfactantSumExceededMessage = () => {
    return (
      <Grid className={classes.message} container justify='center'>
        <Grid item xs={10}>
          <Typography className={classes.novofontRegular} variant='body2' color='error'>The sum of LAS, AES and AEO cannot be more than 38%.  Please reduce % weight of these surfactants</Typography>
        </Grid>
      </Grid>
    );
  };

  return (
    <Paper square className={classes.paper} variant='outlined' elevation={0}>
      <AppBar position='relative' color='default' style={{ marginBottom: '1rem' }} elevation={0}>
        <Tabs
          centered
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='secondary'
          aria-label='ingredient tabs'
          variant='standard'
          scrollButtons='desktop'
        >
          <Tab label='Surfactants' {...a11yProps(0)} className={classes.tabStyle} />
          <Tab label='Enzymes' {...a11yProps(1)} className={classes.tabStyle} />
          <Tab label='Others' {...a11yProps(2)} className={classes.tabStyle} />
        </Tabs>
      </AppBar>
      <TabPanel value={value} index={0} dir={theme.direction}>
        {surfactants.map(surfactant => (
          <IngredientsInput key={surfactant} theme={theme} ingredient={surfactant} />
        ))}
      </TabPanel>

      <TabPanel value={value} index={1} dir={theme.direction}>
        {enzymes.map(enzyme => (
          <IngredientsInput key={enzyme} theme={theme} ingredient={enzyme} />
        ))}
      </TabPanel>
      <TabPanel value={value} index={2} dir={theme.direction}>
        {others.map(builder => (
          <IngredientsInput key={builder} theme={theme} ingredient={builder} />
        ))}
      </TabPanel>
      {surfactantSumExceeded && renderSurfactantSumExceededMessage()}
    </Paper>
  );
};
