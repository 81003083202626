import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { RootState } from '../reducers/root';
import { setBenchmark, setUserBenchmark, generateBenchmarkFormulasList, UserInputActionType } from '../actions/UserInput';
import { SelectBenchmark } from '../components/InputSelection/SelectBenchmark';

const mapStateToProps = (state: RootState) => ({
  userLocale: state.AppState.userLocale,
  region: state.UserInputState.region,
  benchmark: state.UserInputState.benchmark,
  benchmarkData: state.UserInputState.benchmarkData,
  UserFormulations: state.UserInputState.UserFormulations,
  BenchmarkFormulasList: state.UserInputState.BenchmarkFormulasList,
});

const dispatchProps = (dispatch: Dispatch<UserInputActionType>) => {
  return {
    //@ts-ignore
    setBenchmark: (value: string) => dispatch(setBenchmark(value)),
    //@ts-ignore
    setUserBenchmark: (value: string) => dispatch(setUserBenchmark(value)),
    //@ts-ignore
    generateBenchmarkFormulasList: () => dispatch(generateBenchmarkFormulasList()),
  };
};

export default connect(mapStateToProps, dispatchProps)(SelectBenchmark);
