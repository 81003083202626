import React from 'react';
import { Theme, withStyles, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import Paper from '@material-ui/core/Paper';
import ecolabel from '../../images/ecolabel.png';
import CheckCircleTwoToneIcon from '@material-ui/icons/CheckCircleTwoTone';
import { EcoLabelResultSet } from '../../types/FormulationSpace';

interface Props {
  theme: Theme;
  EcoLabel?: EcoLabelResultSet;
}

const labelDescription = 'This section indicates whether your simulated detergent formulation lives up to the label criteria that are considered in WashLab.';

const criterion3tooltip = 'All surfactants displayed in the tool are anaerobically biodegradable with the exception of LAS. Therefore when LAS is included in formulation, Criterion 3 is not met.';

const noteLabelLink = 'https://eur-lex.europa.eu/legal-content/EN/TXT/?uri=uriserv:OJ.L_.2017.180.01.0063.01.ENG&toc=OJ:L:2017:180:TOC#ntr6-L_2017180EN.01006601-E0010';

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '100%',
    height: '100%',
    backgroundColor: theme.palette.background.paper,
    boxSizing: 'border-box',
    padding: '20px'
  },
  ecolabelImage: {
    width: '60px'
  },
  checkIcon: {
    fontSize: 'small',
    color: 'primary'
  },
  novoFontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px'
  },
  novoTitle: {
    fontFamily: 'Novozymes Bold, Arial',
    fontSize: '16px',
  }
}));

export const SustainabilityLabel: React.SFC<Props> = ({ theme, EcoLabel }) => {
  const classes = useStyles(theme);

  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      fontFamily: 'Novozymes Regular, Arial',
      backgroundColor: '#c5da00',
      color: '#2d0028',
      maxWidth: 420,
      fontSize: theme.typography.pxToRem(1),
      border: '1px solid #dadde9',
      borderRadius: '15px',
      padding: '10px'
    },
  }))(Tooltip);

  const checkEcolabel = (index: number): boolean => {
    const ecoValues = Object.values(EcoLabel as EcoLabelResultSet);
    if (ecoValues[index] === 'approved') {
      return true;
    } else {
      return false;
    }
  };

  return (
    <div id='sustainabilitylabel' style={{ paddingBottom: '50vh' }}>
      <Grid container justify='flex-start' alignItems='baseline' spacing={2} >
        <Grid item>
          <Typography className={classes.novoTitle} variant='h5' gutterBottom={true} >Label</Typography>
        </Grid>
        <Grid item>
          <HtmlTooltip
            arrow
            placement='right'
            title={
              <React.Fragment>
                <Typography style={{ fontFamily: 'Novozymes Regular, Arial', padding: '15px', fontSize: '14px' }}>{labelDescription}</Typography>
              </React.Fragment>
            }
          >
            <InfoOutlinedIcon />
          </HtmlTooltip>
        </Grid>
      </Grid>

      <Grid item xl={12}>
        <Paper className={classes.root} elevation={24}>
          <Grid container justify='center'>
            <Grid item xs={2} >
              <img src={ecolabel} alt='ecolabel logo' style={{ width: '80px', margin: 'auto' }} />
            </Grid>
            <Grid item container xs={10} style={{ height: '400px' }} direction='column' >
              <Grid container item xs direction='row' alignItems='flex-start'>
                <CheckCircleTwoToneIcon fontSize='small' color='primary' style={{ margin: '10px' }} />
                <Grid item >
                  <Typography className={classes.novoTitle} variant='h6'>Criterion 1 — Dosage requirements</Typography>
                  <Typography variant='subtitle2'>WashLab uses a fixed dosage of 60 g/wash and 4 kg of ballast.</Typography>
                  <Typography className={classes.novoTitle} variant='subtitle1' color='primary'>All WashLab detergents live up to this criterion.</Typography>
                </Grid>
              </Grid>
              <Grid container item xs direction='row' alignItems='flex-start'>
                <CheckCircleTwoToneIcon fontSize='small' color={checkEcolabel(0) ? 'primary' : 'disabled'} style={{ margin: '10px' }} />
                <Grid item >
                  <Typography className={classes.novoTitle} variant='h6'>Criterion 2 — Toxicity to aquatic organisms</Typography>
                  {checkEcolabel(0) && <Typography className={classes.novoTitle} variant='subtitle1' color='primary' >Your current simulated detergent lives up to this criterion</Typography>}
                </Grid>
              </Grid>
              <Grid container item xs direction='row' alignItems='flex-start'>
                <CheckCircleTwoToneIcon fontSize='small' color={checkEcolabel(1) ? 'primary' : 'disabled'} style={{ margin: '10px' }} />
                <Grid item xs>
                  <Grid container justify='flex-start'>
                    <Typography className={classes.novoTitle} variant='h6'>Criterion 3 — Biodegradability</Typography>
                    <HtmlTooltip
                      arrow
                      placement='right'
                      title={
                        <React.Fragment>
                          <Typography style={{ fontFamily: 'Novozymes Regular, Arial', padding: '15px', fontSize: '14px' }}>{criterion3tooltip}</Typography>
                        </React.Fragment>
                      }
                    >
                      <InfoOutlinedIcon />
                    </HtmlTooltip>
                  </Grid>
                  {checkEcolabel(1) && <Typography className={classes.novoTitle} variant='subtitle1' color='primary'>Your current simulated detergent lives up to this criterion</Typography>}
                </Grid>
              </Grid>
              <Grid container item xs direction='row' alignItems='flex-start'>
                <Typography className={classes.novoFontRegular} variant='body2' >Note: The other criteria that you need to fulfill to qualify for the EU Eco label are not considered in WashLab. You can find them <a href={noteLabelLink} target='_blank' rel='noopener noreferrer'>here.</a></Typography>
              </Grid>
            </Grid>
          </Grid>
        </Paper>
      </Grid>
    </div >
  );
};
