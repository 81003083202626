import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { RootState } from '../reducers/root';
import { setTemperature, setRegion, fetchStainsInfo, UserInputActionType } from '../actions/UserInput';
import { SelectWashCondition } from '../components/InputSelection/SelectWashCondition';

const mapStateToProps = (state: RootState) => ({
  temperature: state.UserInputState.temperature,
  region: state.UserInputState.region,
});

const dispatchProps = (dispatch: Dispatch<UserInputActionType>) => {
  return {
    setTemperature: (value: number) => dispatch(setTemperature(value)),
    //@ts-ignore
    setRegion: (value: string) => dispatch(setRegion(value)),
    //@ts-ignore
    fetchStainsInfo: () => dispatch(fetchStainsInfo())
  };
};

export default connect(mapStateToProps, dispatchProps)(SelectWashCondition);
