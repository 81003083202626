import React, { useState } from 'react';
import { Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import TextField from '@material-ui/core/TextField';
import Slider from '@material-ui/core/Slider';
import { ingredientList } from '../../static/Constants';
import NumberFormat from 'react-number-format';
import { UserLocale } from '../../types/AppState';
import { Typography } from '@material-ui/core';
import ingredientsBoundaries from '../../static/ingredientsBoundaries.json';

interface Props {
  theme: Theme;
  ingredient: string;
  ingredientCosts: any;
  benchmarkData: any;
  weightUnits: string;
  currency: string;
  region: string;
  userLocale: UserLocale;
  setIngredientCost: (ingredient: string, value: number) => void;
  setIngredientCostMeta: (weightUnits: string, currency: string) => void;
  updateBenchmarkValue: (ingredient: string, value: number) => void;
}

const useStyles = makeStyles(theme => ({
  mainContainer: {
    paddingLeft: '48px',
    paddingRight: '33px',
  },
  inputForm: {
    width: '100%',
  },
  number: {
    "& input::-webkit-outer-spin-button, & input::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0
    }
  },
  input: {
    "&::-webkit-outer-spin-button, &::-webkit-inner-spin-button": {
      "-webkit-appearance": "none",
      margin: 0,
      borderRadius: '25px'
    }
  },
  novoFontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
  },
  ingredientLabel: {
    marginTop: '10px',
    paddingRight: '10px ',
    fontSize: '14px',
  },
  fontSize14: {
    fontSize: '14px'
  }
}));

const StyledInput = withStyles({
  root: {
    '& div.MuiInputBase-root': {
      borderRadius: '23px',
      backgroundColor: '#f6f6f5',
      padding: '10px',
      height: '32px',
      fontSize: '14px',
      width: '74px'
    },
    '& div.MuiInput-underline:before': {
      borderBottom: 'none'
    }
  }
})(TextField);

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string; }; }) => void;
  name: string;
  groupSeparator: string;
  decimalSeparator: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, groupSeparator, decimalSeparator, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={groupSeparator}
      decimalSeparator={decimalSeparator}
      isNumericString
    />
  );
}

export const IngredientsInput: React.SFC<Props> = ({ theme, ingredient, ingredientCosts, benchmarkData, weightUnits, currency, region, userLocale, updateBenchmarkValue }) => {
  const classes = useStyles(theme);

  // @ts-ignore
  const maxValue = ingredientsBoundaries[region][ingredient].max;
  // @ts-ignore
  const minValue = ingredientsBoundaries[region][ingredient].min;

  const isDisabled = (ingredient === 'citrate' || ingredient === 'soap');
  const ingredientLabel = ingredientList[ingredient];

  const ingredientValue = benchmarkData[ingredient];
  let initialValue = ingredientValue;
  const [sliderValue, setSliderValue] = useState(initialValue);
  const [currentValue, setCurrentValue] = useState(initialValue);

  const handleChangeIngredient = (ingredient: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    let newValue = Number(event.target.value);
    if (newValue > maxValue) {
      newValue = maxValue;
    }
    setCurrentValue(newValue);
    updateBenchmarkValue(ingredient, newValue);
    setSliderValue(newValue);

  };

  const handleChange = (event: any, newValue: number | number[]) => {
    if (isDisabled) return;  // just do nothing if ingredients is = soap or citrate
    setCurrentValue(newValue);
  };

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    if (isDisabled) return; // just do nothing if ingredients is = soap or citrate
    setCurrentValue(event.target.value);
    setSliderValue(Number(newValue) as number);
  };

  const valuetext = (event: any, newValue: number | number[]) => {
    return `${newValue}°C`;
  };

  const surfactants = ['las', 'aes', 'aeo'];

  let stepCount: number = 0.01;

  if (surfactants.indexOf(ingredient) !== -1) {
    stepCount = 0.1;
  }

  const { groupSeparator, decimalSeparator, locale } = userLocale;

  return (
    <Grid className={classes.mainContainer} container alignItems='flex-end' justify='center'>
      <Grid item xs={12}>
        <FormControl className={classes.inputForm}>
          <Grid container item alignItems='center' justify='space-between' sm={12}>
            <Grid container item xs='auto' alignItems='baseline' >
              <label className={classes.ingredientLabel} htmlFor={ingredient}>{ingredientLabel.toUpperCase()}: </label>
              <StyledInput
                disabled={isDisabled}
                id={ingredient}
                name={ingredient}
                value={currentValue}
                onChange={handleChangeIngredient(ingredient)}
                InputProps={{
                  inputComponent: NumberFormatCustom as any,
                  endAdornment: <InputAdornment position="end" ><span style={{ fontSize: '20px', fontFamily: 'Novozymes Bold, Arial' }}>%</span></InputAdornment>,
                }}
                inputProps={{
                  groupSeparator,
                  decimalSeparator
                }}
              />

              <Typography align='right' className={classes.novoFontRegular} style={{ marginLeft: 'auto', fontSize: '14px' }}>{`${ingredientCosts[ingredient].toLocaleString(locale)} `}<span style={{ fontSize: '12px', color: '#b5b5b5' }}>{` ${currency}/${weightUnits}`}</span></Typography>
            </Grid>

          </Grid>
          <Grid container item xs={12} alignItems='baseline' justify='space-between'>
            <Typography className={classes.fontSize14}>0%</Typography>
            <Typography className={classes.fontSize14}>{maxValue}%</Typography>
          </Grid>
        </FormControl>
        <Slider
          value={sliderValue}
          valueLabelDisplay='auto'
          step={stepCount}
          min={isDisabled ? undefined : minValue}
          max={maxValue}
          getAriaValueText={valuetext}
          onChange={handleSliderChange}
          onChangeCommitted={handleChange}
        />
      </Grid>
    </Grid>
  );
};
