import { connect } from 'react-redux';
import { RootState } from '../reducers/root';
import { Dispatch } from 'react';
import { toggleUserFormulation } from '../actions/UserInput';
import { SavedFormulasSelection } from '../components/CompareFormulas/SavedFormulasSelection';
import { filterUserFormulations } from '../utility/CustomFunctions';

const mapStateToProps = (state: RootState) => ({
  UserFormulations: filterUserFormulations(state.UserInputState.UserFormulations, state.UserInputState.region, state.UserInputState.temperature),
});

const dispatchProps = (dispatch: Dispatch<any>) => {
  return {
    toggleUserFormulation: (formulaId: number) => dispatch(toggleUserFormulation(formulaId))
  };
};

export default connect(mapStateToProps, dispatchProps)(SavedFormulasSelection);
