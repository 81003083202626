import { connect } from 'react-redux';
import { RootState } from '../reducers/root';
import { Dispatch } from 'react';
import { toggleTerms, AppActionTypes } from '../actions/AppState';
import { TermsAndConditions } from '../components/TermsAndConditions';

const mapStateToProps = (state: RootState) => ({
  showTerms: state.AppState.showTerms,
});

const dispatchProps = (dispatch: Dispatch<AppActionTypes>) => {
  return {
    toggleTerms: (value: boolean) => dispatch(toggleTerms(value)),
  };
};

export default connect(mapStateToProps, dispatchProps)(TermsAndConditions);
