import React from 'react';
import { Theme, makeStyles, withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import FormControl from '@material-ui/core/FormControl';
import InputAdornment from '@material-ui/core/InputAdornment';
import Button from '@material-ui/core/Button';
import { ingredientsInputLayouts } from '../../static/Constants';
import Tooltip from '@material-ui/core/Tooltip';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import { toolTipDescription } from '../../static/Constants';
import NumberFormat from 'react-number-format';
import TextField from '@material-ui/core/TextField';
import { UserLocale } from '../../types/AppState';

interface Props {
  theme: Theme;
  ingredientCosts: any;
  ingredientsCurrency: string;
  weightUnits: string;
  currency: string;
  region: string;
  userLocale: UserLocale;
  setIngredientCost: (ingredient: string, value: number) => void;
  setIngredientCostMeta: (weightUnits: string, currency: string) => void;
  setDefaultCost: () => void;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '637px',
    padding: '30px'
  },
  formControl: {
    margin: theme.spacing(1),
    minWidth: 120,
    maxWidth: 300
  },
  inputTitle: {
    fontSize: '32px'
  },
  inputfont: {
    fontSize: '16px',
    lineHeight: '19px',
    paddingBottom: '10px',
    paddingTop: '10px'
  },
  novoitalicfont: {
    fontFamily: 'Novozymes Italic, arial'
  },
  novoboldfont: {
    fontFamily: 'Novozymes Bold, arial'
  },
  custombgcolor: {
    backgroundColor: '#F0EEEE'
  },
  novoButtons: {
    width: 'auto',
    height: '45px',
    borderRadius: '22.5px',
    fontFamily: 'Novozymes Bold, Arial',
    fontSize: '16px',
    lineHeight: '20px',
    textTransform: 'none',
    color: 'primary',
    padding: '10px'
  }
}));

interface NumberFormatCustomProps {
  inputRef: (instance: NumberFormat | null) => void;
  onChange: (event: { target: { name: string; value: string; }; }) => void;
  name: string;
  groupSeparator: string;
  decimalSeparator: string;
}

function NumberFormatCustom(props: NumberFormatCustomProps) {
  const { inputRef, onChange, groupSeparator, decimalSeparator, ...other } = props;
  return (
    <NumberFormat
      {...other}
      getInputRef={inputRef}
      onValueChange={(values) => {
        onChange({
          target: {
            name: props.name,
            value: values.value,
          },
        });
      }}
      thousandSeparator={groupSeparator}
      decimalSeparator={decimalSeparator}
    />
  );
}

export const InputIngredientCost: React.SFC<Props> = ({
  theme,
  ingredientCosts,
  ingredientsCurrency,
  weightUnits,
  region,
  userLocale,
  setIngredientCost,
  setDefaultCost
}) => {
  const classes = useStyles(theme);

  const HtmlTooltip = withStyles((theme: Theme) => ({
    tooltip: {
      fontFamily: 'Novozymes Regular, Arial',
      backgroundColor: '#c5da00',
      color: '#2d0028',
      maxWidth: 420,
      fontSize: theme.typography.pxToRem(12),
      border: '1px solid #dadde9',
      borderRadius: '15px',
      padding: '4px'
    },
  }))(Tooltip);

  const setCost = (ingredient: string) => (event: React.ChangeEvent<HTMLInputElement>) => {
    // if (!isNaN(event.target.value as number))
    setIngredientCost(ingredient, Number(event.target.value));
  };

  const handleSetDefaultCost = () => {
    setDefaultCost();
  };

  const stainGroups = Object.values(ingredientsInputLayouts[region]);

  const { groupSeparator, decimalSeparator } = userLocale;

  return (
    <Grid container className={classes.root} alignContent='flex-start' justify='center'>
      <Grid item sm={12}>
        <Typography className={classes.inputTitle} component='h2' align='center'>
          Input your ingredient cost
        </Typography>
      </Grid>
      <Grid id='inputgrid1' container alignContent='flex-start' spacing={5} style={{ height: '400px', marginTop: '50px' }}>
        {stainGroups.map((layout: any) => (
          <Grid item sm={4} key={layout.name}>
            <Typography component='h3' align='center' >
              {layout.name}
            </Typography>
            {layout.ingredients.map((ingredient: any, index: number) => {
              const ingredientName = Object.keys(ingredient)[0];
              const ingredientLabel = ingredient[ingredientName];
              return (
                <Grid container item key={index} style={{ paddingTop: '30px' }}>
                  <FormControl fullWidth>
                    <Grid item>
                      <label className={`${classes.inputfont} ${classes.novoboldfont}`} htmlFor={ingredientName}>
                        {ingredientLabel.toUpperCase()}
                      </label>
                      <HtmlTooltip
                        arrow
                        placement='right'
                        title={
                          <React.Fragment>
                            <Typography style={{ fontFamily: 'Novozymes Regular, Arial', padding: '15px' }}>{toolTipDescription[ingredientName]}</Typography>
                          </React.Fragment>
                        }
                      >
                        <InfoOutlinedIcon fontSize="small" />
                      </HtmlTooltip>
                    </Grid>
                    <TextField
                      variant='filled'
                      id={ingredientName}
                      name={ingredientName}
                      value={ingredientCosts[ingredientName]}
                      onChange={setCost(ingredientName)}
                      InputProps={{
                        inputComponent: NumberFormatCustom as any,
                        endAdornment: <InputAdornment position='end' style={{ marginRight: '10px' }}>{`${ingredientsCurrency}/${weightUnits}`}</InputAdornment>
                      }}
                      inputProps={{
                        groupSeparator,
                        decimalSeparator
                      }}
                    />
                  </FormControl>
                </Grid>
              );
            }
            )}
          </Grid>
        ))}
      </Grid>
      <Grid item container justify='flex-end'>
        <Button className={classes.novoButtons} onClick={handleSetDefaultCost} ><span style={{ textDecoration: 'underline' }}>Reset prices</span></Button>
      </Grid>
    </Grid>
  );
};
