import React from 'react';
import { Theme, makeStyles } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import LeftPanel from '../../containers/LeftPanel';
import { MainPanel } from './MainPanel';
import PerformanceNavbar from '../../containers/PerformanceNavbar';
import RightPanel from '../../containers/RightPanel';

interface Props {
  theme: Theme;
}
const useStyles = makeStyles((theme) => ({
  mainPanel: {
    paddingLeft: '64px',
    paddingRight: '0px',
  },
}));

export const FormulationSpace: React.SFC<Props> = ({ theme }) => {
  const classes = useStyles(theme);
  return (
    <Grid container justify='center' alignContent='center'>
      <Grid container item xs={12}>
        <Grid item xs={3}>
          <LeftPanel theme={theme} />
        </Grid>
        <Grid className={classes.mainPanel} item xs={9}>
          <PerformanceNavbar theme={theme} />
          <Grid container justify='space-between'>
            <Grid item xs={9}>
              <MainPanel theme={theme} />
            </Grid>
            <Grid item xs={3}>
              <RightPanel theme={theme} />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};
