import { connect } from 'react-redux';
import { RootState } from '../reducers/root';
import { Dispatch } from 'react';
import { UserInputActionType, setNewBenchmarkFromList } from '../actions/UserInput';
import { BenchmarkFormulas } from '../components/CompareFormulas/BenchmarkFormulas';

const mapStateToProps = (state: RootState) => ({
  benchmark: state.UserInputState.benchmark,
  BenchmarkFormulasList: state.UserInputState.BenchmarkFormulasList,
});

const dispatchProps = (dispatch: Dispatch<UserInputActionType>) => {
  return {
    //@ts-ignore
    setNewBenchmarkFromList: (value: string) => dispatch(setNewBenchmarkFromList(value))
  };
};

export default connect(mapStateToProps, dispatchProps)(BenchmarkFormulas);
