import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Typography from '@material-ui/core/Typography';

interface Props {
  selectAllUserFormulations: boolean;
  filteredUserFormulations: any;
  userFormulationState: any;
  handleUserFormulationChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  handleSelectAllUserFormulations: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const useStyles = makeStyles(() => ({
  root: {
    width: '688px',
    height: 'auto',
  },
  paperContainer: {
    border: '1px solid #b1b1b1',
    width: '100%',
    padding: '10px',
  },
  exportButton: {
    width: '182px',
    height: '44px',
    textTransform: 'none',
    borderRadius: '22.5px',
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '18px',
  },
  novoFontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px',
  },
}));

export const UserFormulationsList: React.SFC<Props> = ({ selectAllUserFormulations, filteredUserFormulations, userFormulationState, handleUserFormulationChange, handleSelectAllUserFormulations }) => {
  const classes = useStyles();
  const isEmpty = Object.entries(filteredUserFormulations).length === 0;

  return (
    <React.Fragment>
      <Grid container item xs>
        <Paper className={classes.paperContainer} square elevation={0} style={{ height: '65px' }}>
          <FormControlLabel
            control={<Checkbox checked={selectAllUserFormulations} disabled={isEmpty} name='selectAllUserFormulations' color='primary' onChange={handleSelectAllUserFormulations} />}
            label='Saved formulas'
          />
        </Paper>
        <Paper className={classes.paperContainer} square elevation={0} style={{ height: '100%' }}>
          <FormGroup>
            {isEmpty && <Typography className={classes.novoFontRegular}>No saved formulations found</Typography>}
            {filteredUserFormulations.map((userFormula: any) => {
              const { formulationId, name } = userFormula;
              return (
                <FormControlLabel
                  key={formulationId}
                  classes={{
                    label: classes.novoFontRegular,
                  }}
                  control={<Checkbox checked={userFormulationState[formulationId] || false} onChange={handleUserFormulationChange} name={formulationId.toString()} color='primary' />}
                  label={name}
                />
              );
            })}
          </FormGroup>
        </Paper>
      </Grid>
    </React.Fragment>
  );
};
