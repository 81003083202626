import React from 'react';
import { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';

import SustainabilityLabel from '../../containers/SustainabilityLabel';
import SustainabilityImpact from '../../containers/SustainabilityImpactPage';

interface Props {
  theme: Theme;
}

export const SustainabilityMainPage: React.SFC<Props> = ({ theme }) => {
  return (
    <Grid container justify='center'>
      <Grid item xs={12}>
        <Grid container spacing={4}>
          <Grid item xs={12}>
            <SustainabilityLabel theme={theme} />
          </Grid>
          <Grid item xs={12}>
            <SustainabilityImpact theme={theme} />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};