import React, { useState, useEffect } from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import { InputFooter } from './InputFooter';

import PerformanceNavbar from '../../containers/PerformanceNavbar';

import SelectWashCondition from '../../containers/SelectWashCondition';
import SelectBenchmark from '../../containers/SelectBenchmark';
import InputIngredientCost from '../../containers/InputIngredientCost';

interface Props {
  theme: Theme;
  benchmark: string;
  optimizeRecipe: () => void;
  nextWindow: () => void;
  fetchStainsInfo: () => void;
  fetchUserSavedData: () => void;
  saveUserIngredientCost: () => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    inputMainContainer: {
      height: '100%',
      width: '700px',
      margin: 'auto'
    },
    inputSelectForm: {
      minHeight: '500px'
    },
    mainGrid: {
      height: '100%',
      paddingTop: '64px'
    },
    topNavbar: {

    }
  })
);

export const InputMainContainer: React.SFC<Props> = ({ theme, benchmark, optimizeRecipe, nextWindow, fetchStainsInfo, fetchUserSavedData, saveUserIngredientCost }) => {
  const classes = useStyles(theme);
  useEffect(() => {
    fetchStainsInfo();
    fetchUserSavedData();
    // eslint-disable-next-line
  }, []);

  const [step, setStep] = useState(0);

  const nextStep = () => {
    setStep(step + 1);
  };

  const prevStep = () => {
    setStep(step - 1);
  };

  return (
    <Grid container >
      <PerformanceNavbar theme={theme} />
      <Grid className={classes.mainGrid} container justify='center' alignContent='center'>
        <Grid item sm={12}>
          <Paper className={classes.inputMainContainer}>
            <Grid className={classes.inputSelectForm} container alignContent='center' justify='center'>
              <Grid item sm={12}>
                {step === 0 && <SelectWashCondition theme={theme} />}
                {step === 1 && <SelectBenchmark theme={theme} />}
                {step === 2 && <InputIngredientCost theme={theme} />}
              </Grid>
              <Grid item sm={12} style={{ backgroundColor: 'white' }}>
                <InputFooter
                  theme={theme}
                  benchmark={benchmark}
                  step={step}
                  nextStep={nextStep}
                  prevStep={prevStep}
                  optimizeRecipe={optimizeRecipe}
                  nextWindow={nextWindow}
                  saveUserIngredientCost={saveUserIngredientCost}
                />
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Grid>
  );
};
