import React, { useEffect } from 'react';
import { Route, Switch } from 'react-router';
import { createMuiTheme } from '@material-ui/core/styles';
import { ThemeProvider } from '@material-ui/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import { AuthProps } from '../types/App';
import { AuthState } from '../reducers/AuthState';
import { Authenticator } from '@novozymes/auth';
import { Auth } from '@aws-amplify/auth';
import Amplify from '@aws-amplify/auth';
import { AuthPage } from './Auth/AuthPage';
import Homepage from '../containers/Homepage';
import { ChangePassword } from './Auth/ChangePassword';
import { AmplifyConfig, AmplifyFlowType, AmplifyResponseType } from '@novozymes/auth';

const amplifyConfig = {
  region: process.env.REACT_APP_AWS_COGNITO_REGION,
  userPoolId: process.env.REACT_APP_AWS_COGNITO_USER_POOL_ID,
  userPoolWebClientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
  mandatorySignIn: false,
  authenticationFlowType: AmplifyFlowType.USER_PASSWORD_AUTH,
  oauth: {
    domain: process.env.REACT_APP_AWS_COGNITO_OAUTH_DOMAIN,
    redirectSignIn: process.env.REACT_APP_DOMAIN,
    redirectSignOut: `${process.env.REACT_APP_DOMAIN}/logout`,
    responseType: AmplifyResponseType.TOKEN,
    clientId: process.env.REACT_APP_AWS_COGNITO_CLIENT_ID,
    identityProvider: process.env.REACT_APP_COGNITO_IDENTITY_PROVIDER,
  },
} as AmplifyConfig;

console.log(amplifyConfig);
interface Props {
  authState: AuthState;
  updateIsAuthenticated: (isAuthenticated: boolean) => void;
  updateUserInfo: (userInfo: any) => void;
  setUserLocale: () => void;
  toggleTerms: (value: boolean) => void;
}

const theme = createMuiTheme({
  palette: {
    primary: {
      main: '#c5da00'
    },
    secondary: {
      light: '#ffffff',
      main: '#2a2a2a',
    }
  },
  typography: {
    fontFamily: 'Novozymes Bold, Arial'
  },
  overrides: {
    MuiFilledInput: {
      input: {
        padding: '12px 10px'
      }
    }
  }
});

const App: React.SFC<Props> = ({ authState, updateIsAuthenticated, updateUserInfo, setUserLocale, toggleTerms }) => {
  console.log(amplifyConfig);
  Amplify.configure(amplifyConfig);
 
  const authProps: AuthProps = {
    isAuthenticated: authState.isAuthenticated,
    user: authState.user,
    updateIsAuthenticated,
    updateUserInfo
  };

  //  Check if user has signed in
  useEffect(() => {
    async function checkSession() {
      try {
        await Auth.currentSession();
        const user = await Auth.currentAuthenticatedUser();
        updateUserInfo(user);
        updateIsAuthenticated(true);
        setUserLocale();
      } catch (error) {
        if (error !== 'No current user') {
          console.log('error', error);
        }
      }
    }
    checkSession();
    // eslint-disable-next-line
  }, []);

  return (
    <Authenticator amplifyConfig={amplifyConfig}>

    <ThemeProvider theme={theme}>
      <CssBaseline />
      {/* <p>WE DONE IT</p> */}
      {authState.isAuthenticated === false ? (
        <AuthPage theme={theme} authProps={authProps} toggleTerms={toggleTerms} />
        ) : (
          <Switch>
          <Route exact path='/changepassword' render={props => <ChangePassword {...props} theme={theme} authProps={authProps} />} />
            <Route exact path='/' render={props => <Homepage {...props} theme={theme} authProps={authProps} />} />
            </Switch>
        )}
    </ThemeProvider>
    </Authenticator>
  );
};

export default App;
