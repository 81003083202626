import Grid from '@material-ui/core/Grid';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import 'chartjs-plugin-annotation';
import React from 'react';
import { Bar } from 'react-chartjs-2';
import colors from '../../static/colorCharts.json';
import { UserLocale } from '../../types/AppState';
import Paper from '@material-ui/core/Paper';

interface Props {
  theme: Theme;
  benchmark: string;
  BenchmarkData: any;
  ComparedFormulationsData: any;
  ComparedGroupFormulationsData: any;
  StainGroups: any;
  StainGroupNames: any;
  userLocale: UserLocale;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    padding: '20px 20px',
  },
  paperContainer: {
    width: '100%',
    padding: '20px',
  },
  novoTitle: {
    fontFamily: 'Novozymes Bold, Arial',
    fontSize: '16px',
  }
}));

const generateGroupDataset = (labels: string[], ComparedFormulationsData: any, ComparedGroupFormulationsData: any): any => {
  const benchmarkIsSet = Object.keys(ComparedGroupFormulationsData).indexOf('Benchmark') !== -1;

  const barPercentage = 1.0;
  const categoryPercentage = 0.9;

  const generateBenchmark = (stackName: string): any => {
    const { Benchmark } = ComparedGroupFormulationsData;
    const benchmarkData = Object.values(Benchmark);
    return {
      label: 'Benchmark',
      data: benchmarkData,
      backgroundColor: benchmarkData.map(() => 'rgba(0,0,0,0)'),
      barPercentage: 1.0,
      categoryPercentage: categoryPercentage,
      borderWidth: {
        top: 2,
        right: 0,
        bottom: 0,
        left: 0,
      },
      borderColor: 'gray',
      stack: stackName
    };
  };

  const generateStainDataset = (data: any, index: number): any => {
    const userFormulaName = data[0];
    //@ts-ignore
    const backgroundColors = Object.keys(data[1]).map(groupName => colors[groupName][`backgroundColor${index + 1}`]);
    return {
      label: userFormulaName,
      data: Object.values(data[1]),
      backgroundColor: backgroundColors,
      barPercentage: barPercentage,
      categoryPercentage: categoryPercentage,
      stack: data[0]
    };
  };

  let newGroupDataset: any = {
    labels: labels
  };

  let dataset: any = [];

  Object.entries(ComparedGroupFormulationsData)
    .filter((data) => data[0] !== 'Benchmark')  // Just generate the selected formulations, benchmark will be added below if it is set
    .forEach((data, index) => {
      const stackName = data[0];
      if (benchmarkIsSet) {
        dataset = [...dataset, generateBenchmark(stackName)];
      }
      dataset = [...dataset, generateStainDataset(data, index)];
    });

  newGroupDataset = {
    ...newGroupDataset,
    datasets: [...dataset]
  };
  return newGroupDataset;

};

export const CompareGroupStains: React.SFC<Props> = ({ theme, ComparedFormulationsData, ComparedGroupFormulationsData, StainGroups, StainGroupNames, userLocale }) => {
  const classes = useStyles(theme);

  const isEmpty = Object.entries(ComparedFormulationsData).length === 0;
  let benchmarkIsSet = false;

  let comparedGroupDataset: any = {};

  if (!isEmpty) {
    const labels: string[] = Object.values(StainGroupNames);
    comparedGroupDataset = generateGroupDataset(labels, ComparedFormulationsData, ComparedGroupFormulationsData);
    benchmarkIsSet = (Object.keys(ComparedGroupFormulationsData).indexOf('Benchmark') !== -1);
  }

  const { locale } = userLocale;
  return (
    <Paper className={classes.paperContainer} elevation={24}>
      <Grid container item xs={12} alignContent='flex-start' >
        <Grid item xs={12}>
          <Typography className={classes.novoTitle} variant='h5'>
            Stain Group comparison
        </Typography>
        </Grid>
        <Grid item xs={12}>
          <Bar
            redraw={true}
            data={comparedGroupDataset}
            width={50}
            height={400}
            options={{
              maintainAspectRatio: false,
              scales: {
                xAxes: [
                  {
                    id: 'benchmark',
                    stacked: true,
                    ticks: {
                      beginAtZero: true,
                      fontFamily: 'Novozymes Bold, Arial',
                      fontSize: 14,
                      callback: function (label) {
                        if (/\s/.test(label)) {
                          return label.split(/(&)/);
                        } else {
                          return label;
                        }
                      }
                    },
                    gridLines: {
                      display: false,
                    },
                  },
                ],
                yAxes: [
                  {
                    stacked: false,
                    ticks: {
                      display: true,
                      beginAtZero: true,
                      min: 0,
                      fontFamily: 'Novozymes Bold, Arial',
                      callback: function (value, index, values) {
                        return value.toLocaleString(locale);
                      }
                    },
                    gridLines: {
                      display: true,
                    },
                  },
                ],
              },
              responsive: true,
              legend: {
                display: false,
                position: 'top',
                labels: {
                  fontFamily: 'Novozymes Bold, Arial',
                },
              },
              plugins: {
                chartArea: {
                  backgroundColor: 'rgba(0, 0, 0, 0)',
                },
                chartJsPluginErrorBars: {
                  drawTime: 'beforeDatasetsDraw',
                  width: '15%',
                  color: 'grey',
                },
                annotation: {
                  annotations: [
                    {
                      drawTime: 'beforeDatasetsDraw',
                      id: 'hline',
                      type: 'bar',
                      mode: 'horizontal',
                      scaleID: 'y-axis-0',
                      borderColor: 'red',
                      borderWidth: 5,
                    },
                  ],
                },
              },
              animation: {
                duration: 0,
              },
              tooltips: {
                mode: 'index',
                intersect: false,
                titleFontSize: 16,
                bodyFontSize: 16,
                filter: function (tooltipItems, data) {
                  if (benchmarkIsSet) {
                    return tooltipItems.datasetIndex !== 2 && tooltipItems.datasetIndex !== 4;
                  } else {
                    return true;
                  }
                },
                callbacks: {
                  label: function (tooltipItem, data) {
                    //@ts-ignore
                    if (tooltipItem.datasetIndex !== undefined && data.datasets.length !== undefined) {
                      let label: any = '';
                      const { datasetIndex, index } = tooltipItem;
                      //@ts-ignore
                      if (data.datasets.length === 1) {
                        //@ts-ignore
                        const value = data.datasets[0].data[index];
                        //@ts-ignore
                        label = `${data.datasets[0].label}:  ${value.toLocaleString(locale)}`;
                      } else {
                        //@ts-ignore
                        const value = Number(data.datasets[datasetIndex].data[index]);
                        //@ts-ignore
                        label = `${data.datasets[datasetIndex].label}: ${value.toLocaleString(locale)}`;
                      }
                      return label !== undefined ? label : '';
                    }
                  },
                },
              },
            }}
          />
        </Grid>
      </Grid>
    </Paper>
  );
};
