import { connect } from 'react-redux';
import { RootState } from '../reducers/root';
import { CompareStainDetails } from '../components/CompareFormulas/CompareStainDetails';

const mapStateToProps = (state: RootState) => ({
  region: state.UserInputState.region,
  benchmark: state.UserInputState.benchmark,
  BenchmarkData: state.FormulationSpace.BenchmarkData,
  BenchmarkFormulaList: state.UserInputState.BenchmarkFormulasList,
  ComparedFormulationsData: state.FormulationSpace.ComparedFormulationsData,
  isFetching: state.UserInputState.isFetching,
  StainGroups: state.FormulationSpace.StainGroups,
  StainGroupNames: state.UserInputState.stainGroupNames,
});

export default connect(mapStateToProps)(CompareStainDetails);
