import React from 'react';
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';
import Typography from '@material-ui/core/Typography';
import { FormulationSpaceState } from '../../reducers/FormulationSpace';
import { Theme, makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';

interface Props {
  theme: Theme;
  data: FormulationSpaceState;
  currency: string;
  locale: string;
}

const useStyles = makeStyles(theme => ({
  submit: {
    width: '55px',
    borderRadius: '22.5px',
    backgroundColor: '#c5da00',
  },
  paper: {
    width: '100%',
    paddingLeft: '48px',
    paddingRight: '33px',
    padding: '20px',
  },
  novofontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px',
    margin: '5px 0px 5px 0px',
  },
  navAbout: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px',
    marginLeft: 'auto',
    marginRight: '20px',
    color: '#2a2a2a',
  }
}));

export const CompositionCost: React.SFC<Props> = ({ theme, data, currency, locale }) => {
  const classes = useStyles(theme);
  const { predictedResponse, benchmarkResponse, totalFormulationCost } = data;
  return (
    <Paper square className={classes.paper}>
      <Grid container alignContent='center' justify='center'>
        <Grid item sm={12}>
          <Typography>Composition & Cost</Typography>
        </Grid>
        <Grid item sm={12}>
          <Grid container alignItems='center' justify='space-between'>
            <Typography className={classes.novofontRegular} variant='subtitle1'>
              Predicted response
            </Typography>
            <Typography>
              {predictedResponse.toLocaleString(locale)}
              <span className={classes.novofontRegular} style={{ marginLeft: '5px', color: '#b5b5b5' }}>REM</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item sm={12}>
          <Grid container alignItems='center' justify='space-between'>
            <Typography className={classes.novofontRegular} variant='subtitle1'>
              Benchmark response
            </Typography>
            <Typography>
              {benchmarkResponse.toLocaleString(locale)}
              <span className={classes.novofontRegular} style={{ marginLeft: '5px', color: '#b5b5b5' }}>REM</span>
            </Typography>
          </Grid>
        </Grid>
        <Grid item container justify='flex-end' sm={12}>
          <Divider variant='inset' style={{ width: '100px' }} />
        </Grid>
        <Grid item sm={12}>
          <Grid container alignItems='center' justify='space-between'>
            <Typography className={classes.novofontRegular} variant='subtitle1'>
              Total formulation cost
            </Typography>
            <Typography >
              {totalFormulationCost.toLocaleString(locale)}
              <span className={classes.novofontRegular} style={{ marginLeft: '5px', color: '#b5b5b5' }}>{currency}/ton</span>
            </Typography>
          </Grid>
        </Grid>
      </Grid>
    </Paper>
  );
};
