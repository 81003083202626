import React from 'react';
import { Theme } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Divider from '@material-ui/core/Divider';
import BenchmarkFormulas from '../../containers/BenchmarkFormulas';
import SavedFormulasSelection from '../../containers/SavedFormulasSelection';

interface Props {
  theme: Theme;
}

export const CompareSelectionPage: React.SFC<Props> = ({ theme }) => {
  return (
    <div>
      <Grid container alignContent='center' justify='center'>
        <Grid item xs={10} sm={12}>
          <BenchmarkFormulas theme={theme} />
        </Grid>
        <Grid item xs={10} sm={12}>
          <SavedFormulasSelection theme={theme} />
        </Grid>
      </Grid>
      <Divider />
    </div>
  );
};
