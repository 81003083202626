import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { toggleExportDataDialog, AppActionTypes } from '../actions/AppState';
import { RightPanel } from '../components/FormulationSpace/RightPanel';

const dispatchProps = (dispatch: Dispatch<AppActionTypes>) => {
  return {
    toggleExportDataDialog: (value: boolean) => dispatch(toggleExportDataDialog(value)),
  };
};

export default connect(null, dispatchProps)(RightPanel);
