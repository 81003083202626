import { connect } from 'react-redux';
import { RootState } from '../reducers/root';
import { Dispatch } from 'react';
import { deleteUserFormulation } from '../actions/UserInput';
import { PopupDeleteDialog } from '../components/CompareFormulas/PopupDeleteDialog';

const mapStateToProps = (state: RootState) => ({
});

const dispatchProps = (dispatch: Dispatch<any>) => {
  return {
    //@ts-ignore
    deleteUserFormulation: (formulaId: number) => dispatch(deleteUserFormulation(formulaId))
  };
};

export default connect(mapStateToProps, dispatchProps)(PopupDeleteDialog);
