import React from 'react';
import { makeStyles, Theme, createStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import NavigateNextIcon from '@material-ui/icons/NavigateNext';
//@ts-ignore
import ScrollspyNav from 'react-scrollspy-nav';
import Button from '@material-ui/core/Button';
interface Props {
  theme: Theme;
  toggleExportDataDialog: (value: boolean) => void;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
      height: '80vh',
      fontFamily: 'Novozymes Regular, Arial',
      position: 'fixed',
    },
    novoFontRegular: {
      fontFamily: 'Novozymes Regular, Arial',
    },
    exportButton: {
      width: '123px',
      height: '32px',
      borderRadius: '22.5px',
      border: 'solid 1px #2a2a2a',
      fontSize: '14px',
      fontFamily: 'Novozymes Bold, Arial',
      textTransform: 'none',
    },
    activeNav: {
      fontFamily: 'Novozymes Bold, Arial',
      textDecoration: 'none',
      color: '#000',
      '& div div div': {
        backgroundColor: '#C5DA01!important',
        boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
      },
      '& div div span': {
        color: '#000!important',
      },
    },
  })
);

export const RightPanel: React.SFC<Props> = ({ toggleExportDataDialog }) => {
  const classes = useStyles();
  const steps = ['REM', 'Cost', 'Sustainability', 'Labels'];
  const links = ['#remcharts', '#formulationcost', '#sustainabilityimpact', '#sustainabilitylabel'];

  const handleClick = () => {
    toggleExportDataDialog(true);
  };

  return (
    <div>
      <Button className={classes.exportButton} variant='outlined' onClick={handleClick}>
        Export data
      </Button>
      <ScrollspyNav
        scrollTargetIds={['remcharts', 'formulationcost', 'sustainabilityimpact', 'sustainabilitylabel']}
        activeNavClass={classes.activeNav}
        scrollDuration='300'
        offset={-40}
        headerBackground='true'
      >
        <Grid className={classes.root} xs='auto' container item alignItems='flex-start' justify='center' direction='column'>
          {steps.map((label, index) => (
            <a key={index} href={links[index]} style={{ textDecoration: 'none' }}>
              <Grid container alignItems='center'>
                <Grid container item alignItems='center'>
                  <div style={{ width: '23px', height: '23px', borderRadius: '50%', backgroundColor: '#CCC', color: 'white' }}>
                    <NavigateNextIcon />
                  </div>
                  <span style={{ padding: '10px', color: '#CCC' }}>{label}</span>
                </Grid>
                {index <= 2 && (
                  <span
                    style={{ marginLeft: '10px', border: '0', height: '30px', width: '2px', borderRadius: '1px', backgroundColor: '#ccc' }}
                  ></span>
                )}
              </Grid>
            </a>
          ))}
        </Grid>
      </ScrollspyNav>
    </div>
  );
};
