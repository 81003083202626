import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { SignIn } from '../Auth/SignIn';
import { ResetPassword } from './ResetPassword';
import { AuthProps } from '../../types/App';
import authtablogo from '../../images/NZLogo.svg';
import Tabs from '@material-ui/core/Tabs';
import Tab from '@material-ui/core/Tab';
import Typography from '@material-ui/core/Typography';
import Box from '@material-ui/core/Box';
import Grid from '@material-ui/core/Grid';
import Button from '@material-ui/core/Button';

interface Props {
  theme: Theme;
  authProps: AuthProps;
  toggleTerms: (value: boolean) => void
}

interface TabPanelProps {
  children?: React.ReactNode;
  index: any;
  value: any;
}

const useStyles = makeStyles(theme => ({
  root: {
    flexGrow: 1,
    width: '500px',
    height: '500px',
    backgroundColor: theme.palette.background.paper,
    boxSizing: 'border-box'
  },
  authTab: {
    height: '100vh'
  },
  authtablogo: {
    backgroundImage: `url(${authtablogo})`,
    backgroundPosition: 'top right',
    backgroundRepeat: 'no-repeat'
  },
  textproperty: {
    fontFamily: 'Novozymes Bold',
    fontSize: '18px',
    textTransform: 'none',
    lineHeight: '20px',
    color: 'inherit'
  },
  insidebox: {
    boxSizing: 'border-box',
    width: '400px',
    margin: 'auto',
    height: '100%',
    paddingTop: '30px'
  },
  novoButtons: {
    width: 'auto',
    height: '45px',
    borderRadius: '22.5px',
    fontFamily: 'Novozymes Bold, Arial',
    fontSize: '16px',
    lineHeight: '20px',
    textTransform: 'none',
    color: 'primary',
    padding: '10px'
  }
}));

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Typography
      component='div'
      role='tabpanel'
      hidden={value !== index}
      id={`scrollable-auto-tabpanel-${index}`}
      aria-labelledby={`scrollable-auto-tab-${index}`}
      {...other}
    >
      {value === index && <Box>{children}</Box>}
    </Typography>
  );
}

function a11yProps(index: any) {
  return {
    id: `scrollable-auto-tab-${index}`,
    'aria-controls': `scrollable-auto-tabpanel-${index}`
  };
}

export const AuthTabs: React.SFC<Props> = ({ theme, authProps, toggleTerms, ...props }) => {
  const classes = useStyles(theme);
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <Box className={classes.root} component='div' boxShadow={5}>
      <Box className={classes.insidebox} component='div'>
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor='primary'
          textColor='inherit'
          variant='fullWidth'
          scrollButtons='auto'
          aria-label='sign in and sign up tabs'
          style={{ borderBottom: '#CED0CD 1px solid', marginBottom: '20px' }}
        >
          <Tab className={classes.textproperty} label='Sign In' {...a11yProps(0)} />
          <Tab className={classes.textproperty} label='Reset password' {...a11yProps(1)} />
        </Tabs>

        <TabPanel value={value} index={0}>
          <SignIn theme={theme} authProps={authProps} />
        </TabPanel>
        <TabPanel value={value} index={1}>
          <ResetPassword theme={theme} />
        </TabPanel>
      </Box>
      <Grid container justify='center' alignItems='center' direction='column'>
        <Button className={classes.novoButtons} onClick={() => toggleTerms(true)} ><span style={{ textDecoration: 'underline' }}>Terms & Conditions</span></Button>
      </Grid>
    </Box>
  );
};
