import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import { Bar } from 'react-chartjs-2';
import { CustomLegend } from '../FormulationSpace/CustomLegend';
import { computeCost } from '../../utility/CustomFunctions';

interface Props {
  theme: Theme;
  locale: string;
  currency: string;
  benchmark: string;
  BenchmarkFormulasList: any;
  totalFormulationCost: number;
  ingredientCosts: any;
  UserFormulations: any;
}

const useStyles = makeStyles(theme => ({
  chartContainer: {
    width: '100%',
    height: '350px',
    boxSizing: 'border-box',
    padding: '20px',
    fontFamily: 'Novozymes Bold, Arial'
  },
  graphContainers: {
    backgroundColor: theme.palette.background.paper,
    height: '300px',
    padding: '10px',
    margin: '0px 10px',
    width: '200px'
  },
  Legend: {
    backgroundColor: 'rgb(208, 226, 70)',
    width: '50px',
    height: '20px',
    margin: '0 5px'
  },
  benchmarkLegend: {
    backgroundColor: 'grey',
    width: '50px',
    height: '4px',
    margin: '0 5px 0 20px'
  },
  novoFontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px'
  },
  novoTitle: {
    fontFamily: 'Novozymes Bold, Arial',
    fontSize: '16px',
  },
  tooltipBox: {
    position: 'absolute',
    display: 'inline-block',
    width: '10px',
    height: '10px',
  },
  tooltipBgBox: {
    position: 'relative',
    display: 'inline-block',
    width: '10px',
    height: '10px',
    background: 'white',
    marginRight: '10px',
  }
}));

const primaryColor = '#2d0028';

const generateBenchmark = (totalFormulationCost: number, formulaName: string): any => {
  return {
    label: 'Benchmark',
    data: [totalFormulationCost],
    backgroundColor: 'rgba(0,0,0,0)',
    barPercentage: 1.0,
    categoryPercentage: 1.0,
    borderWidth: {
      top: 2,
      right: 0,
      bottom: 0,
      left: 0,
    },
    borderColor: 'gray',
    stack: formulaName
  };
};

const generateUserDataset = (formulaName: string, formulationCost: number, backgroundColor: string) => {
  return {
    label: formulaName,
    data: [formulationCost],
    backgroundColor: backgroundColor,
    barPercentage: 1.0,
    categoryPercentage: 1.0,
    barThickness: 50,
    stack: formulaName
  };
};
const generateDataset = (totalFormulationCost: number, benchmark: string, currency: string, ingredientCosts: any, UserFormulations: any) => {

  const selectedUserFormulations = Object.entries(UserFormulations).filter((userFormulation: any) => userFormulation[1].isSelected);

  let comparedDatasets: any = [];
  selectedUserFormulations.forEach((userFormulation: any, index: number) => {
    const { las, aes, aeo, soap, amplifyprime100l, progressuno100l, lipexevity200l, mannaway200l, citrate } = userFormulation[1].data;
    const currentFormulation = {
      las: las,
      aes: aes,
      aeo: aeo,
      soap: soap,
      amylase: amplifyprime100l,
      protease: progressuno100l,
      lipase: lipexevity200l,
      mannanase: mannaway200l,
      citrate: citrate,
    };

    const formulationName = userFormulation[1].data.name;
    const formulationCost = computeCost(currentFormulation, ingredientCosts);
    const backgroundColor = `rgba(197, 218, 0, ${.8 - (index * .3)})`;
    const benchmarkIsSet = Number(benchmark) > 1;

    if (benchmarkIsSet) {
      comparedDatasets = [...comparedDatasets, generateBenchmark(totalFormulationCost, formulationName)];
    }
    comparedDatasets = [...comparedDatasets, generateUserDataset(formulationName, formulationCost, backgroundColor)];
  });

  const comparedFormulationCostDataset: any = {
    labels: [`${currency}/ton`],
    datasets: comparedDatasets
  };

  return comparedFormulationCostDataset;
};

export const CompareFormulationCost: React.SFC<Props> = ({ theme, locale, currency, benchmark, BenchmarkFormulasList, totalFormulationCost, ingredientCosts, UserFormulations }) => {
  const classes = useStyles(theme);

  const newDataset = generateDataset(totalFormulationCost, benchmark, currency, ingredientCosts, UserFormulations);


  const benchmarkIsSet = Number(benchmark) > 1;
  let benchmarkName: string = 'No benchmark';

  if (benchmarkIsSet) {
    const { name } = BenchmarkFormulasList[benchmark][1];
    benchmarkName = `${name} benchmark`;
  }

  return (
    <div style={{ marginTop: '60px', marginBottom: '80px' }}>
      <Grid container alignItems='center' spacing={5}>
        <Grid item xs={12}>
          <Typography className={classes.novoTitle} variant='h5'>Formulation cost</Typography>
        </Grid>
        <Grid item xs={5}>
          <Paper className={classes.chartContainer} elevation={24} >
            <Bar
              redraw={true}
              data={newDataset}
              width={100}
              height={300}
              options={{
                maintainAspectRatio: false,
                legend: {
                  display: false,
                  position: 'bottom',
                  fullWidth: true,
                },
                scales: {
                  xAxes: [
                    {
                      id: "bar-x-axis2",
                      stacked: true,
                      ticks: {
                        beginAtZero: true,
                        fontFamily: 'Novozymes Bold, Arial',
                        fontColor: primaryColor
                      },
                      gridLines: {
                        display: false
                      }
                    }
                  ],
                  yAxes: [
                    {
                      stacked: false,
                      ticks: {
                        beginAtZero: true,
                        fontFamily: 'Novozymes Bold, Arial',
                        fontColor: primaryColor,
                        callback: function (value, index, values) {
                          return value.toLocaleString(locale);
                        }
                      }
                    }
                  ]
                },
                animation: {
                  duration: 0,
                },
                tooltips: {
                  mode: 'index',
                  intersect: true,
                  enabled: false,
                  filter: function (tooltipItems, data) {
                    return benchmarkIsSet ? tooltipItems.datasetIndex !== 2 && tooltipItems.datasetIndex !== 4 : true;
                  },
                  callbacks: {
                    label: function (tooltipItem, data) {
                      if (tooltipItem.datasetIndex !== undefined && data.datasets !== undefined && data.datasets[0] !== undefined) {
                        let label: string = '';
                        let labelName: string | undefined = '';
                        //@ts-ignore
                        if (data.datasets.length === 1) {
                          //@ts-ignore
                          const value = Number(data.datasets[0].data[tooltipItem.index]);
                          labelName = benchmarkIsSet ? 'Benchmark' : data.datasets[0].label;
                          label = `${labelName}: ${value.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                        } else {
                          if (tooltipItem.datasetIndex === 0) {
                            const { index } = tooltipItem;
                            labelName = benchmarkIsSet ? 'Benchmark' : data.datasets[0].label;
                            //@ts-ignore
                            const value = Number(data.datasets[0].data[index]);
                            label = `${labelName}: ${value.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                          } else {
                            //@ts-ignore
                            labelName = data.datasets[tooltipItem.datasetIndex].label;
                            //@ts-ignore
                            const value = Number(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);
                            label = `${labelName}: ${value.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                          }
                        }
                        return label !== undefined ? label : '';
                      }
                      return '';
                    },
                  },
                  custom: function (tooltipModel) {
                    let tooltipEl: any = document.getElementById('chartjs-tooltip');
                    if (!tooltipEl) {
                      tooltipEl = document.createElement('div');
                      tooltipEl.id = 'chartjs-tooltip';
                      tooltipEl.innerHTML = '<table></table>';
                      document.body.appendChild(tooltipEl);
                    }
                    // Hide if no tooltip
                    if (tooltipModel.opacity === 0) {
                      //@ts-ignore
                      tooltipEl.style.opacity = 0;
                      return;
                    }
                    // Set caret Position
                    tooltipEl.classList.remove('above', 'below', 'no-transform');
                    if (tooltipModel.yAlign) {
                      tooltipEl.classList.add(tooltipModel.yAlign);
                    } else {
                      tooltipEl.classList.add('no-transform');
                    }

                    function getBody(bodyItem: any) {
                      return bodyItem.lines;
                    }

                    if (tooltipModel.body) {
                      let titleLines = tooltipModel.title || [];
                      let bodyLines = tooltipModel.body.map(getBody);

                      let innerHtml = '<thead style="text-align: left">';

                      titleLines.forEach(function (title) {
                        innerHtml += '<tr><th>' + title + '</th></tr>';
                      });
                      innerHtml += '</thead><tbody>';

                      bodyLines.forEach(function (body, i) {
                        let colors = tooltipModel.labelColors[i];
                        //@ts-ignore
                        let style = 'background: ' + (i === 0 && benchmarkIsSet ? colors.borderColor : colors.backgroundColor);
                        //@ts-ignore
                        style += '; border-color:' + colors.borderColor;
                        let span = `<div class="${classes.tooltipBgBox}"> <span class="${classes.tooltipBox}" style="${style}"></span></div>`;
                        innerHtml += '<tr><td>' + span + body + '</td></tr>';
                      });
                      innerHtml += '</tbody>';

                      let tableRoot = tooltipEl.querySelector('table');
                      //@ts-ignore
                      tableRoot.innerHTML = innerHtml;
                    }

                    //@ts-ignore
                    let position = this._chart.canvas.getBoundingClientRect();

                    // Display, position, and set styles for font
                    tooltipEl.style.opacity = 0.9;
                    tooltipEl.style.color = 'white';
                    tooltipEl.style.backgroundColor = 'black';
                    tooltipEl.style.borderRadius = '10px';
                    tooltipEl.style.position = 'absolute';
                    tooltipEl.style.left = position.left + 'px'; //+ tooltipModel.caretX + 'px';
                    tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                    tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                    tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                    tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                    tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                    tooltipEl.style.pointerEvents = 'none';
                  }
                }
              }}
            />
          </Paper>
        </Grid>
        <Grid container item xs={7} justify='flex-start' spacing={2}>
          <CustomLegend theme={theme} benchmarkIsSet={benchmarkIsSet} benchmarkName={benchmarkName} datasets={newDataset.datasets} />
        </Grid>
      </Grid>
    </div >
  );
};
