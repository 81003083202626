import { Auth } from '@aws-amplify/auth';

export const api_fetch = (path: string, init?: RequestInit) => {
  return Auth.currentAuthenticatedUser().then(
    user => user.getSignInUserSession().getAccessToken().getJwtToken()
  ).then(token => {
    init = Object.assign(init || {}, {
      mode: 'cors',
      headers: {
        'authorization': 'Bearer ' + token,
      }
    });

    // todo: make this endpoint part of a config file
    return fetch('https://mwl-backend-sandbox.novozymes.cloud/' + path, init).then(response => {
      if (!response.ok) { throw response; }
      return response.json();
    });
  });
};
