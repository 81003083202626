import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import CompareStainDetails from '../../containers/CompareStainDetails';
import { stainGroupsLayout } from '../../static/Constants';
import Paper from '@material-ui/core/Paper';

interface Props {
  theme: Theme;
  BenchmarkData: any;
  region: string;
  UserFormulations: any;
  isFetching: boolean;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: 'auto',
    padding: '30px 40px 0px 40px',
    marginBottom: '50px'
  }
}));

export const CompareSingleStains: React.SFC<Props> = ({ theme, region, UserFormulations, isFetching }) => {
  const classes = useStyles(theme);
  const formulaIsSelected = Object.values(UserFormulations).filter((userFormulation: any) => {
    return userFormulation.isSelected && userFormulation.data.region === region;
  }).length > 0;

  const renderGraphs = () => {
    return Object.values(stainGroupsLayout[region]).map((stainGroup: any, index: number) => {
      return (
        <Grid key={index} container justify='space-between' spacing={2}>
          {stainGroup.stainGroupNames.map((groupName: string, index: number) => {
            return (
              <Grid key={index} item xs={12}>
                <Paper className={classes.root} elevation={24}>
                  <CompareStainDetails key={index} theme={theme} groupName={groupName} />
                </Paper>
              </Grid>
            );
          })}
        </Grid>
      );
    });
  };

  const renderChooseFormula = () => {
    return (
      <div className={classes.root}>
        <Grid container justify='center'>
          <Grid item xs={11}>
            <Typography variant='h5'>Please select formulations to compare</Typography>
          </Grid>
        </Grid>
      </div>
    );
  };

  return (
    <Grid container justify='center'>
      <Grid item xs={12}>
        {formulaIsSelected ? renderGraphs() : renderChooseFormula()}
      </Grid>
    </Grid>
  );
};