import { UserLocale } from '../types/AppState';
import {
  CHANGE_APPTAB,
  CHANGE_PERFORMANCE_TAB,
  CHANGE_COMPARE_FORMULAS_TAB,
  INITIAL_DATA_LOADED,
  NEXT_WINDOW,
  PREV_WINDOW,
  TOGGLE_ABOUTWASHLAB,
  SET_USER_LOCALE,
  AppActionTypes,
  TOGGLE_TERMS,
  TOGGLE_EXPORT_DATA_DIALOG,
} from '../actions/AppState';

interface AppState {
  appTab: number;
  predictionTab: number;
  compareFormulasTab: number;
  initialDataLoaded: boolean;
  window: number;
  showAboutWashlabDetails: boolean;
  showTerms: boolean;
  showExportDataDialog: boolean;
  userLocale: UserLocale;
}

const initialAppState = {
  appTab: 0, // 0 - Formulation space / Performance prediction
  predictionTab: 0,
  compareFormulasTab: 0,
  initialDataLoaded: false,
  window: 1,
  showAboutWashlabDetails: false,
  showTerms: false,
  showExportDataDialog: false,
  userLocale: {
    locale: 'da-DK',
    groupSeparator: '.',
    decimalSeparator: ',',
  },
};

export default (state: AppState = initialAppState, action: AppActionTypes): AppState => {
  switch (action.type) {
    case CHANGE_APPTAB:
      return Object.assign({}, state, {
        appTab: action.appTab,
      });
    case CHANGE_PERFORMANCE_TAB:
      return Object.assign({}, state, {
        predictionTab: action.value,
      });
    case CHANGE_COMPARE_FORMULAS_TAB:
      return Object.assign({}, state, {
        compareFormulasTab: action.value,
      });
    case INITIAL_DATA_LOADED:
      return Object.assign({}, state, {
        initialDataLoaded: action.initialDataLoaded,
      });
    case NEXT_WINDOW:
      return Object.assign({}, state, {
        window: action.window,
      });
    case PREV_WINDOW:
      return Object.assign({}, state, {
        window: action.window,
      });
    case TOGGLE_ABOUTWASHLAB:
      return Object.assign({}, state, {
        showAboutWashlabDetails: action.value,
      });
    case SET_USER_LOCALE:
      return Object.assign({}, state, {
        userLocale: {
          locale: action.userLocale,
          groupSeparator: action.groupSeparator,
          decimalSeparator: action.decimalSeparator,
        },
      });
    case TOGGLE_TERMS:
      return Object.assign({}, state, {
        showTerms: action.value,
      });
    case TOGGLE_EXPORT_DATA_DIALOG:
      return Object.assign({}, state, {
        showExportDataDialog: action.value,
      });
    default:
      return state;
  }
};
