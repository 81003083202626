import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { RootState } from '../reducers/root';
import { toggleExportDataDialog, AppActionTypes } from '../actions/AppState';
import { filterUserFormulations } from '../utility/CustomFunctions';
import industryBenchmarks from '../static/industryBenchmarks.json';
import { exportMultipleDataToXlsx, FormulationActionTypes } from '../actions/FormulationSpace';
import { ExportSelectedFormulationsType } from '../types/FormulationSpace';
import { ExportDataDialog } from '../components/ExportData/ExportDataDialog';

const mapStateToProps = (state: RootState) => ({
  showExportDataDialog: state.AppState.showExportDataDialog,
  UserFormulations: filterUserFormulations(state.UserInputState.UserFormulations, state.UserInputState.region, state.UserInputState.temperature),
  //@ts-ignore
  BenchmarkFormulas: industryBenchmarks[state.UserInputState.region]
});

const dispatchProps = (dispatch: Dispatch<AppActionTypes | FormulationActionTypes>) => {
  return {
    toggleExportDataDialog: (value: boolean) => dispatch(toggleExportDataDialog(value)),
    //@ts-ignore
    exportMultipleDataToXlsx: (selectedFormulations: ExportSelectedFormulationsType) => dispatch(exportMultipleDataToXlsx(selectedFormulations)),
  };
};

export default connect(mapStateToProps, dispatchProps)(ExportDataDialog);
