import { connect } from 'react-redux';
import { RootState } from '../reducers/root';
import { Dispatch } from 'react';
import { FormulationCost } from '../components/FormulationSpace/FormulationCost';

const mapStateToProps = (state: RootState) => ({
  locale: state.AppState.userLocale.locale,
  currency: state.UserInputState.currency,
  benchmark: state.UserInputState.benchmark,
  BenchmarkFormulasList: state.UserInputState.BenchmarkFormulasList,
  totalFormulationCost: state.FormulationSpace.totalFormulationCost,
  ingredientCosts: state.UserInputState.ingredientCosts,
});

const dispatchProps = (dispatch: Dispatch<any>) => {
  return {
  };
};

export default connect(mapStateToProps, dispatchProps)(FormulationCost);
