import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import ExpansionPanel from '@material-ui/core/ExpansionPanel';
import ExpansionPanelSummary from '@material-ui/core/ExpansionPanelSummary';
import ExpansionPanelDetails from '@material-ui/core/ExpansionPanelDetails';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import Paper from '@material-ui/core/Paper';
import PopupDeleteDialog from '../../containers/PopupDeleteDialog';

interface Props {
  theme: Theme;
  UserFormulations: any;
  toggleUserFormulation: (formulaId: number) => void;
}

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    height: 'auto',
  },
  novofontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px',
    padding: '5px',
  },
  formControl: {
    margin: theme.spacing(0),
  },
  thrashIcon: {
    color: '#ff5252',
    margin: 'auto 0 auto auto',
  },
  novoFont16: {
    fontSize: '16px',
  },
  leftPaneLayout: {
    paddingLeft: '48px',
    paddingRight: '33px',
  },
}));

interface CreateFormulationsListParams {
  classes: any;
  userFormulationsPerRegion: any;
  handleChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const CreateFormulationsList: React.SFC<CreateFormulationsListParams> = ({ classes, userFormulationsPerRegion, handleChange }) => {
  return (
    <FormControl required component='fieldset' className={classes.formControl}>
      <FormGroup>
        {Object.keys(userFormulationsPerRegion).map((formulationId: any, index: number) => {
          return (
            <Grid key={index} container alignContent='center' justify='space-between' wrap='nowrap'>
              <FormControlLabel
                key={formulationId}
                control={
                  <Checkbox
                    checked={userFormulationsPerRegion[formulationId].isSelected}
                    color='primary'
                    onChange={handleChange}
                    name={formulationId}
                  />
                }
                label={
                  <span style={{ fontFamily: 'Novozymes Regular, Arial', marginRight: 'auto', fontSize: '14px' }}>{`${
                    userFormulationsPerRegion[formulationId].data.name
                  } - (${new Date(userFormulationsPerRegion[formulationId].data.datetime).toDateString()})`}</span>
                }
              />
              <PopupDeleteDialog formulationId={formulationId} />
            </Grid>
          );
        })}
      </FormGroup>
    </FormControl>
  );
};

export const SavedFormulasSelection: React.SFC<Props> = ({ theme, UserFormulations, toggleUserFormulation }) => {
  const classes = useStyles(theme);

  let userFormulationsPerRegion: any = {};

  try {
    // filter UserFormulations based on the selected region
    Object.entries(UserFormulations).forEach((formulation: any) => {
      userFormulationsPerRegion = {
        ...userFormulationsPerRegion,
        [formulation[0]]: {
          ...formulation[1],
        },
      };
    });
  } catch (error) {
    // do nothing
  }

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    toggleUserFormulation(+event.target.name);
  };

  const userFormulationIsEmpty = Object.entries(userFormulationsPerRegion).length === 0;

  const renderFormulationIsEmpty = () => {
    return <Typography>You don't have saved formulas.</Typography>;
  };

  return (
    <Paper square className={classes.root}>
      <Grid container alignContent='center' justify='center'>
        <Grid item sm={12}>
          <ExpansionPanel defaultExpanded>
            <ExpansionPanelSummary
              className={classes.leftPaneLayout}
              expandIcon={<ExpandMoreIcon fontSize='large' />}
              aria-controls='panel1a-content'
              id='panel1a-header'
            >
              <Typography className={classes.novoFont16} variant='h5'>
                Saved formulas
              </Typography>
            </ExpansionPanelSummary>
            <ExpansionPanelDetails className={classes.leftPaneLayout}>
              {userFormulationIsEmpty ? (
                renderFormulationIsEmpty()
              ) : (
                <CreateFormulationsList classes={classes} userFormulationsPerRegion={userFormulationsPerRegion} handleChange={handleChange} />
              )}
            </ExpansionPanelDetails>
            <Typography
              className={classes.novofontRegular}
              variant='subtitle2'
              align='center'
              style={{
                paddingLeft: '48px',
                paddingRight: '33px',
              }}
            >
              Please note you can only select up to three formulas at once
            </Typography>
          </ExpansionPanel>
        </Grid>
      </Grid>
    </Paper>
  );
};
