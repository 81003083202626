import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Popover from '@material-ui/core/Popover';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/DeleteOutline';
import IconButton from '@material-ui/core/IconButton';
import Grid from '@material-ui/core/Grid';

interface Props {
  formulationId: number;
  deleteUserFormulation: (formulationId: number) => any;
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    typography: {
      padding: theme.spacing(2),
    },
    deleteButton: {
      color: '#ff5252',
      margin: 'auto 0 auto auto',
    },
    novoButton: {
      margin: theme.spacing(2),
      borderRadius: '22.5px',
      fontFamily: 'Novozymes Bold, arial',
      textTransform: 'none',
      height: '40px',
      fontSize: '16px',
      padding: '0px 20px',
    },
    formSize: {
      width: '350px'
    }
  }),
);

export const PopupDeleteDialog: React.SFC<Props> = ({ formulationId, deleteUserFormulation }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLButtonElement | null>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteConfirmation = () => {
    deleteUserFormulation(formulationId);
    handleClose();
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  return (
    <div>
      <IconButton color='primary' onClick={handleClick}>
        <DeleteIcon className={classes.deleteButton} />
      </IconButton>
      <Popover
        id={id}
        open={open}
        anchorEl={anchorEl}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
      >
        <div className={classes.formSize}>
          <Grid container justify='center'>
            <Grid container item justify='center' >
              <Typography className={classes.typography}>Do you want to delete this formulation?</Typography>
            </Grid>
            <Grid container item justify='center'>
              <Button className={classes.novoButton} variant='outlined' onClick={handleDeleteConfirmation} >Yes</Button>
              <Button className={classes.novoButton} variant='outlined' onClick={handleClose}>No</Button>
            </Grid>
          </Grid>
        </div>
      </Popover>
    </div>
  );
};
