import React from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { Bar } from 'react-chartjs-2';
import Paper from '@material-ui/core/Paper';
import Box from '@material-ui/core/Box';
import { ComparedSustainabilityDataType } from '../../types/FormulationSpace';

interface Props {
  theme: Theme;
  benchmark: string;
  BenchmarkFormulasList: any;
  ImpactData: any;
  SustainabilityBenchmarkData: any;
  locale: string;
  CompareSustainabilityData: ComparedSustainabilityDataType;
  selectedUserFormulas: any;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '100%',
    boxSizing: 'border-box',
    paddingTop: '20px',
    fontFamily: 'Novozymes Bold, Arial',
  },
  graphContainers: {
    height: '300px',
    width: '170px',
    padding: '10px 5px',
  },
  legendContainer: {
    paddingTop: '50px',
  },
  formulaLegend: {
    backgroundColor: 'rgba(197, 218, 0, 0.5 )',
    width: '50px',
    height: '20px',
    margin: '0 5px'
  },
  benchmarkLegend: {
    backgroundColor: 'grey',
    marginTop: '10px',
    width: '50px',
    height: '4px',
    margin: '0 5px 0 0px'
  },
  novoFontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    fontSize: '14px'
  },
  novoTitle: {
    fontFamily: 'Novozymes Bold, Arial',
    fontSize: '16px',
    paddingBottom: '30px',
  },
  tooltipBox: {
    position: 'absolute',
    display: 'inline-block',
    width: '10px',
    height: '10px',
  },
  tooltipBgBox: {
    position: 'relative',
    display: 'inline-block',
    width: '10px',
    height: '10px',
    background: 'white',
    marginRight: '10px',
  }
}));

const primaryColor = '#2d0028';

const generateBenchmark = (benchmarkValue: number, labelName: string): any => {
  return {
    label: 'Benchmark',
    data: [benchmarkValue],
    backgroundColor: 'rgba(0,0,0,0)',
    barPercentage: 1.0,
    categoryPercentage: 1.0,
    borderWidth: {
      top: 2,
      right: 0,
      bottom: 0,
      left: 0,
    },
    borderColor: 'gray',
    stack: labelName
  };
};

const generateUserDataset = (labelName: string, dataValue: number, backgroundColor: string) => {
  return {
    label: labelName,
    data: [dataValue],
    backgroundColor: backgroundColor,
    barPercentage: 1.0,
    categoryPercentage: 1.0,
    barThickness: 50,
    stack: labelName
  };
};

export const CompareSustainabilityImpact: React.SFC<Props> = ({ theme, benchmark, BenchmarkFormulasList, ImpactData, SustainabilityBenchmarkData, locale, CompareSustainabilityData, selectedUserFormulas }) => {
  const classes = useStyles(theme);

  const labelNames: any = {
    co2: 'CO2  in million tons',
    chemical: 'Chemicals  in million tons',
    cdv: 'CDV in  billion m3 water'
  };

  const selectedFormulaId = Object.keys(selectedUserFormulas);
  const isNotEmpty = Object.keys(CompareSustainabilityData).length !== 0;
  const benchmarkIsSet = Number(benchmark) > 1;

  let comparedImpactDatasets: any = {};

  if (isNotEmpty) {
    // get the fieldnames of ImpactData
    const impactKeys = Object.keys(labelNames);

    impactKeys.forEach(labelName => {
      let benchmarkValue: number;
      let comparedDatasets: any = [];

      try {
        selectedFormulaId.forEach((formulaId, index) => {
          const formulaName = selectedUserFormulas[formulaId].name;
          const backgroundColor = `rgba(197, 218, 0, ${.8 - (index * .3)})`;
          //@ts-ignore
          const formulaValue = CompareSustainabilityData.ImpactData[formulaId][labelName];

          if (benchmarkIsSet) {
            benchmarkValue = SustainabilityBenchmarkData[labelName];
            comparedDatasets = [
              ...comparedDatasets,
              generateBenchmark(benchmarkValue, formulaName),
              generateUserDataset(formulaName, formulaValue, backgroundColor),
            ];
          } else {
            comparedDatasets = [...comparedDatasets, generateUserDataset(formulaName, formulaValue, backgroundColor)];
          }
        });
      } catch (error) {

      }

      comparedImpactDatasets = {
        ...comparedImpactDatasets,
        [labelName]: {
          labels: [labelNames[labelName]],
          datasets: comparedDatasets
        }
      };
    });

  }  // isNotEmpty block

  let filteredDatasets = comparedImpactDatasets['co2'].datasets;

  let benchmarkName: string = 'No benchmark';
  if (benchmarkIsSet) {
    const { name } = BenchmarkFormulasList[benchmark][1];
    benchmarkName = `${name} benchmark`;
    filteredDatasets = comparedImpactDatasets['co2'].datasets.filter((dataset: any, index: number) => index !== 0 && index !== 2 && index !== 4);
  }

  return (
    <div>
      <Grid container item xs={12} className={classes.root} >
        <Grid item>
          <Typography className={classes.novoTitle} variant='h5'>Sustainability, Yearly impact</Typography>
        </Grid>
        <Grid container item justify='space-between' xs={12} >
          {Object.keys(comparedImpactDatasets).map((keyName: string) => {
            return (
              <Paper key={keyName} elevation={24} >
                <Grid className={classes.graphContainers} item style={{ width: '200px', height: '300px', padding: '15px' }}>
                  <Bar
                    redraw={true}
                    data={comparedImpactDatasets[keyName]}
                    width={100}
                    height={300}
                    options={{
                      maintainAspectRatio: false,
                      legend: {
                        display: false
                      },
                      animation: {
                        duration: 0,
                      },
                      scales: {
                        xAxes: [
                          {
                            id: "benchmark",
                            stacked: true,
                            ticks: {
                              beginAtZero: true,
                              fontFamily: 'Novozymes Bold, Arial',
                              fontColor: primaryColor,
                              callback: function (label) {
                                if (/\s/.test(label)) {
                                  // eslint-disable-next-line
                                  return label.split(/  /);
                                } else {
                                  return label;
                                }
                              }
                            },
                            gridLines: {
                              display: false
                            }
                          }
                        ],
                        yAxes: [
                          {
                            stacked: false,
                            ticks: {
                              beginAtZero: true,
                              fontFamily: 'Novozymes Bold, Arial',
                              fontSize: 12,
                              fontColor: primaryColor,
                              callback: function (value, index, values) {
                                return value.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 });
                              }
                            },
                          }
                        ]
                      },
                      tooltips: {
                        mode: 'index',
                        intersect: false,
                        enabled: false,
                        filter: function (tooltipItems, data) {
                          return benchmarkIsSet ? tooltipItems.datasetIndex !== 2 && tooltipItems.datasetIndex !== 4 : true;
                        },
                        callbacks: {
                          label: function (tooltipItem, data) {
                            if (tooltipItem.datasetIndex !== undefined && data.datasets !== undefined && data.datasets[0] !== undefined) {
                              let label: string = '';
                              let labelName: string | undefined = '';
                              //@ts-ignore
                              if (data.datasets.length === 1) {
                                //@ts-ignore
                                const value = Number(data.datasets[0].data[tooltipItem.index]);
                                labelName = benchmarkIsSet ? 'Benchmark' : data.datasets[0].label;
                                label = `${labelName}: ${value.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                              } else {
                                if (tooltipItem.datasetIndex === 0) {
                                  const { index } = tooltipItem;
                                  labelName = benchmarkIsSet ? 'Benchmark' : data.datasets[0].label;
                                  //@ts-ignore
                                  const value = Number(data.datasets[0].data[index]);
                                  label = `${labelName}: ${value.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                                } else {
                                  //@ts-ignore
                                  labelName = data.datasets[tooltipItem.datasetIndex].label;
                                  //@ts-ignore
                                  const value = Number(data.datasets[tooltipItem.datasetIndex].data[tooltipItem.index]);
                                  label = `${labelName}: ${value.toLocaleString(locale, { minimumFractionDigits: 2, maximumFractionDigits: 2 })}`;
                                }
                              }
                              return label !== undefined ? label : '';
                            }
                            return '';
                          }
                        },
                        custom: function (tooltipModel) {
                          let tooltipEl: any = document.getElementById('chartjs-tooltip');
                          if (!tooltipEl) {
                            tooltipEl = document.createElement('div');
                            tooltipEl.id = 'chartjs-tooltip';
                            tooltipEl.innerHTML = '<table></table>';
                            document.body.appendChild(tooltipEl);
                          }
                          // Hide if no tooltip
                          if (tooltipModel.opacity === 0) {
                            //@ts-ignore
                            tooltipEl.style.opacity = 0;
                            return;
                          }
                          // Set caret Position
                          tooltipEl.classList.remove('above', 'below', 'no-transform');
                          if (tooltipModel.yAlign) {
                            tooltipEl.classList.add(tooltipModel.yAlign);
                          } else {
                            tooltipEl.classList.add('no-transform');
                          }

                          function getBody(bodyItem: any) {
                            return bodyItem.lines;
                          }

                          if (tooltipModel.body) {
                            let titleLines = tooltipModel.title || [];
                            let bodyLines = tooltipModel.body.map(getBody);

                            let innerHtml = '<thead style="text-align: left">';

                            titleLines.forEach(function (title) {
                              innerHtml += '<tr><th>' + title + '</th></tr>';
                            });
                            innerHtml += '</thead><tbody>';

                            bodyLines.forEach(function (body, i) {
                              let colors = tooltipModel.labelColors[i];
                              //@ts-ignore
                              let style = 'background: ' + (i === 0 && benchmarkIsSet ? colors.borderColor : colors.backgroundColor);
                              //@ts-ignore
                              style += '; border-color:' + colors.borderColor;
                              let span = `<div class="${classes.tooltipBgBox}"> <span class="${classes.tooltipBox}" style="${style}"></span></div>`;
                              innerHtml += '<tr><td>' + span + body + '</td></tr>';
                            });
                            innerHtml += '</tbody>';

                            let tableRoot = tooltipEl.querySelector('table');
                            //@ts-ignore
                            tableRoot.innerHTML = innerHtml;
                          }

                          //@ts-ignore
                          let position = this._chart.canvas.getBoundingClientRect();

                          // Display, position, and set styles for font
                          tooltipEl.style.opacity = 0.85;
                          tooltipEl.style.color = 'white';
                          tooltipEl.style.backgroundColor = 'black';
                          tooltipEl.style.borderRadius = '10px';
                          tooltipEl.style.position = 'absolute';
                          tooltipEl.style.left = position.left + 'px'; //+ tooltipModel.caretX + 'px';
                          tooltipEl.style.top = position.top + window.pageYOffset + tooltipModel.caretY + 'px';
                          tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
                          tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
                          tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
                          tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
                          tooltipEl.style.pointerEvents = 'none';
                        }
                      }
                    }}
                  />
                </Grid>
              </Paper>
            );
          })}
        </Grid>

        <Grid item container xs={12} justify='center' style={{ padding: '50px 10px' }}>
          <React.Fragment>
            <Grid container item xs={12} style={{ paddingTop: '10px', paddingBottom: '20px' }}>
              {benchmarkIsSet && <React.Fragment><Box className={classes.benchmarkLegend}></Box><Typography className={classes.novoFontRegular}>{benchmarkName}</Typography></React.Fragment>}
            </Grid >
            <Grid container alignItems='center' justify='flex-start' spacing={2}>
              {filteredDatasets.map((dataset: any, index: number) => {
                const { label, backgroundColor } = dataset;
                return (
                  <Grid key={index} container item xs={12}>
                    <Box className={classes.formulaLegend} style={{ backgroundColor: backgroundColor }}></Box>
                    <Typography className={classes.novoFontRegular} >{label}</Typography>
                  </Grid>
                );
              })}
            </Grid>
          </React.Fragment>
        </Grid>
      </Grid >
    </div>
  );
};
