import { Action } from 'redux';
import { ThunkAction } from 'redux-thunk';
import { RootState } from '../reducers/root';
import { api_fetch } from '../utility/Api';
import { convertValue } from '../utility/Formula';
import {
  setStainCodes,
  updateStainGroups,
  updateModelPrediction,
  setBenchmarkData,
  setPredictedResponse,
  predictUserFormulation,
  generateComparedFormulationsData,
  toggleBenchmarkIsSet,
  evaluateSustainability,
} from './FormulationSpace';

import { stainGroupNames, TARGET_PH } from '../static/Constants';

import regions from '../static/regions.json';
import industryBenchmarks from '../static/industryBenchmarks.json';
import ingredientsBoundaries from '../static/ingredientsBoundaries.json';

import { initialDataLoaded } from './AppState';

import { RegionsType } from '../types/UserInput';
import { roundOffPredictedResults } from '../utility/CustomFunctions';

export const SET_TEMPERATURE = 'USERINPUT:SET_TEMPERATURE';
export const SET_REGION = 'USERINPUT:SET_REGION';
export const SET_GROUP_NAMES = 'USERINPUT:SET_GROUP_NAMES';
export const SET_BENCHMARK = 'USERINPUT:SET_BENCHMARK';
export const SET_BENCHMARK_TYPE = 'USERINPUT:SET_BENCHMARK_TYPE';
export const SET_INGREDIENT_COST = 'USERINPUT:SET_INGREDIENT_COST';
export const SET_INGREDIENT_COST_META = 'USERINPUT:SET_INGREDIENT_COST_META';
export const SHOW_RESULT = 'USERINPUT:SHOW_RESULT';
export const UPDATE_BENCHMARK_VALUE = 'USERINPUT:UPDATE_BENCHMARK_VALUE';
export const TOGGLE_IS_FETCHING = 'USERINPUT:TOGGLE_IS_FETCHING';
export const LOAD_STAINS_INFO = 'USERINPUT:LOAD_STAINS_INFO';
export const SET_CURRENCY = 'USERINPUT:SET_CURRENCY';
export const SAVE_FORMULATION = 'USERINPUT:SAVE_FORMULATION';
export const UPDATE_USER_FORMULATIONS = 'USERINPUT:UPDATE_USER_FORMULATIONS';
export const LOAD_USER_INGREDIENTCOST = 'USERINPUT:LOAD_USER_INGREDIENTCOST';
export const SET_SURFACTANT_SUM_EXCEEDED = 'USERINPUT:SET_SURFACTANT_SUM_EXCEEDED';
export const SET_BENCHMARK_FORMULAS_LIST = 'USERINPUT:SET_BENCHMARK_FORMULAS_LIST';
export const SET_ERROR_MESSAGE = 'USERINPUT:SET_ERROR_MESSAGE';

type ThunkResult<R extends Action> = ThunkAction<void, RootState, undefined, R>;

interface SetTemperature {
  type: typeof SET_TEMPERATURE;
  value: number;
}

interface SetRegion {
  type: typeof SET_REGION;
  value: string;
}

interface SetGroupNames {
  type: typeof SET_GROUP_NAMES;
  value: string[];
}

interface SetBenchmark {
  type: typeof SET_BENCHMARK;
  value: string;
  benchmarkParameters: any;
}

interface SetBenchmarkType {
  type: typeof SET_BENCHMARK_TYPE;
  value: string;
}

interface SetIngredientCost {
  type: typeof SET_INGREDIENT_COST;
  data: { [ingredient: string]: number; };
}

interface SetIngredientCostMeta {
  type: typeof SET_INGREDIENT_COST_META;
  weightUnit: string;
  currency: string;
}

interface UpdateBenchmarkValue {
  type: typeof UPDATE_BENCHMARK_VALUE;
  data: { [ingredient: string]: number; };
}

interface ToggleIsFetching {
  type: typeof TOGGLE_IS_FETCHING;
  isFetching: boolean;
}

interface SetCurrency {
  type: typeof SET_CURRENCY;
  currency: string;
}

interface SaveFormulation {
  type: typeof SAVE_FORMULATION;
  UserFormulation: any;
}

interface UpdateUserFormulations {
  type: typeof UPDATE_USER_FORMULATIONS;
  UserFormulations: any;
}

interface LoadUserIngredientCost {
  type: typeof LOAD_USER_INGREDIENTCOST;
  ingredientCost: any;
}

interface SetSurfactantSumExceeded {
  type: typeof SET_SURFACTANT_SUM_EXCEEDED;
  value: boolean;
}

interface SetBenchmarkFormulasList {
  type: typeof SET_BENCHMARK_FORMULAS_LIST;
  value: any;
}

interface SetErrorMessage {
  type: typeof SET_ERROR_MESSAGE;
  message: string;
  isEnabled: boolean;
}

export type UserInputActionType =
  | SetTemperature
  | SetRegion
  | SetGroupNames
  | SetBenchmark
  | SetBenchmarkType
  | SetIngredientCost
  | SetIngredientCostMeta
  | UpdateBenchmarkValue
  | ToggleIsFetching
  | SetCurrency
  | SaveFormulation
  | UpdateUserFormulations
  | LoadUserIngredientCost
  | SetSurfactantSumExceeded
  | SetBenchmarkFormulasList
  | SetErrorMessage;

export const setTemperature = (value: number): SetTemperature => ({
  type: SET_TEMPERATURE,
  value: value,
});

export const setRegion = (value: string): ThunkResult<SetRegion | SetTemperature | SetGroupNames | SetIngredientCostMeta> => {
  return (dispatch) => {
    dispatch({
      type: SET_REGION,
      value: value,
    });
    if (value === 'cn') {
      dispatch(setTemperature(25));
    } else {
      dispatch(setTemperature(30));
    }
    //@ts-ignore
    const groupName = stainGroupNames[value];
    dispatch({
      type: SET_GROUP_NAMES,
      value: groupName,
    });
    dispatch({
      type: SET_INGREDIENT_COST_META,
      weightUnit: 'kg',
      currency: (regions as RegionsType)[value].currency,
    });
    dispatch(setBenchmark('0'));
    dispatch(setCurrency());
    dispatch(fetchUserCost());
    dispatch(generateComparedFormulationsData());
  };
};

export const setGroupNames = (value: string[]): SetGroupNames => ({
  type: SET_GROUP_NAMES,
  value: value,
});

// Generate combined list of benchmarks and user formulas
export const generateBenchmarkFormulasList = (): ThunkResult<Action<any>> => {
  return (dispatch, getState) => {
    const region = getState().UserInputState.region;
    const temperature = getState().UserInputState.temperature;
    //@ts-ignore
    let combinedBenchmarks: any = Object.values(industryBenchmarks[region]);

    const userFormulations = getState().UserInputState.UserFormulations;

    Object.values(userFormulations)
      .filter((userFormulation: any) => userFormulation.data.region === region)
      .filter((userFormulation: any) => userFormulation.data.temperature === temperature)
      .map((userFormulation: any) => userFormulation.data)
      .forEach((userFormulation: any) => {
        const { name, las, aes, aeo, soap, citrate, amplifyprime100l, progressuno100l, lipexevity200l, mannaway200l } = userFormulation;
        const tempObject = {
          name,
          defaultBenchmark: {
            las: las,
            aes: aes,
            aeo: aeo,
            soap: soap,
            amylase: amplifyprime100l,
            protease: progressuno100l,
            lipase: lipexevity200l,
            mannanase: mannaway200l,
            citrate: citrate,
          }
        };
        combinedBenchmarks.push(tempObject);
      });

    let benchmarkFormulasList: any = {};

    Object.entries(combinedBenchmarks).forEach((benchmark: any, index: number) => {
      if (index === 0) return;
      benchmarkFormulasList = {
        ...benchmarkFormulasList,
        [index]: benchmark
      };
    });

    dispatch(setBenchmarkFormulasList(benchmarkFormulasList));
  };
};

export const setBenchmarkFormulasList = (benchmarkFormulasList: any): SetBenchmarkFormulasList => ({
  type: SET_BENCHMARK_FORMULAS_LIST,
  value: benchmarkFormulasList
});

export const setNewBenchmarkFromList = (value: string): ThunkResult<Action<any>> => {
  return (dispatch, getState) => {
    if (value === '1') {
      dispatch(setBenchmark('1'));
    }
    const tempBenchmarkParameters = getState().UserInputState.BenchmarkFormulasList[value];
    dispatch({
      type: SET_BENCHMARK,
      value: tempBenchmarkParameters[0],
      benchmarkParameters: tempBenchmarkParameters[1].defaultBenchmark
    });
    dispatch(toggleBenchmarkIsSet(false));
    dispatch(optimizeRecipe());
  };
};

export const setBenchmark = (value: string): ThunkResult<SetBenchmark | SetBenchmarkType> => {
  return (dispatch, getState) => {
    const region: string = getState().UserInputState.region;
    const benchmarks: { [index: string]: any; } = Object.assign({}, industryBenchmarks);
    const newBenchmarkParameters = benchmarks[region][value].defaultBenchmark;

    dispatch({
      type: SET_BENCHMARK,
      value: value,
      benchmarkParameters: newBenchmarkParameters,
    });

    dispatch(setBenchmarkType('default'));
    dispatch(checkSurfactantSumConstraint());
  };
};

// If user selected own formulation as a benchmark
export const setUserBenchmark = (value: string): ThunkResult<Action<any>> => {
  return (dispatch, getState) => {
    const userFormulations = getState().UserInputState.BenchmarkFormulasList[value][1].defaultBenchmark;
    const { las, aes, aeo, soap, citrate, amylase, protease, lipase, mannanase } = userFormulations;
    const userBenchmark = {
      las: las,
      aes: aes,
      aeo: aeo,
      soap: soap,
      amylase: amylase,
      protease: protease,
      lipase: lipase,
      mannanase: mannanase,
      citrate: citrate,
    };

    dispatch({
      type: SET_BENCHMARK,
      value: value,
      benchmarkParameters: userBenchmark,
    });

    dispatch(setBenchmarkType('user'));
  };
};

const setBenchmarkType = (value: string): SetBenchmarkType => ({
  type: SET_BENCHMARK_TYPE,
  value: value,
});

export const setIngredientCost = (ingredient: string, cost: number) => ({
  type: SET_INGREDIENT_COST,
  data: { [ingredient]: cost },
});

export const setIngredientCostMeta = (weightUnits: string, currency: string) => ({
  type: SET_INGREDIENT_COST,
  weightUnits: weightUnits,
  currency: currency,
});

export const updateBenchmarkValue = (ingredient: string, value: number): ThunkResult<Action<any>> => {
  return (dispatch, getState) => {
    const region = getState().UserInputState.region;
    //@ts-ignore
    const maxValue = ingredientsBoundaries[region][ingredient].max;

    let validatedValue = value;

    // prevent users from assigning values outside outside the boundaries
    if (value > maxValue) {
      validatedValue = maxValue;
      dispatch(setErrorMessage('Value cannot exceed maximum weight % allowed', true));

      setTimeout(() => {
        dispatch(setErrorMessage('', false));
      }, 4000);

    } else {
      dispatch(setErrorMessage('', false));
    }

    dispatch({
      type: UPDATE_BENCHMARK_VALUE,
      data: { [ingredient]: validatedValue },
    });
    dispatch(optimizeRecipe());
    dispatch(checkSurfactantSumConstraint());
  };
};

export const toggleIsFetching = (isFetching: boolean): ToggleIsFetching => ({
  type: TOGGLE_IS_FETCHING,
  isFetching,
});

export const setCurrency = (): ThunkResult<SetCurrency> => {
  return (dispatch, getState) => {
    const newCurrency: string = (regions as RegionsType)[getState().UserInputState.region].currency;
    dispatch({
      type: SET_CURRENCY,
      currency: newCurrency,
    });
  };
};

export const optimizeRecipe = (): ThunkResult<Action<any>> => {
  return async (dispatch, getState) => {
    const region = getState().UserInputState.region;
    dispatch(toggleIsFetching(true));
    const { StainGroups } = getState().FormulationSpace;
    let singleStainGraphs: any = null;

    Object.keys(StainGroups).forEach((stain: string) => {
      const labels = StainGroups[stain].map((stain: any) => stain.display_name_eng);
      const stainNumber = StainGroups[stain].map((stain: any) => stain.stain_number);
      const stainCode = StainGroups[stain].map((stain: any) => stain.stain_code);
      const displayName = StainGroups[stain].map((stain: any) => stain.display_name_eng);

      singleStainGraphs = {
        ...singleStainGraphs,
        [stain]: {
          labels: labels,
          stain_number: stainNumber,
          stain_code: stainCode,
          display_name_eng: displayName,
        },
      };
    });

    // prepare body
    let temp;
    if (getState().UserInputState.region === 'cn') {
      temp = 25;
    } else {
      temp = getState().UserInputState.temperature;
    }

    const { las, aes, aeo, soap, citrate, lipase, mannanase, amylase, protease } = getState().UserInputState.benchmarkData;
    let body = {
      region: getState().UserInputState.region,
      Temperature: temp,
      las: convertValue(las, region),
      aes: convertValue(aes, region),
      aeo: convertValue(aeo, region),
      soap: convertValue(soap, region),
      citrate: convertValue(citrate, region),
      target_ph: TARGET_PH, // fixed value
      lipexevity200l: convertValue(lipase, region),
      mannaway200l: convertValue(mannanase, region),
      amplifyprime100l: convertValue(amylase, region),
      progressuno100l: convertValue(protease, region),
      Swatch_Types: getState().FormulationSpace.AllStainCodes.map(stainCode => stainCode.stain_code),
    };
    try {
      api_fetch('model_prediction', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
        .then((results) => {
          const predictedResults = roundOffPredictedResults(JSON.parse(results));
          dispatch(updateModelPrediction(predictedResults));

          let newStainGroups: any = Object.assign({}, StainGroups);

          //  Generate new StainGroups with predicted results and deviation
          Object.keys(StainGroups).forEach((groupStains: any) => {
            newStainGroups = {
              ...newStainGroups,
              [groupStains]: [],
            };
            StainGroups[groupStains].forEach((stain: any, index: number) => {
              newStainGroups = {
                ...newStainGroups,

                [groupStains]: [
                  ...newStainGroups[groupStains],
                  {
                    ...stain,
                    Predicted: predictedResults.data[stain.index].Predicted,
                    Deviation: predictedResults.data[stain.index]['Standard Deviation'],
                  },
                ],
              };
            });
          });
          dispatch(updateStainGroups(newStainGroups));
          if (!getState().FormulationSpace.benchmarkIsSet) {
            dispatch(setBenchmarkData());
          }
        })
        .then(() => {
          dispatch(setPredictedResponse());
          dispatch(generateBenchmarkFormulasList());
          dispatch(toggleIsFetching(false));
        })
        .catch((error) => {
          console.log('Error encountered', error);
        });
    } catch (error) {
      console.log('Model prediction error:', error);
    }
  };
};

export const fetchStainsInfo = (): ThunkResult<Action<any>> => {
  return (dispatch, getState) => {
    dispatch(toggleIsFetching(true));
    const region = getState().UserInputState.region;
    //let allStainCodes = [];
    const stainGroupLabels = Object.keys(getState().UserInputState.stainGroupNames);

    try {
      api_fetch('get_stain_group', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ region: region }),
      })
        .then((results) => {
          //allStainCodes = results.map((stain: any) => stain.stain_code);
          dispatch(setStainCodes(results));

          let StainGroups: any = {};
          //@ts-ignore
          stainGroupLabels.forEach((stain: string) => {
            StainGroups = {
              ...StainGroups,
              [stain]: [],
            };
          });

          results.forEach((stain: any, index: number) => {
            // action
            stain.stain_group.forEach((groupName: string) => {
              //@ts-ignore
              if (!stainGroupLabels.includes(groupName)) {
                return;
              }
              StainGroups = {
                ...StainGroups,
                [groupName]: [
                  ...StainGroups[groupName],
                  {
                    index: index, // reference or pointer to the allStainCodes
                    stain_code: stain.stain_code,
                    stain_number: stain.stain_number,
                    display_name_eng: stain.display_name_eng,
                  },
                ],
              };
            });
          });

          dispatch(updateStainGroups(StainGroups));
          dispatch(toggleIsFetching(false));
        })
        .catch((e) => {
          console.log('fetch error encountered', e);
          dispatch(toggleIsFetching(false));
        });
    } catch (error) {
      console.log('Error get_stain_group', error);
    }
  };
};

export const saveFormulation = (fileName?: string): ThunkResult<Action<any>> => {
  return (dispatch) => {
    const currentIngredients = dispatch(getIngredientValues());
    const newApiBody = Object.assign({}, currentIngredients, {
      name: fileName,
    });

    try {
      api_fetch('save_formulation', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(newApiBody),
      })
        .then((results) => {
          dispatch(fetchUserFormulations());
          dispatch(generateBenchmarkFormulasList());
        })
        .catch((error) => {
          console.log('error', error);
        });
    } catch (error) {
      console.log('Error fetching formulations');
    }
  };
};

const getIngredientValues = (): ThunkResult<Action<any>> => {
  return (dispatch, getState) => {
    let temp;
    if (getState().UserInputState.region === 'cn') {
      temp = 25;
    } else {
      temp = getState().UserInputState.temperature;
    }

    const currentDateTime = new Date();

    let body = {
      username: getState().AuthState.user.username,
      region: getState().UserInputState.region,
      temperature: temp,
      dose: 60,
      datetime: currentDateTime,
      progressuno100l: getState().UserInputState.benchmarkData.protease,
      amplifyprime100l: getState().UserInputState.benchmarkData.amylase,
      lipexevity200l: getState().UserInputState.benchmarkData.lipase,
      mannaway200l: getState().UserInputState.benchmarkData.mannanase,
      las: getState().UserInputState.benchmarkData.las,
      aes: getState().UserInputState.benchmarkData.aes,
      aeo: getState().UserInputState.benchmarkData.aeo,
      soap: getState().UserInputState.benchmarkData.soap,
      citrate: getState().UserInputState.benchmarkData.citrate,
    };
    return JSON.parse(JSON.stringify(body));
  };
};

export const fetchUserFormulations = (): ThunkResult<Action<any>> => {
  return (dispatch, getState) => {
    const username = getState().AuthState.user.username;

    const body = {
      username: username,
    };

    try {
      api_fetch('get_saved_formulations', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
        .then((results) => {
          let formulationList: any = {};
          results.forEach((formulation: any) => {
            formulationList = {
              ...formulationList,
              [formulation.id]: {
                isSelected: false,
                data: {
                  ...formulation,
                },
              },
            };
          });
          dispatch(updateUserFormulations(formulationList));
        })
        .catch((error) => {
          if (error.status === 404) console.log('User formulation is empty');
        });
    } catch (error) {
      // console.log('Error fetching formulations');
    }
  };
};

const updateUserFormulations = (UserFormulations: any): UpdateUserFormulations => ({
  type: UPDATE_USER_FORMULATIONS,
  UserFormulations: UserFormulations,
});

export const toggleUserFormulation = (formulaId: number): ThunkResult<Action<any>> => {
  return (dispatch, getState) => {
    const maxSelected = 3; // Maximum of 3 formulas to compare for now
    let currentUserFormulations = getState().UserInputState.UserFormulations;
    const selectedFormulas = Object.values(getState().UserInputState.UserFormulations)
      .filter((v: any) => v.data.region === getState().UserInputState.region)
      .filter((v: any) => v.isSelected === true);

    const formulaIdIsNotSelected = selectedFormulas.map((formula: any) => formula.data.id).indexOf(formulaId) === -1;

    // do nothing if selected formula is already 3 and the formulaId being toggled is not in the list
    if (selectedFormulas.length === maxSelected && formulaIdIsNotSelected) return;

    currentUserFormulations = {
      ...currentUserFormulations,
      [formulaId]: {
        ...currentUserFormulations[formulaId],
        isSelected: !currentUserFormulations[formulaId].isSelected,
      },
    };
    dispatch(updateUserFormulations(currentUserFormulations));
    dispatch(predictUserFormulation(Number(formulaId)));
    dispatch(evaluateSustainability(formulaId));
  };
};

const fetchUserCost = (): ThunkResult<Action<any>> => {
  return (dispatch, getState) => {
    //if (getState().AppState.initialDataLoaded) return;
    const { region } = getState().UserInputState;
    const username = getState().AuthState.user.username;

    const { currency } = (regions as RegionsType)[region];
    let body = {
      username: username,
      currency: currency,
    };

    try {
      api_fetch('get_cost_for_user', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
        .then((results: any) => {
          dispatch(loadUserCostData(JSON.parse(JSON.stringify(results))));
        })
        .then(() => {
          dispatch(initialDataLoaded(true));
        })
        .catch((error: any) => {
          if (error.status === 404) {
            console.log('No record found');
          }
        });
    } catch (error) {
      console.log('A problem has occured while fetching your info', error);
    }

  };
};

// Assign the user's saved data to the state
const loadUserCostData = (results: any): ThunkResult<Action<any>> => {
  return (dispatch) => {
    const { progressuno100l, lipexevity200l, aes, soap, amplifyprime100l, las, aeo, citrate, mannaway200l } = results[0];

    const savedIngredientCost = {
      protease: progressuno100l,
      lipase: lipexevity200l,
      aes: aes,
      soap: soap,
      amylase: amplifyprime100l,
      las: las,
      aeo: aeo,
      citrate: citrate,
      mannanase: mannaway200l === undefined ? 0 : mannaway200l,
    };

    dispatch(loadUserIngredientCost(savedIngredientCost));
  };
};

const loadUserIngredientCost = (ingredientCost: any): LoadUserIngredientCost => ({
  type: LOAD_USER_INGREDIENTCOST,
  ingredientCost: ingredientCost,
});

export const saveUserIngredientCost = (): ThunkResult<Action<any>> => {
  return (dispatch, getState) => {
    const { username } = getState().AuthState.user;
    const { currency } = getState().UserInputState;

    const { protease, amylase, lipase, las, mannanase, aes, aeo, soap, citrate } = getState().UserInputState.ingredientCosts;
    let body = {
      username: username,
      currency: currency,
      progressuno100l: protease,
      amplifyprime100l: amylase,
      lipexevity200l: lipase,
      mannaway200l: mannanase,
      las: las,
      aes: aes,
      aeo: aeo,
      soap: soap,
      citrate: citrate,
    };

    try {
      api_fetch('save_cost_for_user', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(body),
      })
        .then((results: any) => {
          return;
        })
        .catch((error: any) => {
          console.log('error saving record', error);
        });
    } catch (error) {
      console.log('API error received', error);
    }
  };
};

// Load User's saved data
export const fetchUserSavedData = (): ThunkResult<Action<any>> => {
  return (dispatch, getState) => {
    dispatch(fetchUserFormulations());
    dispatch(fetchUserCost());
  };
};

// reset the prices to default values based on region
export const setDefaultCost = (): ThunkResult<Action<any>> => {
  return (dispatch, getState) => {
    const currentRegion = getState().UserInputState.region;
    dispatch({
      type: SET_REGION,
      value: currentRegion,
    });
  };
};

const checkSurfactantSumConstraint = (): ThunkResult<Action<any>> => {
  return (dispatch, getState) => {
    const { las, aes, aeo } = getState().UserInputState.benchmarkData;
    if (las + aes + aeo > 38) {
      dispatch({
        type: SET_SURFACTANT_SUM_EXCEEDED,
        value: true,
      });
    } else {
      dispatch({
        type: SET_SURFACTANT_SUM_EXCEEDED,
        value: false,
      });
    }
  };
};

const setErrorMessage = (message: string, isEnabled: boolean) => ({
  type: SET_ERROR_MESSAGE,
  message: message,
  isEnabled: isEnabled
});

export const deleteUserFormulation = (formulaId: number): ThunkResult<Action<any>> => {
  return (dispatch, getState) => {
    const { username } = getState().AuthState.user;
    const deleteBody = {
      username,
      id: formulaId
    };

    try {
      api_fetch('delete_formulation', {
        method: 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(deleteBody),
      }).then(() => {
        // remove formulation from the state
        const currentUserFormulations = getState().UserInputState.UserFormulations;

        try {
          // added since fromEntries doesn't work on Microsoft edge
          Object.fromEntries = (arr: any) => Object.assign({}, ...Array.from(arr, ([k, v]) => ({ [k]: v })));
          let filteredUserFormulations = Object.fromEntries(Object.entries(currentUserFormulations).filter((userFormula: any) => userFormula[0] !== formulaId));
          dispatch(updateUserFormulations(filteredUserFormulations));
        } catch (error) {
          console.log('Something went wrong', error);
        }
        dispatch(generateBenchmarkFormulasList());
        dispatch(setBenchmark('1'));
        dispatch(generateComparedFormulationsData());
      }).catch((error) => {
        console.log('Error deleting user data');
      });

    } catch (error) {
      console.log('Error deleting formulation', error);
    }

  };
};