import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Theme, makeStyles, Typography } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import TableHead from '@material-ui/core/TableHead';
import Paper from '@material-ui/core/Paper';

interface Props {
  theme: Theme;
  showTerms: boolean;
  toggleTerms: (value: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  aboutPage: {
    width: '700px',
  },
  buttons: {
    borderRadius: '22.5px',
    margin: theme.spacing(1),
    color: 'black',
  },
  novoFontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    color: 'black'
  },
  novoFontBold: {
    fontFamily: 'Novozymes Bold, Arial',
    color: 'black'
  },
}));

function createData(col1: string, col2: string, col3: string, col4: string) {
  return { col1, col2, col3, col4 };
}

const cookiesTable = [
  createData('_ga', 'Google Analytics', 'Registers a unique ID that is used to generate statistical data on how the visitor uses the Website', '2 years'),
  createData('_gat', 'Google Tag Manager', 'Used by Google Analytics to throttle request rate', 'a minute'),
  createData('_gid', 'Google Analytics', 'Registers a unique ID that is used to generate statistical data on how the visitor uses the Website', 'a day'),
  createData('AMP_TOKEN', 'Google Analytics',
    'Contains a token that can be used to retrieve a Client ID from AMP Client ID service. Other possible values indicate opt-out, inflight request or an error retrieving a Client ID from AMP Client ID service.',
    '30 seconds to 1 year'
  ),
  createData(
    '_hjClosedSurveyInvites',
    'Hotjar',
    'Hotjar cookie. This cookie is set once a visitor interacts with a Survey invitation modal popup. It is used to ensure that the same invite does not re-appear if it has already been shown.',
    '365 days'
  ),
  createData('_hjDonePolls', 'Hotjar', 'Hotjar cookie. This cookie is set once a visitor completes a poll using the Feedback Poll widget. It is used to ensure that the same poll does not re-appear if it has already been filled in.', '365 days'),
  createData(
    '_hjMinimizedPolls',
    'Hotjar',
    'Hotjar cookie. This cookie is set once a visitor minimizes a Feedback Poll widget. It is used to ensure that the widget stays minimizes when the visitor navigates through your site.',
    '365 days'
  ),
  createData(
    '_hjDoneTestersWidgets',
    'Hotjar',
    'Hotjar cookie. This cookie is set once a visitor submits their information in the Recruit User Testers widget. It is used to ensure that the same form does not re-appear if it has already been filled in.',
    '365 days'
  ),
  createData(
    '_hjMinimizedTestersWidgets',
    'Hotjar',
    'Hotjar cookie. This cookie is set once a visitor minimizes a Recruit User Testers widget. It is used to ensure that the widget stays minimizes when the visitor navigates through your site.',
    '365 days'
  ),
  createData(
    '_hjIncludedInSample',
    'Hotjar',
    'Hotjar cookie. This session cookie is set to let Hotjar know whether that visitor is included in the sample which is used to generate funnels.',
    '365 days'
  ),
  createData(
    '_hjShownFeedbackMessage',
    'Hotjar',
    'This cookie is set when a visitor minimizes or completes Incoming Feedback. This is done so that the Incoming Feedback will load as minimized immediately if they navigate to another page where it is set to show.',
    '365 days'
  ),
  createData(
    '_hjid',
    'Hotjar',
    'Hotjar cookie. This cookie is set when the customer first lands on a page with the Hotjar script. It is used to persist the random user ID, unique to that site on the browser. This ensures that behavior in subsequent visits to the same site will be attributed to the same user ID.',
    '365 days'
  )
];


export const TermsAndConditions: React.SFC<Props> = ({ theme, showTerms, toggleTerms }) => {
  const classes = useStyles();
  const scroll = 'paper';

  const handleClose = () => {
    toggleTerms(false);
  };

  return (
    <div>
      <Dialog
        open={showTerms}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle id='scroll-dialog-title'>Washlab</DialogTitle>
        <DialogContent dividers={true}>
          <Typography className={classes.novoFontBold} variant='h6' component={'span'}>
            TERMS & CONDITIONS.
              <br />
          </Typography>
          <Typography className={classes.novoFontRegular} component={'span'}>
            The Website, www.washlab.novozymes.com (“the Website”), is owned and managed by Novozymes A/S, Krogshoejvej 36, DK-2880 Bagsvaerd, Denmark (“Novozymes”).
            <br />
            <br />
          </Typography>
          <Typography className={classes.novoFontRegular} component={'span'}>
            This notice contains the terms & conditions governing the use of the Website, including data privacy and information about cookies (hereinafter referred to as the “Terms & Conditions”).
            Please read the Terms & Conditions carefully before using the Website. When you use the Website you expressly accept the Terms & Conditions.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontBold} variant='h6' component={'span'}>
            PURPOSE OF THE WEBSITE
              <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            The purpose of the Website is strictly commercial, and as such, it is meant to further Novozymes’ business by the sales of enzymes to formulators of laundry detergents.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            This is done by providing detergent formulators insights into the predicted performance on stain removal of their detergent formulation. The availability of such information should help detergent formulators significantly reduce the number of actual wash trials needed to refine and develop the final formulation. This Website lowers therefore the cost of formulation development and frees up resources for faster innovation.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            The predictions provided by the Website are indicative, and are meant as formulation guidance. They should not substitute all lab work. Contact Novozymes for further formulation advice involving enzymes, before conducting physical trials.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontBold} variant='h6' component={'span'}>
            Contact
              <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            If you have any questions or comments with respect to these Terms & Conditions, or other Information on the Website, please do not hesitate to contact Novozymes at washlab@novozymes.com.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            If you have questions relating to the processing of your personal data or want to invoke your data privacy rights as further described below under “Data Privacy”, please contact legal@novozymes.com.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontBold} variant='h6' component={'span'}>
            LIMITATION OF LIABILITY
              <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            The information on the Website, including advice and recommendations, (“Information”) is provided “as is”, “with all faults” and “as available” without warranties of any kind, whether express or implied, including, but not limited to warranties of merchantability, fitness for a particular purpose, completeness, reliability, accuracy, and noninfringement.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            Any action you take upon the information on the Website is strictly at your own risk.
              <br />
            <br />
          </Typography>
          <Typography className={classes.novoFontRegular} component={'span'}>
            You acknowledge that computer and telecommunications systems are not fault-free and that Novozymes does not guarantee the connection to or transmission from computer networks.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            Novozymes and/or its affiliates shall in no event be held liable for damages (whether direct or indirect), injury, or losses of any kind, including content loss, resulting from your use of or access to the Information on the Website.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontBold} variant='h6' component={'span'}>
            INTELLECTUAL PROPERTY RIGHTS
              <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            All Information on this site, including, but not limited to, any name, logo, text, software, product descriptions, formulas, photographs, video graphics or other material, is owned or licensed by Novozymes whether or not protected by copyright, trademarks, service marks, patents or other proprietary rights and laws. Nothing contained herein shall be construed as conferring any license or right under any patent or trademark of Novozymes or any third party. Any unauthorized use of these names, materials, etc., may be subject to penalties or damages, including, but not limited to, those related to violations of trademarks, copyrights and publicity rights.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontBold} variant='h6' component={'span'}>
            INPUT DATA
              <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            When you use this Website, you are requested to provide input regarding the following input data in relation to your company (“Input Data”):
              <br />
            <br />
            <ol>
              <li>
                <Typography className={classes.novoFontRegular} component={'span'}>Ingredients used: In order for the algorithm to provide the best guidance/recommendation, on formulation composition, you need to specify which ingredients you want to use in your (re)formulation and at which concentrations</Typography>
              </li>
              <br />
              <li>
                <Typography className={classes.novoFontRegular} component={'span'}>Ingredient prices: In order for the algorithm to provide the best guidance/recommendation on formulation cost, you need to input prices for the used ingredients</Typography>
              </li>
              <br />
              <li>
                <Typography className={classes.novoFontRegular} component={'span'}>Currency and unit quotes: In order for the algorithm to provide the most relevant guidance/recommendation, you need to specify what currency and weight unit you are using</Typography>
              </li>
            </ol>
          </Typography>


          <Typography className={classes.novoFontRegular} component={'span'}>
            The Input data, when entered, is used within the Novozymes group as an input for the prediction algorithm to deliver the recommendations on this Website to improve the functionality of the algorithm, potentially jointly with external advisors subject to appropriate confidentiality obligations.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            By providing the Input Data you authorize Novozymes and its affiliates to use the Input Data for these purposes. Novozymes undertakes to keep your Input Data confidential. We do not share your Input Data with third parties.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontBold} variant='h6' component={'span'}>
            DATA PRIVACY
              <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            Novozymes A/S is committed to protecting your personal information.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            The following describes the way we handle information gained about you from your visit to and use of the Website in accordance with applicable rules on protection of personal data, including the EU General Data Protection Regulation no. 679/1016 (“GDPR”).
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            What personal information do we collect and why?
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            The Website collects the following personal data about our online visitors.
              <br />
            <br />
            <ol>
              <li>
                <Typography className={classes.novoFontRegular} component={'span'}>e-mail address</Typography>
              </li>
              <br />
              <li>
                <Typography className={classes.novoFontRegular} component={'span'}>user behavior tracking through the use of cookies</Typography>
              </li>
              <br />
            </ol>
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            Your contact information (e-mail address) is solely used to enable you access to the Website.
              <br />
            <br />
              User behavior tracking is carried out to improve our Website. See further description below under “Cookies”.
            </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            The legal basis for such processing of your personal data under GDPR is our legitimate interest in maintaining and improving our online content, cf. GDPR article 6, 1 (f) and/or your consent, if you have provided a such, cf. GDPR article 6, 1 (a).
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            Your personal information will be retained for as long as it is relevant to fulfil the purpose for which it has been registered. We have implemented technical and organizational measures to keep it safe.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            <span style={{ textDecoration: 'underline' }}>Transfers of data</span>
            <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            Your personal information may be disclosed to affiliates in the Novozymes group to the extent relevant to fulfil your specific request.
              Please see the list of Novozymes’ affiliates <a href="https://www.novozymes.com/en/about-us/management-ownership/subsidiaries">here</a>.
              <br />
            <br />
              Your personal information may also be disclosed to service providers, data processors or other business partners, who assist Novozymes and work under our instructions.
              <br />
            <br />
              In some cases, we will be transferring personal data to countries outside the EU/EEA. Such transfers will only take place for the specific purposes mentioned above under the section “What information do we collect and why?”. We will ensure that appropriate safeguards are in place for such transfer. You may obtain a copy of the contract/agreement template ensuring such safeguards by contacting us at legal@novozymes.com.
              <br /><br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            <span style={{ textDecoration: 'underline' }}>Your rights</span>
            <br />
            <br />
              If we process personal data about you, you have certain data protection rights:
              <br />
            <br />
              You have the right to request access to and rectification or erasure of your personal data.
              <br />
            <ul>
              <li>
                You also have the right to object to the processing of your personal data and to have the processing of your personal data restricted.
                </li>
              <li>
                If processing of your personal information is based on your consent, you have the right to withdraw your consent at any time. Your withdrawal will not affect the lawfulness of the processing carried out before you withdrew your consent. You may withdraw your consent by contacting us at <a href="mailto:legal@novozymes.com">legal@novozymes.com</a>.
                </li>
              <li>
                You may always lodge a complaint with a data protection supervisory authority, e.g. The Danish Data Protection Agency ("Datatilsynet").
                </li>
            </ul>
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            There may be conditions or limitations on these rights. You can take steps to exercise your rights by contacting us at <a href="mailto:legal@novozymes.com">legal@novozymes.com</a>.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontBold} variant='h6' component={'span'}>
            GOVERNING LAW AND VENUE
              <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            These Terms & Conditions are governed exclusively by Danish law without regard to rules on choice of law. Any disputes arising out of or in connection with the Website shall be referred to the jurisdiction of the Danish ordinary courts.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontBold} variant='h6' component={'span'}>
            COOKIES
              <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            www.washlab.novozymes.com uses cookies to improve the functionality of the site as well as to collect information about Website usage. Some cookies are necessary for the site to function properly, and for us to gain a better understanding of potential areas of improvement so as to continually improve the relevance of and accessibility to the content we and you provide on the platform.
              <br />
            <br />
              As such, cookies provide us with a more detailed tracking to finetune our usage reports. However, this information will only be used internally within the Novozymes group and will not be shared with any external stakeholders or be public at any time or transferred to third parties.
              <br />
            <br />
              By accepting the Terms & Conditions and by using the Website, you accept that Novozymes uses cookies as described below, unless you have opted out of cookies in your browser.
              <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            Here we list the cookies that Novozymes uses on the Website as well as the purpose(s) for using the cookies:
              <br />
            <br />
          </Typography>


          <TableContainer component={Paper}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell>Name</TableCell>
                  <TableCell align="left">Provider</TableCell>
                  <TableCell align="left">Purpose</TableCell>
                  <TableCell align="left">Expire</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {cookiesTable.map((row: any, tabIndex: number) => (
                  <TableRow key={tabIndex}>
                    <TableCell align='left' component='th' style={{ width: '230px' }}>
                      {row.col1}
                    </TableCell>
                    <TableCell className={classes.novoFontRegular} align='left' style={{ width: '150px' }}>
                      {row.col2}
                    </TableCell>
                    <TableCell className={classes.novoFontRegular} align='left'>
                      {row.col3}
                    </TableCell>
                    <TableCell className={classes.novoFontRegular} align='left' style={{ width: '110px' }} >
                      {row.col4}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br /><br />

          <Typography className={classes.novoFontRegular} component={'span'}>
            <span style={{ textDecoration: 'underline' }}>What are cookies?</span>
            <br />
            <br />
            Cookies are small text files, which the Website places on your computer's hard drive, smartphone or other electronic device. Cookies contain information, which the Website can use, among other things, to make the communication between you and your web-browser more efficient.
            <br />
            <br />
            Temporary and Permanent cookies
            <br />
            <br />
            There are basically two types of cookies, "temporary" cookies and "permanent" cookies. The temporary cookies are linked to the current visit to the Website and are deleted automatically, when you close your web browser. In contrast, permanent cookies will be stored on your equipment. Permanent cookies delete themselves after a certain period but will be renewed every time you visit the Website.
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            <span style={{ textDecoration: 'underline' }}>How to avoid and delete cookies?</span>
            <br />
            <br />
            If you do not want Novozymes to place or read cookies on your equipment, you have the possibility of opting out of cookies in your browser, where you can choose which cookies that should be allowed, blocked or deleted.  As user, you need to be aware that the Website might not function optimally if you delete or block cookies.
            <br />
            <br />
            If you wish to remove the cookies that are already on your equipment, and you are using a PC and a newer browser, you can press CTRL + SHIFT + DELETE simultaneously. If this shortcut does not work, you can find the support pages for the most commonly used browsers as well as a link to delete flash-cookies here:
            <br /><br />
            <a href='https://support.microsoft.com/en-gb/help/17442/windows-internet-explorer-delete-manage-cookies'>Internet Explorer</a>
            <br />
            <a href='https://support.mozilla.org/en-US/kb/clear-cookies-and-site-data-firefox?redirectlocale=en-US&redirectslug=delete-cookies-remove-info-websites-stored'>Mozilla Firefox</a>
            <br />
            <a href='https://support.google.com/chrome/answer/95647?hl=en-GB'>Google Chrome</a>
            <br />
            <a href='https://support.apple.com/kb/PH21411?locale=da_DK&viewlocale=en_UK'>Safari</a>
            <br />
            <a href='http://ie.sogou.com/help/help-3-4.html'>Sogou</a>
            <br />
            <a href='http://chrome.360.cn/help/cleandata.html'>360</a>
            <br />
            <a href='http://www.macromedia.com/support/documentation/en/flashplayer/help/settings_manager07.html'>Flash cookies</a>
            <br /><br />

          </Typography>

        </DialogContent>
        <DialogActions>
          <Button className={classes.buttons} onClick={handleClose} color='primary' variant='contained'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
