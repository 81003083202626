import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { RootState } from '../reducers/root';
import { PerformanceNavbar } from '../components/PerformancePrediction/PerformanceNavbar';
import { AuthActionTypes, updateIsAuthenticated, updateUserInfo } from '../actions/AuthState';
import { prevWindow, toggleAboutWashlabDetails, AppActionTypes } from '../actions/AppState';

const mapStateToProps = (state: RootState) => ({
  isAuthenticated: state.AuthState.isAuthenticated,
  user: state.AuthState.user,
  isFetching: state.UserInputState.isFetching,
  window: state.AppState.window,
});

const dispatchProps = (dispatch: Dispatch<AuthActionTypes | AppActionTypes>) => {
  return {
    updateIsAuthenticated: (isAuthenticated: boolean) => dispatch(updateIsAuthenticated(isAuthenticated)),
    updateUserInfo: (userInfo: any) => dispatch(updateUserInfo(userInfo)),
    //@ts-ignore
    prevWindow: () => dispatch(prevWindow()),
    //@ts-ignore
    toggleAboutWashlabDetails: (value: boolean) => dispatch(toggleAboutWashlabDetails(value)),
  };
};

export default connect(mapStateToProps, dispatchProps)(PerformanceNavbar);
