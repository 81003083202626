import React from 'react';
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import { Theme, makeStyles, Typography } from '@material-ui/core';

import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';

interface Props {
  theme: Theme;
  region: string;
  showAboutWashlabDetails: boolean;
  toggleAboutWashlabDetails: (value: boolean) => void;
}

const useStyles = makeStyles((theme) => ({
  aboutPage: {
    width: '700px',
  },
  buttons: {
    borderRadius: '22.5px',
    margin: theme.spacing(1),
    color: 'black',
  },
  novoFontRegular: {
    fontFamily: 'Novozymes Regular, Arial',
    color: 'black'
  },
  novoFontBold: {
    fontFamily: 'Novozymes Bold, Arial',
    color: 'black'
  },
}));

function createData(col1: string, col2: string, col3: string) {
  return { col1, col2, col3 };
}

const euRows = [
  createData('FSW data foundation', '3 x 150 FSW (including block/cross references)', 'EU-relevant stains (single stains incl. AISE)'),
  createData(
    'Variable detergent component parameters',
    'LAS, AES, AEO, Progress Uno, Amplify Prime, Lipex Evity, Mannaway',
    'Conditions include 0-20.8 % LAS, 0-23% AES, 0-23% AEO, 0-1.6% Progress Uno 100L, 0-0.1% Amplify Prime 100L, 0-0.68% Lipex Evity 200L, 0-0.39% Mannaway 200L'
  ),
  createData(
    'Fixed detergent component parameters',
    '~1% TEA, 0,25% ethanol, 1% MPG, 5% Citrate, 2% Soap',
    'Used as fixed solvent background in all wash trials. Also used to obtain relevant pH (7-9)'
  ),
  createData(
    'Not tested (but commonly used)',
    'Polymers, FWA, perfume, strong chelators',
    'Relevant components, but deselected in order to control the number of variables'
  ),
  createData(
    'Fixed conditions per region',
    'EU: 30-40°C, FL, 15.6-18L, 60 g detergent/wash dose), standard EU ballast (4 kg) and program length (no prewash, 51 min main wash, 3 x 5 min Rinse, 109 min total duration), 14 dH Water hardness',
    'Dosing only serve as a guideline for the parameter space'
  ),
];

const cnRows = [
  createData('FSW data foundation', '84 FSW', 'China-relevant stains, CHIPS, JB group, JBS group'),
  createData(
    'Variable detergent component parameters',
    'LAS, AES, AEO, Progress Uno, Amplify Prime, Lipex Evity',
    'Conditions include 0-20 % LAS, 0-26.6% AES, 0-20% AEO, 0-3 % Progress Uno 100L, 0-0.1% Amplify Prime 100L'
  ),
  createData(
    'Fixed detergent component parameters',
    '~1% TEA, 0,25% ethanol, 1% MPG, 0-5% citrate, 0-4% soap',
    'Used as fixed solvent background in all wash trials. Also used to obtain relevant pH (7-9). In the current version citrate and soap levels appear as fixed component parameters, due to issues in the simulation uncertainty.'
  ),
  createData(
    'Not tested (but commonly used)',
    'Polymers, FWA, perfume, strong chelators',
    'Relevant components but deselected in order to control the number of variables'
  ),
  createData(
    'Fixed conditions per region',
    'China: 25°C, Top Loader, 37 L, 0-2 g/L dose, standard AP ballast and program length, 14 dH Water hardness',
    'Dosing only serve as a guideline for the parameter space'
  ),
];

const euTechDetails: string = 'The below table summarizes the overall setup used to produce the EU data.'

const cnTechDetails: string = 'The below table summarizes the overall setup used to produce the Chinese data that is used as a basis for the simulation.'

export const AboutWashlabDetails: React.SFC<Props> = ({ theme, region, showAboutWashlabDetails, toggleAboutWashlabDetails }) => {
  const classes = useStyles();
  const scroll = 'paper';
  let rows: any = [];

  let technicalDetailsText: string = ''

  if (region === 'eu') {
    rows = [...euRows];
    technicalDetailsText = euTechDetails
  } else {
    rows = [...cnRows];
    technicalDetailsText = cnTechDetails
  }

  const handleClose = () => {
    toggleAboutWashlabDetails(false);
  };

  const descriptionElementRef = React.useRef<HTMLElement>(null);
  React.useEffect(() => {
    if (showAboutWashlabDetails) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [showAboutWashlabDetails]);

  return (
    <div>
      <Dialog
        open={showAboutWashlabDetails}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby='scroll-dialog-title'
        aria-describedby='scroll-dialog-description'
        fullWidth
        maxWidth={'md'}
      >
        <DialogTitle id='scroll-dialog-title'>About WashLab</DialogTitle>
        <DialogContent dividers={true}>
          <DialogContentText id='scroll-dialog-description' ref={descriptionElementRef} tabIndex={-1}>
            <Typography className={classes.novoFontRegular} component={'span'}>
              Welcome to WashLab – a full-scale wash simulator.
              <br />
              <br />
            </Typography>
            <Typography className={classes.novoFontRegular} component={'span'}>
              This tool is intended to provide guidance when doing reformulation of European and Chinese detergents.
              It simulates the cleaning performance of a given formulation and calculates the associated formulation costs and sustainability profile.
              <br />
              <br />
            </Typography>
            <Typography className={classes.novoFontRegular} component={'span'}>
              You are welcome to use this tool in your dialogue with customers. It is recommended to draw conclusions from the individual stains and not just the overall picture.
              Please take also into consideration, when interpreting data, that the model at this point in time bears some uncertainty, which is even more pronounced when looking at aggregated stains.
              <br />
              <br />
            </Typography>

            <Typography className={classes.novoFontRegular} component={'span'}>
              Please remember to validate your formulation suggestions with real washes before presenting it to customers in the case of a reformulation project.
              <br />
              <br />
            </Typography>

            <Typography className={classes.novoFontRegular} component={'span'}>
              WashLab will be developed on an ongoing basis. We look therefore forward to learning about your experience.
              <br />
              <br />
            </Typography>

            <Typography className={classes.novoFontRegular} component={'span'}>
              We hope you will find WashLab fun and useful to play around with and we very much look forward to hearing your feedback - enjoy!
              <br />
              <br />
            </Typography>

            <Typography className={classes.novoFontBold} variant='h6' component={'span'}>
              Technical Details
              <br />
              <br />
            </Typography>

            <Typography className={classes.novoFontRegular} component={'span'}>
              {technicalDetailsText}
              <br />
              <br />
            </Typography>

            {region === 'eu' && <div>
              <Typography className={classes.novoFontBold} variant='h6' component={'span'}>
                How is the data generated?
                <br />
                <br />
              </Typography>
              <Typography className={classes.novoFontRegular} component={'span'}>
                Data is generated from full scale wash experiments. The experimental program is defined by Design of Experiments (DoE).
                DoE is a statistical approach to designing experiments. It serves as the data foundation for the simulation model in WashLab.
                DoE is a procedure for resource efficient planning of experiments so that the data obtained can be analyzed to yield valid and objective conclusions on complex interactions.
                <br /><br />
                For WashLab, the DoE used to build the model consisted on an experimental program where some of the detergent components where varied in different concentrations in a broad range.
                The goal is to identify all the individual effects of each component and the combined effect of mixtures in the overall detergent performance.
                The DoE is built on statistics, and therefore considers possible interactions between components up to a third degree.
                <br /><br />
                The power of DoE is that there is no need to include repeats in the experimental program (consecutive experiments using the same conditions),
                but it relies on internal replicates. Internal replicates refer to experiments that while overall have different conditions,
                they do replicate some of the factors. As an example, for a trial of 150 washes, each wash represented overall a different set of conditions
                (different concentrations of all the components); i.e., no repeated experiments. However, experiment 1,
                experiment 2 and experiment “n” share as a common condition that the concentration of one component was the same in both experiments (internal replicates).
                <br /><br />
              </Typography> </div>}

            <Typography className={classes.novoFontBold} component={'span'}>
              Execution of trials
              <br />
              <br />
            </Typography>

            <Typography className={classes.novoFontRegular} component={'span'}>
              The FSW trials were single washes, meaning there was no room for double, triple or quadruple repeats as it is usually done.
              Instead the DOE (Design of Experiment) was built with internal validation.
              <br />
              <br />
            </Typography>
          </DialogContentText>
          <TableContainer component={Paper}>
            <Table>
              <TableBody>
                {rows.map((row: any, tabIndex: number) => (
                  <TableRow key={tabIndex}>
                    <TableCell align='left' component='th' style={{ width: '250px' }}>
                      {row.col1}
                    </TableCell>
                    <TableCell className={classes.novoFontRegular} align='left'>
                      {row.col2}
                    </TableCell>
                    <TableCell className={classes.novoFontRegular} align='left'>
                      {row.col3}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
          <br /><br />

          <Typography className={classes.novoFontBold} component={'span'}>
            Error Bars Description
            <br />
            <br />
          </Typography>

          <Typography className={classes.novoFontRegular} component={'span'}>
            The simulated performance of a formulation on a certain stain is based on a machine learning model which has been fitted to experimental data.
            The model includes a random component to account for the experimental variation in the underlying experiments as well as the uncertainty of the simulation.
            <br />
            <br />
          </Typography>
          <Typography className={classes.novoFontRegular} component={'span'}>
            When simulating performance of a formulation, a set of samples is extracted from the model and a normal distribution is fitted to that set. The final simulated value for a stain and formulation is the mean of the fitted normal distribution. The error bars are given by the +/-2 * Standard Deviation of the fitted normal distribution.
            <br />
            <br />
          </Typography>
          <Typography className={classes.novoFontRegular} component={'span'}>
            The aggregated bars for the different stain groups represent the sum of all the individual means of each stain. Likewise, the errors bars are the sum of all the individual error bars of the stains in that group.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button className={classes.buttons} onClick={handleClose} color='primary' variant='contained'>
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
