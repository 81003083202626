import { connect } from 'react-redux';
import { Dispatch } from 'react';
import { RootState } from '../reducers/root';
import { setIngredientCost, setIngredientCostMeta, UserInputActionType, setDefaultCost } from '../actions/UserInput';
import { InputIngredientCost } from '../components/InputSelection/InputIngredientCost';

const mapStateToProps = (state: RootState) => ({
  ingredientCosts: state.UserInputState.ingredientCosts,
  ingredientsCurrency: state.UserInputState.ingredientsCurrency,
  weightUnits: state.UserInputState.weightUnits,
  currency: state.UserInputState.currency,
  region: state.UserInputState.region,
  userLocale: state.AppState.userLocale,
});

const dispatchProps = (dispatch: Dispatch<UserInputActionType>) => {
  return {
    //@ts-ignore
    setIngredientCostMeta: (weightUnits: string, currency: string) => dispatch(setIngredientCostMeta(weightUnits, currency)),
    //@ts-ignore
    setIngredientCost: (ingredient: string, value: number) => dispatch(setIngredientCost(ingredient, value)),
    //@ts-ignore
    setDefaultCost: () => dispatch(setDefaultCost())
  };
};

export default connect(mapStateToProps, dispatchProps)(InputIngredientCost);
