import React, { useState } from 'react';
import { Theme, makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import FormControl from '@material-ui/core/FormControl';
import Button from '@material-ui/core/Button';
import { TextField } from '@material-ui/core';

interface Props {
  theme: Theme;
  onClick: (value: boolean) => void;
  saveFormulation: (formulationName?: string) => void;
  setMessage: (status: any) => void;
  UserFormulations: any;
}

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    height: '200px'
  },
  paper: {
    width: '100%',
    height: '200px',
    paddingLeft: '48px',
    paddingRight: '33px',
  },
  novofontRegular: {
    fontFamily: 'Novozymes Regular, Arial'
  },
  novoboldfont: {
    fontFamily: 'Novozymes Bold, arial'
  },
  custombgcolor: {
    backgroundColor: '#F0EEEE'
  },
  submit: {
    margin: theme.spacing(3, 0, 2),
    borderRadius: '22.5px',
    fontFamily: 'Novozymes Regular, Arial',
    textTransform: 'none'
  }
}));

export const SaveFormulationForm: React.SFC<Props> = ({ theme, onClick, saveFormulation, UserFormulations, setMessage }) => {
  const classes = useStyles(theme);

  const d = new Date();
  const suggestedFilename = (d.getFullYear() * 100 + d.getMonth() + 1) * 100 + d.getDate() + d.getHours().toString() + d.getMinutes().toString() + d.getSeconds().toString();
  const [formulaName, setFormulaName] = useState(suggestedFilename.toString());

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFormulaName(event.target.value);
  };

  const handleClick = () => {
    saveFormulation(formulaName);
    if (Object.keys(UserFormulations).indexOf(formulaName)) {
      setMessage({
        displayMessage: true,
        message: 'Formulation saved'
      });
    }
  };

  const validateCodeForm = () => {
    return formulaName.length < 3;
  };

  return (
    <div id='saveformulationform' className={classes.root}>
      <Paper square className={classes.paper} elevation={0}>
        <Grid container alignContent='center' justify='center' spacing={3}>
          <Grid item>
            <Typography>
              Save current formulation
            </Typography>
          </Grid>
          <Grid item sm={12} xl={12}>
            <FormControl fullWidth>
              <TextField autoFocus fullWidth id="standard-name" label="Please enter the formulation name" value={formulaName} onChange={handleChange} />
            </FormControl>
          </Grid>
        </Grid>
        <Grid container justify='center' spacing={2}>
          <Grid item>
            <Button className={classes.submit} size='small' variant='contained' color='primary' onClick={() => handleClick()} disabled={validateCodeForm()}>
              Save
            </Button>
          </Grid>
          <Grid item>
            <Button className={classes.submit} size='small' variant='contained' color='primary' onClick={() => onClick(false)}>
              Cancel
            </Button>
          </Grid>
        </Grid>
      </Paper>

    </div>
  );
};
