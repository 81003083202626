import React from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';

interface Props {
  theme: Theme;
  formerrors?: any;
  apierrors?: any;
}

const useStyles = makeStyles(theme => ({
  typography: {
    textAlign: 'center',
    padding: '10px 0',
    color: 'danger'
  }
}));

export const FormErrors: React.SFC<Props> = ({ theme, formerrors, apierrors }) => {
  const classes = useStyles(theme);
  if (formerrors && (formerrors.blankfield || formerrors.passwordmatch)) {
    return (
      <div>
        <Typography className={classes.typography}>
          {formerrors.passwordmatch ? 'Password value does not match confirm password value' : ''}
        </Typography>
        <Typography className={classes.typography}>{formerrors.blankfield ? 'Please enter your username and password' : ''}</Typography>
      </div>
    );
  } else if (apierrors) {
    return <Typography className={classes.typography}>{apierrors}</Typography>;
  } else if (formerrors && formerrors.cognito && formerrors.cognito.message) {
    return <Typography className={classes.typography}>{formerrors.cognito.message}</Typography>;
  } else {
    return <div />;
  }
};
