import { connect } from 'react-redux';
import { RootState } from '../reducers/root';
import { StainGroupsGraph } from '../components/PerformancePrediction/StainGroupsGraph';

const mapStateToProps = (state: RootState) => ({
  benchmark: state.UserInputState.benchmark,
  benchmarkType: state.UserInputState.benchmarkType,
  StainGroupNames: state.UserInputState.stainGroupNames,
  region: state.UserInputState.region,
  StainGroups: state.FormulationSpace.StainGroups,
  ModelPredictionData: state.FormulationSpace.ModelPredictionData,
  BenchmarkData: state.FormulationSpace.BenchmarkData,
  userLocale: state.AppState.userLocale,
});

export default connect(mapStateToProps)(StainGroupsGraph);
